@import './shared_colors';

//text colors
$text-primary: rgb(61, 25, 119);
$text-secondary: rgb(200, 68, 153);

.text-primary {
    color: $text-primary !important;
}

.text-secondary {
    color: $text-secondary !important;
}

.font-small {
    font-size: 12px;
}
