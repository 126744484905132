$primary: #440f7c;
$secondary-solid: #d62196;
$secondary-light: #f7d3ea;
$secondary: #d62196;
$olive-color: rgba(33, 149, 55, 0.1);

// background colors
$background-primary: rgba(216, 208, 227, 1);
$background-secondary: rgba(241, 213, 233, 1);
$background-inactive: rgba(189, 189, 199, 0.5);
