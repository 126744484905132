.br-theme-fontawesome-stars .br-widget {
    height: 28px;
    white-space: nowrap;
}
.br-theme-fontawesome-stars .br-widget a {
    font: normal normal normal 20px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
    margin-right: 2px;
}
.br-theme-fontawesome-stars .br-widget a:after {
    content: '\f005';
    color: #d2d2d2;
}
.br-theme-fontawesome-stars .br-widget a.br-active:after {
    color: #edb867;
}
.br-theme-fontawesome-stars .br-widget a.br-selected:after {
    color: #edb867;
}
.br-theme-fontawesome-stars .br-widget .br-current-rating {
    display: none;
}
.br-theme-fontawesome-stars .br-readonly a {
    cursor: default;
}
@media print {
    .br-theme-fontawesome-stars .br-widget a:after {
        content: '\f006';
        color: black;
    }
    .br-theme-fontawesome-stars .br-widget a.br-active:after,
    .br-theme-fontawesome-stars .br-widget a.br-selected:after {
        content: '\f005';
        color: black;
    }
}
