/*------------------------------------------------------------------
 [Master Stylesheet]

  Project:	Regal Admin
  Version:	1.0.0
-------------------------------------------------------------------*/
/*-------------------------------------------------------------------
  ===== Table of Contents =====

  * Bootstrap functions
  * Template variables
  * SCSS Compass Functions
  * Boostrap Main SCSS
  * Template mixins
    + Animation Mixins
    + Badge Mixins
    + Button Mixins
    + Miscellaneous Mixins
    + BlockQuote Mixins
    + Cards Mixins
    + Color Functions Mixins
    + Tooltips
  * Core Styles
    + Reset Styles
    + Fonts
    + Functions
    + Backgrounds
    + Sidebar
    + Navbar
    + Typography
    + Miscellaneous
    + Footer
    + Layouts
    + Utilities
    + Demo styles
  * Components
    + Buttons
    + Breadcrumbs
    + Cards
    + Checkboxes and Radios
    + Dropdowns
    + Forms
    + Icons
    + Lists
    + Preview
    + Tables
  * Plugin Overrides
    + Bootstrap Tour
    + Jquery File Upload
    + Progressbar-js
    + Rating
    + Select2
    + Typeahead
  * Landing screens
    + Auth
-------------------------------------------------------------------*/
/*-------------------------------------------------------------------*/
/* === Import Bootstrap functions and variables === */
/*-------------------------------------------------------------------*/
/* === Import template variables === */
/*-------------------------------------------------------------------*/
/* === SCSS Compass Functions === */
@-moz-keyframes flash {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    75% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes flash {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    75% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes flash {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    75% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes flash {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    75% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes flash {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    75% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes bounce {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -o-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    50% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    60% {
        -webkit-transform: translateY(-15px);
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -o-transform: translateY(-15px);
        transform: translateY(-15px);
    }

    80% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes bounce {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -o-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    50% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    60% {
        -webkit-transform: translateY(-15px);
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -o-transform: translateY(-15px);
        transform: translateY(-15px);
    }

    80% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-o-keyframes bounce {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -o-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    50% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    60% {
        -webkit-transform: translateY(-15px);
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -o-transform: translateY(-15px);
        transform: translateY(-15px);
    }

    80% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-ms-keyframes bounce {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -o-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    50% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    60% {
        -webkit-transform: translateY(-15px);
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -o-transform: translateY(-15px);
        transform: translateY(-15px);
    }

    80% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes bounce {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -o-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    50% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    60% {
        -webkit-transform: translateY(-15px);
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -o-transform: translateY(-15px);
        transform: translateY(-15px);
    }

    80% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-moz-keyframes shake {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    10% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    20% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    30% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    40% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    50% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    60% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    70% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    80% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    90% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes shake {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    10% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    20% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    30% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    40% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    50% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    60% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    70% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    80% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    90% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-o-keyframes shake {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    10% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    20% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    30% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    40% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    50% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    60% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    70% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    80% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    90% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-ms-keyframes shake {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    10% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    20% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    30% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    40% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    50% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    60% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    70% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    80% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    90% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes shake {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    10% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    20% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    30% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    40% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    50% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    60% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    70% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    80% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    90% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-moz-keyframes tada {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    10% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        -moz-transform: scale(0.9) rotate(-3deg);
        -ms-transform: scale(0.9) rotate(-3deg);
        -o-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg);
    }

    20% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        -moz-transform: scale(0.9) rotate(-3deg);
        -ms-transform: scale(0.9) rotate(-3deg);
        -o-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg);
    }

    30% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    40% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        -moz-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        -o-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg);
    }

    50% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    60% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        -moz-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        -o-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg);
    }

    70% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    80% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        -moz-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        -o-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg);
    }

    90% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    100% {
        -webkit-transform: scale(1) rotate(0);
        -moz-transform: scale(1) rotate(0);
        -ms-transform: scale(1) rotate(0);
        -o-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);
    }
}

@-webkit-keyframes tada {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    10% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        -moz-transform: scale(0.9) rotate(-3deg);
        -ms-transform: scale(0.9) rotate(-3deg);
        -o-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg);
    }

    20% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        -moz-transform: scale(0.9) rotate(-3deg);
        -ms-transform: scale(0.9) rotate(-3deg);
        -o-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg);
    }

    30% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    40% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        -moz-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        -o-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg);
    }

    50% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    60% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        -moz-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        -o-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg);
    }

    70% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    80% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        -moz-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        -o-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg);
    }

    90% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    100% {
        -webkit-transform: scale(1) rotate(0);
        -moz-transform: scale(1) rotate(0);
        -ms-transform: scale(1) rotate(0);
        -o-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);
    }
}

@-o-keyframes tada {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    10% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        -moz-transform: scale(0.9) rotate(-3deg);
        -ms-transform: scale(0.9) rotate(-3deg);
        -o-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg);
    }

    20% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        -moz-transform: scale(0.9) rotate(-3deg);
        -ms-transform: scale(0.9) rotate(-3deg);
        -o-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg);
    }

    30% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    40% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        -moz-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        -o-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg);
    }

    50% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    60% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        -moz-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        -o-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg);
    }

    70% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    80% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        -moz-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        -o-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg);
    }

    90% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    100% {
        -webkit-transform: scale(1) rotate(0);
        -moz-transform: scale(1) rotate(0);
        -ms-transform: scale(1) rotate(0);
        -o-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);
    }
}

@-ms-keyframes tada {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    10% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        -moz-transform: scale(0.9) rotate(-3deg);
        -ms-transform: scale(0.9) rotate(-3deg);
        -o-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg);
    }

    20% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        -moz-transform: scale(0.9) rotate(-3deg);
        -ms-transform: scale(0.9) rotate(-3deg);
        -o-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg);
    }

    30% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    40% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        -moz-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        -o-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg);
    }

    50% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    60% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        -moz-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        -o-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg);
    }

    70% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    80% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        -moz-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        -o-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg);
    }

    90% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    100% {
        -webkit-transform: scale(1) rotate(0);
        -moz-transform: scale(1) rotate(0);
        -ms-transform: scale(1) rotate(0);
        -o-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);
    }
}

@keyframes tada {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    10% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        -moz-transform: scale(0.9) rotate(-3deg);
        -ms-transform: scale(0.9) rotate(-3deg);
        -o-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg);
    }

    20% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        -moz-transform: scale(0.9) rotate(-3deg);
        -ms-transform: scale(0.9) rotate(-3deg);
        -o-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg);
    }

    30% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    40% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        -moz-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        -o-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg);
    }

    50% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    60% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        -moz-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        -o-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg);
    }

    70% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    80% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        -moz-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        -o-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg);
    }

    90% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -moz-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        -o-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    100% {
        -webkit-transform: scale(1) rotate(0);
        -moz-transform: scale(1) rotate(0);
        -ms-transform: scale(1) rotate(0);
        -o-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);
    }
}

@-moz-keyframes swing {
    20%,
    40%,
    60%,
    80%,
    100% {
        -webkit-transform-origin: top center 50%;
        -moz-transform-origin: top center 50%;
        -ms-transform-origin: top center 50%;
        -o-transform-origin: top center 50%;
        transform-origin: top center 50%;
    }

    20% {
        -webkit-transform: rotate(15deg);
        -moz-transform: rotate(15deg);
        -ms-transform: rotate(15deg);
        -o-transform: rotate(15deg);
        transform: rotate(15deg);
    }

    40% {
        -webkit-transform: rotate(-10deg);
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    60% {
        -webkit-transform: rotate(5deg);
        -moz-transform: rotate(5deg);
        -ms-transform: rotate(5deg);
        -o-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    80% {
        -webkit-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@-webkit-keyframes swing {
    20%,
    40%,
    60%,
    80%,
    100% {
        -webkit-transform-origin: top center 50%;
        -moz-transform-origin: top center 50%;
        -ms-transform-origin: top center 50%;
        -o-transform-origin: top center 50%;
        transform-origin: top center 50%;
    }

    20% {
        -webkit-transform: rotate(15deg);
        -moz-transform: rotate(15deg);
        -ms-transform: rotate(15deg);
        -o-transform: rotate(15deg);
        transform: rotate(15deg);
    }

    40% {
        -webkit-transform: rotate(-10deg);
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    60% {
        -webkit-transform: rotate(5deg);
        -moz-transform: rotate(5deg);
        -ms-transform: rotate(5deg);
        -o-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    80% {
        -webkit-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@-o-keyframes swing {
    20%,
    40%,
    60%,
    80%,
    100% {
        -webkit-transform-origin: top center 50%;
        -moz-transform-origin: top center 50%;
        -ms-transform-origin: top center 50%;
        -o-transform-origin: top center 50%;
        transform-origin: top center 50%;
    }

    20% {
        -webkit-transform: rotate(15deg);
        -moz-transform: rotate(15deg);
        -ms-transform: rotate(15deg);
        -o-transform: rotate(15deg);
        transform: rotate(15deg);
    }

    40% {
        -webkit-transform: rotate(-10deg);
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    60% {
        -webkit-transform: rotate(5deg);
        -moz-transform: rotate(5deg);
        -ms-transform: rotate(5deg);
        -o-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    80% {
        -webkit-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@-ms-keyframes swing {
    20%,
    40%,
    60%,
    80%,
    100% {
        -webkit-transform-origin: top center 50%;
        -moz-transform-origin: top center 50%;
        -ms-transform-origin: top center 50%;
        -o-transform-origin: top center 50%;
        transform-origin: top center 50%;
    }

    20% {
        -webkit-transform: rotate(15deg);
        -moz-transform: rotate(15deg);
        -ms-transform: rotate(15deg);
        -o-transform: rotate(15deg);
        transform: rotate(15deg);
    }

    40% {
        -webkit-transform: rotate(-10deg);
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    60% {
        -webkit-transform: rotate(5deg);
        -moz-transform: rotate(5deg);
        -ms-transform: rotate(5deg);
        -o-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    80% {
        -webkit-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@keyframes swing {
    20%,
    40%,
    60%,
    80%,
    100% {
        -webkit-transform-origin: top center 50%;
        -moz-transform-origin: top center 50%;
        -ms-transform-origin: top center 50%;
        -o-transform-origin: top center 50%;
        transform-origin: top center 50%;
    }

    20% {
        -webkit-transform: rotate(15deg);
        -moz-transform: rotate(15deg);
        -ms-transform: rotate(15deg);
        -o-transform: rotate(15deg);
        transform: rotate(15deg);
    }

    40% {
        -webkit-transform: rotate(-10deg);
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    60% {
        -webkit-transform: rotate(5deg);
        -moz-transform: rotate(5deg);
        -ms-transform: rotate(5deg);
        -o-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    80% {
        -webkit-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@-moz-keyframes wobble {
    0% {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }

    15% {
        -webkit-transform: translateX(-25%) rotate(-5deg);
        -moz-transform: translateX(-25%) rotate(-5deg);
        -ms-transform: translateX(-25%) rotate(-5deg);
        -o-transform: translateX(-25%) rotate(-5deg);
        transform: translateX(-25%) rotate(-5deg);
    }

    30% {
        -webkit-transform: translateX(20%) rotate(3deg);
        -moz-transform: translateX(20%) rotate(3deg);
        -ms-transform: translateX(20%) rotate(3deg);
        -o-transform: translateX(20%) rotate(3deg);
        transform: translateX(20%) rotate(3deg);
    }

    45% {
        -webkit-transform: translateX(-15%) rotate(-3deg);
        -moz-transform: translateX(-15%) rotate(-3deg);
        -ms-transform: translateX(-15%) rotate(-3deg);
        -o-transform: translateX(-15%) rotate(-3deg);
        transform: translateX(-15%) rotate(-3deg);
    }

    60% {
        -webkit-transform: translateX(10%) rotate(2deg);
        -moz-transform: translateX(10%) rotate(2deg);
        -ms-transform: translateX(10%) rotate(2deg);
        -o-transform: translateX(10%) rotate(2deg);
        transform: translateX(10%) rotate(2deg);
    }

    75% {
        -webkit-transform: translateX(-5%) rotate(-1deg);
        -moz-transform: translateX(-5%) rotate(-1deg);
        -ms-transform: translateX(-5%) rotate(-1deg);
        -o-transform: translateX(-5%) rotate(-1deg);
        transform: translateX(-5%) rotate(-1deg);
    }

    100% {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@-webkit-keyframes wobble {
    0% {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }

    15% {
        -webkit-transform: translateX(-25%) rotate(-5deg);
        -moz-transform: translateX(-25%) rotate(-5deg);
        -ms-transform: translateX(-25%) rotate(-5deg);
        -o-transform: translateX(-25%) rotate(-5deg);
        transform: translateX(-25%) rotate(-5deg);
    }

    30% {
        -webkit-transform: translateX(20%) rotate(3deg);
        -moz-transform: translateX(20%) rotate(3deg);
        -ms-transform: translateX(20%) rotate(3deg);
        -o-transform: translateX(20%) rotate(3deg);
        transform: translateX(20%) rotate(3deg);
    }

    45% {
        -webkit-transform: translateX(-15%) rotate(-3deg);
        -moz-transform: translateX(-15%) rotate(-3deg);
        -ms-transform: translateX(-15%) rotate(-3deg);
        -o-transform: translateX(-15%) rotate(-3deg);
        transform: translateX(-15%) rotate(-3deg);
    }

    60% {
        -webkit-transform: translateX(10%) rotate(2deg);
        -moz-transform: translateX(10%) rotate(2deg);
        -ms-transform: translateX(10%) rotate(2deg);
        -o-transform: translateX(10%) rotate(2deg);
        transform: translateX(10%) rotate(2deg);
    }

    75% {
        -webkit-transform: translateX(-5%) rotate(-1deg);
        -moz-transform: translateX(-5%) rotate(-1deg);
        -ms-transform: translateX(-5%) rotate(-1deg);
        -o-transform: translateX(-5%) rotate(-1deg);
        transform: translateX(-5%) rotate(-1deg);
    }

    100% {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@-o-keyframes wobble {
    0% {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }

    15% {
        -webkit-transform: translateX(-25%) rotate(-5deg);
        -moz-transform: translateX(-25%) rotate(-5deg);
        -ms-transform: translateX(-25%) rotate(-5deg);
        -o-transform: translateX(-25%) rotate(-5deg);
        transform: translateX(-25%) rotate(-5deg);
    }

    30% {
        -webkit-transform: translateX(20%) rotate(3deg);
        -moz-transform: translateX(20%) rotate(3deg);
        -ms-transform: translateX(20%) rotate(3deg);
        -o-transform: translateX(20%) rotate(3deg);
        transform: translateX(20%) rotate(3deg);
    }

    45% {
        -webkit-transform: translateX(-15%) rotate(-3deg);
        -moz-transform: translateX(-15%) rotate(-3deg);
        -ms-transform: translateX(-15%) rotate(-3deg);
        -o-transform: translateX(-15%) rotate(-3deg);
        transform: translateX(-15%) rotate(-3deg);
    }

    60% {
        -webkit-transform: translateX(10%) rotate(2deg);
        -moz-transform: translateX(10%) rotate(2deg);
        -ms-transform: translateX(10%) rotate(2deg);
        -o-transform: translateX(10%) rotate(2deg);
        transform: translateX(10%) rotate(2deg);
    }

    75% {
        -webkit-transform: translateX(-5%) rotate(-1deg);
        -moz-transform: translateX(-5%) rotate(-1deg);
        -ms-transform: translateX(-5%) rotate(-1deg);
        -o-transform: translateX(-5%) rotate(-1deg);
        transform: translateX(-5%) rotate(-1deg);
    }

    100% {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@-ms-keyframes wobble {
    0% {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }

    15% {
        -webkit-transform: translateX(-25%) rotate(-5deg);
        -moz-transform: translateX(-25%) rotate(-5deg);
        -ms-transform: translateX(-25%) rotate(-5deg);
        -o-transform: translateX(-25%) rotate(-5deg);
        transform: translateX(-25%) rotate(-5deg);
    }

    30% {
        -webkit-transform: translateX(20%) rotate(3deg);
        -moz-transform: translateX(20%) rotate(3deg);
        -ms-transform: translateX(20%) rotate(3deg);
        -o-transform: translateX(20%) rotate(3deg);
        transform: translateX(20%) rotate(3deg);
    }

    45% {
        -webkit-transform: translateX(-15%) rotate(-3deg);
        -moz-transform: translateX(-15%) rotate(-3deg);
        -ms-transform: translateX(-15%) rotate(-3deg);
        -o-transform: translateX(-15%) rotate(-3deg);
        transform: translateX(-15%) rotate(-3deg);
    }

    60% {
        -webkit-transform: translateX(10%) rotate(2deg);
        -moz-transform: translateX(10%) rotate(2deg);
        -ms-transform: translateX(10%) rotate(2deg);
        -o-transform: translateX(10%) rotate(2deg);
        transform: translateX(10%) rotate(2deg);
    }

    75% {
        -webkit-transform: translateX(-5%) rotate(-1deg);
        -moz-transform: translateX(-5%) rotate(-1deg);
        -ms-transform: translateX(-5%) rotate(-1deg);
        -o-transform: translateX(-5%) rotate(-1deg);
        transform: translateX(-5%) rotate(-1deg);
    }

    100% {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@keyframes wobble {
    0% {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }

    15% {
        -webkit-transform: translateX(-25%) rotate(-5deg);
        -moz-transform: translateX(-25%) rotate(-5deg);
        -ms-transform: translateX(-25%) rotate(-5deg);
        -o-transform: translateX(-25%) rotate(-5deg);
        transform: translateX(-25%) rotate(-5deg);
    }

    30% {
        -webkit-transform: translateX(20%) rotate(3deg);
        -moz-transform: translateX(20%) rotate(3deg);
        -ms-transform: translateX(20%) rotate(3deg);
        -o-transform: translateX(20%) rotate(3deg);
        transform: translateX(20%) rotate(3deg);
    }

    45% {
        -webkit-transform: translateX(-15%) rotate(-3deg);
        -moz-transform: translateX(-15%) rotate(-3deg);
        -ms-transform: translateX(-15%) rotate(-3deg);
        -o-transform: translateX(-15%) rotate(-3deg);
        transform: translateX(-15%) rotate(-3deg);
    }

    60% {
        -webkit-transform: translateX(10%) rotate(2deg);
        -moz-transform: translateX(10%) rotate(2deg);
        -ms-transform: translateX(10%) rotate(2deg);
        -o-transform: translateX(10%) rotate(2deg);
        transform: translateX(10%) rotate(2deg);
    }

    75% {
        -webkit-transform: translateX(-5%) rotate(-1deg);
        -moz-transform: translateX(-5%) rotate(-1deg);
        -ms-transform: translateX(-5%) rotate(-1deg);
        -o-transform: translateX(-5%) rotate(-1deg);
        transform: translateX(-5%) rotate(-1deg);
    }

    100% {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@-moz-keyframes pulse {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    50% {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }

    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    50% {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }

    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-o-keyframes pulse {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    50% {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }

    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-ms-keyframes pulse {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    50% {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }

    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    50% {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }

    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-moz-keyframes wiggle {
    0% {
        -webkit-transform: skewX(9deg);
        -moz-transform: skewX(9deg);
        -ms-transform: skewX(9deg);
        -o-transform: skewX(9deg);
        transform: skewX(9deg);
    }

    10% {
        -webkit-transform: skewX(-8deg);
        -moz-transform: skewX(-8deg);
        -ms-transform: skewX(-8deg);
        -o-transform: skewX(-8deg);
        transform: skewX(-8deg);
    }

    20% {
        -webkit-transform: skewX(7deg);
        -moz-transform: skewX(7deg);
        -ms-transform: skewX(7deg);
        -o-transform: skewX(7deg);
        transform: skewX(7deg);
    }

    30% {
        -webkit-transform: skewX(-6deg);
        -moz-transform: skewX(-6deg);
        -ms-transform: skewX(-6deg);
        -o-transform: skewX(-6deg);
        transform: skewX(-6deg);
    }

    40% {
        -webkit-transform: skewX(5deg);
        -moz-transform: skewX(5deg);
        -ms-transform: skewX(5deg);
        -o-transform: skewX(5deg);
        transform: skewX(5deg);
    }

    50% {
        -webkit-transform: skewX(-4deg);
        -moz-transform: skewX(-4deg);
        -ms-transform: skewX(-4deg);
        -o-transform: skewX(-4deg);
        transform: skewX(-4deg);
    }

    60% {
        -webkit-transform: skewX(3deg);
        -moz-transform: skewX(3deg);
        -ms-transform: skewX(3deg);
        -o-transform: skewX(3deg);
        transform: skewX(3deg);
    }

    70% {
        -webkit-transform: skewX(-2deg);
        -moz-transform: skewX(-2deg);
        -ms-transform: skewX(-2deg);
        -o-transform: skewX(-2deg);
        transform: skewX(-2deg);
    }

    80% {
        -webkit-transform: skewX(1deg);
        -moz-transform: skewX(1deg);
        -ms-transform: skewX(1deg);
        -o-transform: skewX(1deg);
        transform: skewX(1deg);
    }

    90% {
        -webkit-transform: skewX(0deg);
        -moz-transform: skewX(0deg);
        -ms-transform: skewX(0deg);
        -o-transform: skewX(0deg);
        transform: skewX(0deg);
    }

    100% {
        -webkit-transform: skewX(0deg);
        -moz-transform: skewX(0deg);
        -ms-transform: skewX(0deg);
        -o-transform: skewX(0deg);
        transform: skewX(0deg);
    }
}

@-webkit-keyframes wiggle {
    0% {
        -webkit-transform: skewX(9deg);
        -moz-transform: skewX(9deg);
        -ms-transform: skewX(9deg);
        -o-transform: skewX(9deg);
        transform: skewX(9deg);
    }

    10% {
        -webkit-transform: skewX(-8deg);
        -moz-transform: skewX(-8deg);
        -ms-transform: skewX(-8deg);
        -o-transform: skewX(-8deg);
        transform: skewX(-8deg);
    }

    20% {
        -webkit-transform: skewX(7deg);
        -moz-transform: skewX(7deg);
        -ms-transform: skewX(7deg);
        -o-transform: skewX(7deg);
        transform: skewX(7deg);
    }

    30% {
        -webkit-transform: skewX(-6deg);
        -moz-transform: skewX(-6deg);
        -ms-transform: skewX(-6deg);
        -o-transform: skewX(-6deg);
        transform: skewX(-6deg);
    }

    40% {
        -webkit-transform: skewX(5deg);
        -moz-transform: skewX(5deg);
        -ms-transform: skewX(5deg);
        -o-transform: skewX(5deg);
        transform: skewX(5deg);
    }

    50% {
        -webkit-transform: skewX(-4deg);
        -moz-transform: skewX(-4deg);
        -ms-transform: skewX(-4deg);
        -o-transform: skewX(-4deg);
        transform: skewX(-4deg);
    }

    60% {
        -webkit-transform: skewX(3deg);
        -moz-transform: skewX(3deg);
        -ms-transform: skewX(3deg);
        -o-transform: skewX(3deg);
        transform: skewX(3deg);
    }

    70% {
        -webkit-transform: skewX(-2deg);
        -moz-transform: skewX(-2deg);
        -ms-transform: skewX(-2deg);
        -o-transform: skewX(-2deg);
        transform: skewX(-2deg);
    }

    80% {
        -webkit-transform: skewX(1deg);
        -moz-transform: skewX(1deg);
        -ms-transform: skewX(1deg);
        -o-transform: skewX(1deg);
        transform: skewX(1deg);
    }

    90% {
        -webkit-transform: skewX(0deg);
        -moz-transform: skewX(0deg);
        -ms-transform: skewX(0deg);
        -o-transform: skewX(0deg);
        transform: skewX(0deg);
    }

    100% {
        -webkit-transform: skewX(0deg);
        -moz-transform: skewX(0deg);
        -ms-transform: skewX(0deg);
        -o-transform: skewX(0deg);
        transform: skewX(0deg);
    }
}

@-o-keyframes wiggle {
    0% {
        -webkit-transform: skewX(9deg);
        -moz-transform: skewX(9deg);
        -ms-transform: skewX(9deg);
        -o-transform: skewX(9deg);
        transform: skewX(9deg);
    }

    10% {
        -webkit-transform: skewX(-8deg);
        -moz-transform: skewX(-8deg);
        -ms-transform: skewX(-8deg);
        -o-transform: skewX(-8deg);
        transform: skewX(-8deg);
    }

    20% {
        -webkit-transform: skewX(7deg);
        -moz-transform: skewX(7deg);
        -ms-transform: skewX(7deg);
        -o-transform: skewX(7deg);
        transform: skewX(7deg);
    }

    30% {
        -webkit-transform: skewX(-6deg);
        -moz-transform: skewX(-6deg);
        -ms-transform: skewX(-6deg);
        -o-transform: skewX(-6deg);
        transform: skewX(-6deg);
    }

    40% {
        -webkit-transform: skewX(5deg);
        -moz-transform: skewX(5deg);
        -ms-transform: skewX(5deg);
        -o-transform: skewX(5deg);
        transform: skewX(5deg);
    }

    50% {
        -webkit-transform: skewX(-4deg);
        -moz-transform: skewX(-4deg);
        -ms-transform: skewX(-4deg);
        -o-transform: skewX(-4deg);
        transform: skewX(-4deg);
    }

    60% {
        -webkit-transform: skewX(3deg);
        -moz-transform: skewX(3deg);
        -ms-transform: skewX(3deg);
        -o-transform: skewX(3deg);
        transform: skewX(3deg);
    }

    70% {
        -webkit-transform: skewX(-2deg);
        -moz-transform: skewX(-2deg);
        -ms-transform: skewX(-2deg);
        -o-transform: skewX(-2deg);
        transform: skewX(-2deg);
    }

    80% {
        -webkit-transform: skewX(1deg);
        -moz-transform: skewX(1deg);
        -ms-transform: skewX(1deg);
        -o-transform: skewX(1deg);
        transform: skewX(1deg);
    }

    90% {
        -webkit-transform: skewX(0deg);
        -moz-transform: skewX(0deg);
        -ms-transform: skewX(0deg);
        -o-transform: skewX(0deg);
        transform: skewX(0deg);
    }

    100% {
        -webkit-transform: skewX(0deg);
        -moz-transform: skewX(0deg);
        -ms-transform: skewX(0deg);
        -o-transform: skewX(0deg);
        transform: skewX(0deg);
    }
}

@-ms-keyframes wiggle {
    0% {
        -webkit-transform: skewX(9deg);
        -moz-transform: skewX(9deg);
        -ms-transform: skewX(9deg);
        -o-transform: skewX(9deg);
        transform: skewX(9deg);
    }

    10% {
        -webkit-transform: skewX(-8deg);
        -moz-transform: skewX(-8deg);
        -ms-transform: skewX(-8deg);
        -o-transform: skewX(-8deg);
        transform: skewX(-8deg);
    }

    20% {
        -webkit-transform: skewX(7deg);
        -moz-transform: skewX(7deg);
        -ms-transform: skewX(7deg);
        -o-transform: skewX(7deg);
        transform: skewX(7deg);
    }

    30% {
        -webkit-transform: skewX(-6deg);
        -moz-transform: skewX(-6deg);
        -ms-transform: skewX(-6deg);
        -o-transform: skewX(-6deg);
        transform: skewX(-6deg);
    }

    40% {
        -webkit-transform: skewX(5deg);
        -moz-transform: skewX(5deg);
        -ms-transform: skewX(5deg);
        -o-transform: skewX(5deg);
        transform: skewX(5deg);
    }

    50% {
        -webkit-transform: skewX(-4deg);
        -moz-transform: skewX(-4deg);
        -ms-transform: skewX(-4deg);
        -o-transform: skewX(-4deg);
        transform: skewX(-4deg);
    }

    60% {
        -webkit-transform: skewX(3deg);
        -moz-transform: skewX(3deg);
        -ms-transform: skewX(3deg);
        -o-transform: skewX(3deg);
        transform: skewX(3deg);
    }

    70% {
        -webkit-transform: skewX(-2deg);
        -moz-transform: skewX(-2deg);
        -ms-transform: skewX(-2deg);
        -o-transform: skewX(-2deg);
        transform: skewX(-2deg);
    }

    80% {
        -webkit-transform: skewX(1deg);
        -moz-transform: skewX(1deg);
        -ms-transform: skewX(1deg);
        -o-transform: skewX(1deg);
        transform: skewX(1deg);
    }

    90% {
        -webkit-transform: skewX(0deg);
        -moz-transform: skewX(0deg);
        -ms-transform: skewX(0deg);
        -o-transform: skewX(0deg);
        transform: skewX(0deg);
    }

    100% {
        -webkit-transform: skewX(0deg);
        -moz-transform: skewX(0deg);
        -ms-transform: skewX(0deg);
        -o-transform: skewX(0deg);
        transform: skewX(0deg);
    }
}

@keyframes wiggle {
    0% {
        -webkit-transform: skewX(9deg);
        -moz-transform: skewX(9deg);
        -ms-transform: skewX(9deg);
        -o-transform: skewX(9deg);
        transform: skewX(9deg);
    }

    10% {
        -webkit-transform: skewX(-8deg);
        -moz-transform: skewX(-8deg);
        -ms-transform: skewX(-8deg);
        -o-transform: skewX(-8deg);
        transform: skewX(-8deg);
    }

    20% {
        -webkit-transform: skewX(7deg);
        -moz-transform: skewX(7deg);
        -ms-transform: skewX(7deg);
        -o-transform: skewX(7deg);
        transform: skewX(7deg);
    }

    30% {
        -webkit-transform: skewX(-6deg);
        -moz-transform: skewX(-6deg);
        -ms-transform: skewX(-6deg);
        -o-transform: skewX(-6deg);
        transform: skewX(-6deg);
    }

    40% {
        -webkit-transform: skewX(5deg);
        -moz-transform: skewX(5deg);
        -ms-transform: skewX(5deg);
        -o-transform: skewX(5deg);
        transform: skewX(5deg);
    }

    50% {
        -webkit-transform: skewX(-4deg);
        -moz-transform: skewX(-4deg);
        -ms-transform: skewX(-4deg);
        -o-transform: skewX(-4deg);
        transform: skewX(-4deg);
    }

    60% {
        -webkit-transform: skewX(3deg);
        -moz-transform: skewX(3deg);
        -ms-transform: skewX(3deg);
        -o-transform: skewX(3deg);
        transform: skewX(3deg);
    }

    70% {
        -webkit-transform: skewX(-2deg);
        -moz-transform: skewX(-2deg);
        -ms-transform: skewX(-2deg);
        -o-transform: skewX(-2deg);
        transform: skewX(-2deg);
    }

    80% {
        -webkit-transform: skewX(1deg);
        -moz-transform: skewX(1deg);
        -ms-transform: skewX(1deg);
        -o-transform: skewX(1deg);
        transform: skewX(1deg);
    }

    90% {
        -webkit-transform: skewX(0deg);
        -moz-transform: skewX(0deg);
        -ms-transform: skewX(0deg);
        -o-transform: skewX(0deg);
        transform: skewX(0deg);
    }

    100% {
        -webkit-transform: skewX(0deg);
        -moz-transform: skewX(0deg);
        -ms-transform: skewX(0deg);
        -o-transform: skewX(0deg);
        transform: skewX(0deg);
    }
}

@-moz-keyframes bounceOut {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    25% {
        -webkit-transform: scale(0.95, 0.95);
        -moz-transform: scale(0.95, 0.95);
        -ms-transform: scale(0.95, 0.95);
        -o-transform: scale(0.95, 0.95);
        transform: scale(0.95, 0.95);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(0.3, 0.3);
        -moz-transform: scale(0.3, 0.3);
        -ms-transform: scale(0.3, 0.3);
        -o-transform: scale(0.3, 0.3);
        transform: scale(0.3, 0.3);
    }
}

@-webkit-keyframes bounceOut {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    25% {
        -webkit-transform: scale(0.95, 0.95);
        -moz-transform: scale(0.95, 0.95);
        -ms-transform: scale(0.95, 0.95);
        -o-transform: scale(0.95, 0.95);
        transform: scale(0.95, 0.95);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(0.3, 0.3);
        -moz-transform: scale(0.3, 0.3);
        -ms-transform: scale(0.3, 0.3);
        -o-transform: scale(0.3, 0.3);
        transform: scale(0.3, 0.3);
    }
}

@-o-keyframes bounceOut {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    25% {
        -webkit-transform: scale(0.95, 0.95);
        -moz-transform: scale(0.95, 0.95);
        -ms-transform: scale(0.95, 0.95);
        -o-transform: scale(0.95, 0.95);
        transform: scale(0.95, 0.95);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(0.3, 0.3);
        -moz-transform: scale(0.3, 0.3);
        -ms-transform: scale(0.3, 0.3);
        -o-transform: scale(0.3, 0.3);
        transform: scale(0.3, 0.3);
    }
}

@-ms-keyframes bounceOut {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    25% {
        -webkit-transform: scale(0.95, 0.95);
        -moz-transform: scale(0.95, 0.95);
        -ms-transform: scale(0.95, 0.95);
        -o-transform: scale(0.95, 0.95);
        transform: scale(0.95, 0.95);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(0.3, 0.3);
        -moz-transform: scale(0.3, 0.3);
        -ms-transform: scale(0.3, 0.3);
        -o-transform: scale(0.3, 0.3);
        transform: scale(0.3, 0.3);
    }
}

@keyframes bounceOut {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    25% {
        -webkit-transform: scale(0.95, 0.95);
        -moz-transform: scale(0.95, 0.95);
        -ms-transform: scale(0.95, 0.95);
        -o-transform: scale(0.95, 0.95);
        transform: scale(0.95, 0.95);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(0.3, 0.3);
        -moz-transform: scale(0.3, 0.3);
        -ms-transform: scale(0.3, 0.3);
        -o-transform: scale(0.3, 0.3);
        transform: scale(0.3, 0.3);
    }
}

@-moz-keyframes bounceOutUp {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }
}

@-webkit-keyframes bounceOutUp {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }
}

@-o-keyframes bounceOutUp {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }
}

@-ms-keyframes bounceOutUp {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }
}

@keyframes bounceOutUp {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }
}

@-moz-keyframes bounceOutDown {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
    }
}

@-webkit-keyframes bounceOutDown {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
    }
}

@-o-keyframes bounceOutDown {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
    }
}

@-ms-keyframes bounceOutDown {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
    }
}

@keyframes bounceOutDown {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
    }
}

@-moz-keyframes bounceOutLeft {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }
}

@-webkit-keyframes bounceOutLeft {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }
}

@-o-keyframes bounceOutLeft {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }
}

@-ms-keyframes bounceOutLeft {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }
}

@keyframes bounceOutLeft {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }
}

@-moz-keyframes bounceOutRight {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }
}

@-webkit-keyframes bounceOutRight {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }
}

@-o-keyframes bounceOutRight {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }
}

@-ms-keyframes bounceOutRight {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }
}

@keyframes bounceOutRight {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }
}

@-moz-keyframes bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.3, 0.3);
        -moz-transform: scale(0.3, 0.3);
        -ms-transform: scale(0.3, 0.3);
        -o-transform: scale(0.3, 0.3);
        transform: scale(0.3, 0.3);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.05, 1.05);
        -moz-transform: scale(1.05, 1.05);
        -ms-transform: scale(1.05, 1.05);
        -o-transform: scale(1.05, 1.05);
        transform: scale(1.05, 1.05);
    }

    70% {
        -webkit-transform: scale(0.9, 0.9);
        -moz-transform: scale(0.9, 0.9);
        -ms-transform: scale(0.9, 0.9);
        -o-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9);
    }

    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-webkit-keyframes bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.3, 0.3);
        -moz-transform: scale(0.3, 0.3);
        -ms-transform: scale(0.3, 0.3);
        -o-transform: scale(0.3, 0.3);
        transform: scale(0.3, 0.3);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.05, 1.05);
        -moz-transform: scale(1.05, 1.05);
        -ms-transform: scale(1.05, 1.05);
        -o-transform: scale(1.05, 1.05);
        transform: scale(1.05, 1.05);
    }

    70% {
        -webkit-transform: scale(0.9, 0.9);
        -moz-transform: scale(0.9, 0.9);
        -ms-transform: scale(0.9, 0.9);
        -o-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9);
    }

    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-o-keyframes bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.3, 0.3);
        -moz-transform: scale(0.3, 0.3);
        -ms-transform: scale(0.3, 0.3);
        -o-transform: scale(0.3, 0.3);
        transform: scale(0.3, 0.3);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.05, 1.05);
        -moz-transform: scale(1.05, 1.05);
        -ms-transform: scale(1.05, 1.05);
        -o-transform: scale(1.05, 1.05);
        transform: scale(1.05, 1.05);
    }

    70% {
        -webkit-transform: scale(0.9, 0.9);
        -moz-transform: scale(0.9, 0.9);
        -ms-transform: scale(0.9, 0.9);
        -o-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9);
    }

    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-ms-keyframes bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.3, 0.3);
        -moz-transform: scale(0.3, 0.3);
        -ms-transform: scale(0.3, 0.3);
        -o-transform: scale(0.3, 0.3);
        transform: scale(0.3, 0.3);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.05, 1.05);
        -moz-transform: scale(1.05, 1.05);
        -ms-transform: scale(1.05, 1.05);
        -o-transform: scale(1.05, 1.05);
        transform: scale(1.05, 1.05);
    }

    70% {
        -webkit-transform: scale(0.9, 0.9);
        -moz-transform: scale(0.9, 0.9);
        -ms-transform: scale(0.9, 0.9);
        -o-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9);
    }

    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@keyframes bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.3, 0.3);
        -moz-transform: scale(0.3, 0.3);
        -ms-transform: scale(0.3, 0.3);
        -o-transform: scale(0.3, 0.3);
        transform: scale(0.3, 0.3);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.05, 1.05);
        -moz-transform: scale(1.05, 1.05);
        -ms-transform: scale(1.05, 1.05);
        -o-transform: scale(1.05, 1.05);
        transform: scale(1.05, 1.05);
    }

    70% {
        -webkit-transform: scale(0.9, 0.9);
        -moz-transform: scale(0.9, 0.9);
        -ms-transform: scale(0.9, 0.9);
        -o-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9);
    }

    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-moz-keyframes bounceInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateY(30px);
        -moz-transform: translateY(30px);
        -ms-transform: translateY(30px);
        -o-transform: translateY(30px);
        transform: translateY(30px);
    }

    80% {
        -webkit-transform: translateY(-10px);
        -moz-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        -o-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes bounceInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateY(30px);
        -moz-transform: translateY(30px);
        -ms-transform: translateY(30px);
        -o-transform: translateY(30px);
        transform: translateY(30px);
    }

    80% {
        -webkit-transform: translateY(-10px);
        -moz-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        -o-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-o-keyframes bounceInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateY(30px);
        -moz-transform: translateY(30px);
        -ms-transform: translateY(30px);
        -o-transform: translateY(30px);
        transform: translateY(30px);
    }

    80% {
        -webkit-transform: translateY(-10px);
        -moz-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        -o-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-ms-keyframes bounceInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateY(30px);
        -moz-transform: translateY(30px);
        -ms-transform: translateY(30px);
        -o-transform: translateY(30px);
        transform: translateY(30px);
    }

    80% {
        -webkit-transform: translateY(-10px);
        -moz-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        -o-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes bounceInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateY(30px);
        -moz-transform: translateY(30px);
        -ms-transform: translateY(30px);
        -o-transform: translateY(30px);
        transform: translateY(30px);
    }

    80% {
        -webkit-transform: translateY(-10px);
        -moz-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        -o-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-moz-keyframes bounceInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateY(-30px);
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -o-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    80% {
        -webkit-transform: translateY(10px);
        -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
        -o-transform: translateY(10px);
        transform: translateY(10px);
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes bounceInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateY(-30px);
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -o-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    80% {
        -webkit-transform: translateY(10px);
        -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
        -o-transform: translateY(10px);
        transform: translateY(10px);
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-o-keyframes bounceInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateY(-30px);
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -o-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    80% {
        -webkit-transform: translateY(10px);
        -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
        -o-transform: translateY(10px);
        transform: translateY(10px);
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-ms-keyframes bounceInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateY(-30px);
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -o-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    80% {
        -webkit-transform: translateY(10px);
        -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
        -o-transform: translateY(10px);
        transform: translateY(10px);
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes bounceInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateY(-30px);
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -o-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    80% {
        -webkit-transform: translateY(10px);
        -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
        -o-transform: translateY(10px);
        transform: translateY(10px);
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-moz-keyframes bounceInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-30px);
        -moz-transform: translateX(-30px);
        -ms-transform: translateX(-30px);
        -o-transform: translateX(-30px);
        transform: translateX(-30px);
    }

    80% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes bounceInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-30px);
        -moz-transform: translateX(-30px);
        -ms-transform: translateX(-30px);
        -o-transform: translateX(-30px);
        transform: translateX(-30px);
    }

    80% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-o-keyframes bounceInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-30px);
        -moz-transform: translateX(-30px);
        -ms-transform: translateX(-30px);
        -o-transform: translateX(-30px);
        transform: translateX(-30px);
    }

    80% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-ms-keyframes bounceInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-30px);
        -moz-transform: translateX(-30px);
        -ms-transform: translateX(-30px);
        -o-transform: translateX(-30px);
        transform: translateX(-30px);
    }

    80% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes bounceInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-30px);
        -moz-transform: translateX(-30px);
        -ms-transform: translateX(-30px);
        -o-transform: translateX(-30px);
        transform: translateX(-30px);
    }

    80% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -ms-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-moz-keyframes bounceInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(30px);
        -moz-transform: translateX(30px);
        -ms-transform: translateX(30px);
        -o-transform: translateX(30px);
        transform: translateX(30px);
    }

    80% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes bounceInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(30px);
        -moz-transform: translateX(30px);
        -ms-transform: translateX(30px);
        -o-transform: translateX(30px);
        transform: translateX(30px);
    }

    80% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-o-keyframes bounceInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(30px);
        -moz-transform: translateX(30px);
        -ms-transform: translateX(30px);
        -o-transform: translateX(30px);
        transform: translateX(30px);
    }

    80% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-ms-keyframes bounceInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(30px);
        -moz-transform: translateX(30px);
        -ms-transform: translateX(30px);
        -o-transform: translateX(30px);
        transform: translateX(30px);
    }

    80% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes bounceInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(30px);
        -moz-transform: translateX(30px);
        -ms-transform: translateX(30px);
        -o-transform: translateX(30px);
        transform: translateX(30px);
    }

    80% {
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        -o-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeOutUp {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0;
    }
}

@-webkit-keyframes fadeOutUp {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0;
    }
}

@-o-keyframes fadeOutUp {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0;
    }
}

@-ms-keyframes fadeOutUp {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0;
    }
}

@keyframes fadeOutUp {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0;
    }
}

@-moz-keyframes fadeOutDown {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0;
    }
}

@-webkit-keyframes fadeOutDown {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0;
    }
}

@-o-keyframes fadeOutDown {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0;
    }
}

@-ms-keyframes fadeOutDown {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0;
    }
}

@keyframes fadeOutDown {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0;
    }
}

@-moz-keyframes fadeOutRight {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0;
    }
}

@-webkit-keyframes fadeOutRight {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0;
    }
}

@-o-keyframes fadeOutRight {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0;
    }
}

@-ms-keyframes fadeOutRight {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0;
    }
}

@keyframes fadeOutRight {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0;
    }
}

@-moz-keyframes fadeOutLeft {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0;
    }
}

@-webkit-keyframes fadeOutLeft {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0;
    }
}

@-o-keyframes fadeOutLeft {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0;
    }
}

@-ms-keyframes fadeOutLeft {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0;
    }
}

@keyframes fadeOutLeft {
    0% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0;
    }
}

@-moz-keyframes fadeOutUpBig {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
        opacity: 0;
    }
}

@-webkit-keyframes fadeOutUpBig {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
        opacity: 0;
    }
}

@-o-keyframes fadeOutUpBig {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
        opacity: 0;
    }
}

@-ms-keyframes fadeOutUpBig {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
        opacity: 0;
    }
}

@keyframes fadeOutUpBig {
    0% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
        opacity: 0;
    }
}

@-moz-keyframes fadeOutDownBig {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
    }
}

@-webkit-keyframes fadeOutDownBig {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
    }
}

@-o-keyframes fadeOutDownBig {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
    }
}

@-ms-keyframes fadeOutDownBig {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
    }
}

@keyframes fadeOutDownBig {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
    }
}

@-moz-keyframes fadeOutRightBig {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }
}

@-webkit-keyframes fadeOutRightBig {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }
}

@-o-keyframes fadeOutRightBig {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }
}

@-ms-keyframes fadeOutRightBig {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }
}

@keyframes fadeOutRightBig {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }
}

@-moz-keyframes fadeOutLeftBig {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }
}

@-webkit-keyframes fadeOutLeftBig {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }
}

@-o-keyframes fadeOutLeftBig {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }
}

@-ms-keyframes fadeOutLeftBig {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }
}

@keyframes fadeOutLeftBig {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeInUp {
    0% {
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes fadeInUp {
    0% {
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-o-keyframes fadeInUp {
    0% {
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-ms-keyframes fadeInUp {
    0% {
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeInUp {
    0% {
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-moz-keyframes fadeInDown {
    0% {
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes fadeInDown {
    0% {
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-o-keyframes fadeInDown {
    0% {
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-ms-keyframes fadeInDown {
    0% {
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeInDown {
    0% {
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-moz-keyframes fadeInRight {
    0% {
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes fadeInRight {
    0% {
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@-o-keyframes fadeInRight {
    0% {
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@-ms-keyframes fadeInRight {
    0% {
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeInRight {
    0% {
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@-moz-keyframes fadeInLeft {
    0% {
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes fadeInLeft {
    0% {
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@-o-keyframes fadeInLeft {
    0% {
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@-ms-keyframes fadeInLeft {
    0% {
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeInLeft {
    0% {
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@-moz-keyframes fadeInUpBig {
    0% {
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes fadeInUpBig {
    0% {
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-o-keyframes fadeInUpBig {
    0% {
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-ms-keyframes fadeInUpBig {
    0% {
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeInUpBig {
    0% {
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-moz-keyframes fadeInDownBig {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes fadeInDownBig {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-o-keyframes fadeInDownBig {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-ms-keyframes fadeInDownBig {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes fadeInDownBig {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -moz-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        -o-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-moz-keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-o-keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-ms-keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -moz-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        -o-transform: translateX(2000px);
        transform: translateX(2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-moz-keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-o-keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-ms-keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -moz-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        -o-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-moz-keyframes flip {
    0% {
        -webkit-transform: perspective(400px) rotateY(0);
        -moz-transform: perspective(400px) rotateY(0);
        -ms-transform: perspective(400px) rotateY(0);
        -o-transform: perspective(400px) rotateY(0);
        transform: perspective(400px) rotateY(0);
        -webkit-animation-timing-function: ease-out;
        -moz-animation-timing-function: ease-out;
        -ms-animation-timing-function: ease-out;
        -o-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    40% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -webkit-animation-timing-function: ease-out;
        -moz-animation-timing-function: ease-out;
        -ms-animation-timing-function: ease-out;
        -o-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    50% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -webkit-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -ms-animation-timing-function: ease-in;
        -o-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    80% {
        -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
        -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
        -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
        -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
        transform: perspective(400px) rotateY(360deg) scale(0.95);
        -webkit-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -ms-animation-timing-function: ease-in;
        -o-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: perspective(400px) scale(1);
        -moz-transform: perspective(400px) scale(1);
        -ms-transform: perspective(400px) scale(1);
        -o-transform: perspective(400px) scale(1);
        transform: perspective(400px) scale(1);
        -webkit-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -ms-animation-timing-function: ease-in;
        -o-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
}

@-webkit-keyframes flip {
    0% {
        -webkit-transform: perspective(400px) rotateY(0);
        -moz-transform: perspective(400px) rotateY(0);
        -ms-transform: perspective(400px) rotateY(0);
        -o-transform: perspective(400px) rotateY(0);
        transform: perspective(400px) rotateY(0);
        -webkit-animation-timing-function: ease-out;
        -moz-animation-timing-function: ease-out;
        -ms-animation-timing-function: ease-out;
        -o-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    40% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -webkit-animation-timing-function: ease-out;
        -moz-animation-timing-function: ease-out;
        -ms-animation-timing-function: ease-out;
        -o-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    50% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -webkit-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -ms-animation-timing-function: ease-in;
        -o-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    80% {
        -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
        -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
        -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
        -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
        transform: perspective(400px) rotateY(360deg) scale(0.95);
        -webkit-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -ms-animation-timing-function: ease-in;
        -o-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: perspective(400px) scale(1);
        -moz-transform: perspective(400px) scale(1);
        -ms-transform: perspective(400px) scale(1);
        -o-transform: perspective(400px) scale(1);
        transform: perspective(400px) scale(1);
        -webkit-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -ms-animation-timing-function: ease-in;
        -o-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
}

@-o-keyframes flip {
    0% {
        -webkit-transform: perspective(400px) rotateY(0);
        -moz-transform: perspective(400px) rotateY(0);
        -ms-transform: perspective(400px) rotateY(0);
        -o-transform: perspective(400px) rotateY(0);
        transform: perspective(400px) rotateY(0);
        -webkit-animation-timing-function: ease-out;
        -moz-animation-timing-function: ease-out;
        -ms-animation-timing-function: ease-out;
        -o-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    40% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -webkit-animation-timing-function: ease-out;
        -moz-animation-timing-function: ease-out;
        -ms-animation-timing-function: ease-out;
        -o-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    50% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -webkit-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -ms-animation-timing-function: ease-in;
        -o-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    80% {
        -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
        -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
        -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
        -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
        transform: perspective(400px) rotateY(360deg) scale(0.95);
        -webkit-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -ms-animation-timing-function: ease-in;
        -o-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: perspective(400px) scale(1);
        -moz-transform: perspective(400px) scale(1);
        -ms-transform: perspective(400px) scale(1);
        -o-transform: perspective(400px) scale(1);
        transform: perspective(400px) scale(1);
        -webkit-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -ms-animation-timing-function: ease-in;
        -o-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
}

@-ms-keyframes flip {
    0% {
        -webkit-transform: perspective(400px) rotateY(0);
        -moz-transform: perspective(400px) rotateY(0);
        -ms-transform: perspective(400px) rotateY(0);
        -o-transform: perspective(400px) rotateY(0);
        transform: perspective(400px) rotateY(0);
        -webkit-animation-timing-function: ease-out;
        -moz-animation-timing-function: ease-out;
        -ms-animation-timing-function: ease-out;
        -o-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    40% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -webkit-animation-timing-function: ease-out;
        -moz-animation-timing-function: ease-out;
        -ms-animation-timing-function: ease-out;
        -o-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    50% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -webkit-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -ms-animation-timing-function: ease-in;
        -o-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    80% {
        -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
        -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
        -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
        -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
        transform: perspective(400px) rotateY(360deg) scale(0.95);
        -webkit-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -ms-animation-timing-function: ease-in;
        -o-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: perspective(400px) scale(1);
        -moz-transform: perspective(400px) scale(1);
        -ms-transform: perspective(400px) scale(1);
        -o-transform: perspective(400px) scale(1);
        transform: perspective(400px) scale(1);
        -webkit-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -ms-animation-timing-function: ease-in;
        -o-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
}

@keyframes flip {
    0% {
        -webkit-transform: perspective(400px) rotateY(0);
        -moz-transform: perspective(400px) rotateY(0);
        -ms-transform: perspective(400px) rotateY(0);
        -o-transform: perspective(400px) rotateY(0);
        transform: perspective(400px) rotateY(0);
        -webkit-animation-timing-function: ease-out;
        -moz-animation-timing-function: ease-out;
        -ms-animation-timing-function: ease-out;
        -o-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    40% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
        transform: perspective(400px) translateZ(150px) rotateY(170deg);
        -webkit-animation-timing-function: ease-out;
        -moz-animation-timing-function: ease-out;
        -ms-animation-timing-function: ease-out;
        -o-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    50% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -webkit-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -ms-animation-timing-function: ease-in;
        -o-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    80% {
        -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
        -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
        -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
        -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
        transform: perspective(400px) rotateY(360deg) scale(0.95);
        -webkit-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -ms-animation-timing-function: ease-in;
        -o-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: perspective(400px) scale(1);
        -moz-transform: perspective(400px) scale(1);
        -ms-transform: perspective(400px) scale(1);
        -o-transform: perspective(400px) scale(1);
        transform: perspective(400px) scale(1);
        -webkit-animation-timing-function: ease-in;
        -moz-animation-timing-function: ease-in;
        -ms-animation-timing-function: ease-in;
        -o-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
}

@-moz-keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        -moz-transform: perspective(400px) rotateX(90deg);
        -ms-transform: perspective(400px) rotateX(90deg);
        -o-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotateX(-10deg);
        -moz-transform: perspective(400px) rotateX(-10deg);
        -ms-transform: perspective(400px) rotateX(-10deg);
        -o-transform: perspective(400px) rotateX(-10deg);
        transform: perspective(400px) rotateX(-10deg);
    }

    70% {
        -webkit-transform: perspective(400px) rotateX(10deg);
        -moz-transform: perspective(400px) rotateX(10deg);
        -ms-transform: perspective(400px) rotateX(10deg);
        -o-transform: perspective(400px) rotateX(10deg);
        transform: perspective(400px) rotateX(10deg);
    }

    100% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        -moz-transform: perspective(400px) rotateX(0deg);
        -ms-transform: perspective(400px) rotateX(0deg);
        -o-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }
}

@-webkit-keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        -moz-transform: perspective(400px) rotateX(90deg);
        -ms-transform: perspective(400px) rotateX(90deg);
        -o-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotateX(-10deg);
        -moz-transform: perspective(400px) rotateX(-10deg);
        -ms-transform: perspective(400px) rotateX(-10deg);
        -o-transform: perspective(400px) rotateX(-10deg);
        transform: perspective(400px) rotateX(-10deg);
    }

    70% {
        -webkit-transform: perspective(400px) rotateX(10deg);
        -moz-transform: perspective(400px) rotateX(10deg);
        -ms-transform: perspective(400px) rotateX(10deg);
        -o-transform: perspective(400px) rotateX(10deg);
        transform: perspective(400px) rotateX(10deg);
    }

    100% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        -moz-transform: perspective(400px) rotateX(0deg);
        -ms-transform: perspective(400px) rotateX(0deg);
        -o-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }
}

@-o-keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        -moz-transform: perspective(400px) rotateX(90deg);
        -ms-transform: perspective(400px) rotateX(90deg);
        -o-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotateX(-10deg);
        -moz-transform: perspective(400px) rotateX(-10deg);
        -ms-transform: perspective(400px) rotateX(-10deg);
        -o-transform: perspective(400px) rotateX(-10deg);
        transform: perspective(400px) rotateX(-10deg);
    }

    70% {
        -webkit-transform: perspective(400px) rotateX(10deg);
        -moz-transform: perspective(400px) rotateX(10deg);
        -ms-transform: perspective(400px) rotateX(10deg);
        -o-transform: perspective(400px) rotateX(10deg);
        transform: perspective(400px) rotateX(10deg);
    }

    100% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        -moz-transform: perspective(400px) rotateX(0deg);
        -ms-transform: perspective(400px) rotateX(0deg);
        -o-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }
}

@-ms-keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        -moz-transform: perspective(400px) rotateX(90deg);
        -ms-transform: perspective(400px) rotateX(90deg);
        -o-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotateX(-10deg);
        -moz-transform: perspective(400px) rotateX(-10deg);
        -ms-transform: perspective(400px) rotateX(-10deg);
        -o-transform: perspective(400px) rotateX(-10deg);
        transform: perspective(400px) rotateX(-10deg);
    }

    70% {
        -webkit-transform: perspective(400px) rotateX(10deg);
        -moz-transform: perspective(400px) rotateX(10deg);
        -ms-transform: perspective(400px) rotateX(10deg);
        -o-transform: perspective(400px) rotateX(10deg);
        transform: perspective(400px) rotateX(10deg);
    }

    100% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        -moz-transform: perspective(400px) rotateX(0deg);
        -ms-transform: perspective(400px) rotateX(0deg);
        -o-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }
}

@keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        -moz-transform: perspective(400px) rotateX(90deg);
        -ms-transform: perspective(400px) rotateX(90deg);
        -o-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotateX(-10deg);
        -moz-transform: perspective(400px) rotateX(-10deg);
        -ms-transform: perspective(400px) rotateX(-10deg);
        -o-transform: perspective(400px) rotateX(-10deg);
        transform: perspective(400px) rotateX(-10deg);
    }

    70% {
        -webkit-transform: perspective(400px) rotateX(10deg);
        -moz-transform: perspective(400px) rotateX(10deg);
        -ms-transform: perspective(400px) rotateX(10deg);
        -o-transform: perspective(400px) rotateX(10deg);
        transform: perspective(400px) rotateX(10deg);
    }

    100% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        -moz-transform: perspective(400px) rotateX(0deg);
        -ms-transform: perspective(400px) rotateX(0deg);
        -o-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }
}

@-moz-keyframes flipOutX {
    0% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        -moz-transform: perspective(400px) rotateX(0deg);
        -ms-transform: perspective(400px) rotateX(0deg);
        -o-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        -moz-transform: perspective(400px) rotateX(90deg);
        -ms-transform: perspective(400px) rotateX(90deg);
        -o-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-webkit-keyframes flipOutX {
    0% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        -moz-transform: perspective(400px) rotateX(0deg);
        -ms-transform: perspective(400px) rotateX(0deg);
        -o-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        -moz-transform: perspective(400px) rotateX(90deg);
        -ms-transform: perspective(400px) rotateX(90deg);
        -o-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-o-keyframes flipOutX {
    0% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        -moz-transform: perspective(400px) rotateX(0deg);
        -ms-transform: perspective(400px) rotateX(0deg);
        -o-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        -moz-transform: perspective(400px) rotateX(90deg);
        -ms-transform: perspective(400px) rotateX(90deg);
        -o-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-ms-keyframes flipOutX {
    0% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        -moz-transform: perspective(400px) rotateX(0deg);
        -ms-transform: perspective(400px) rotateX(0deg);
        -o-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        -moz-transform: perspective(400px) rotateX(90deg);
        -ms-transform: perspective(400px) rotateX(90deg);
        -o-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@keyframes flipOutX {
    0% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        -moz-transform: perspective(400px) rotateX(0deg);
        -ms-transform: perspective(400px) rotateX(0deg);
        -o-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        -moz-transform: perspective(400px) rotateX(90deg);
        -ms-transform: perspective(400px) rotateX(90deg);
        -o-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-moz-keyframes flipInY {
    0% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        -moz-transform: perspective(400px) rotateY(90deg);
        -ms-transform: perspective(400px) rotateY(90deg);
        -o-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotateY(-10deg);
        -moz-transform: perspective(400px) rotateY(-10deg);
        -ms-transform: perspective(400px) rotateY(-10deg);
        -o-transform: perspective(400px) rotateY(-10deg);
        transform: perspective(400px) rotateY(-10deg);
    }

    70% {
        -webkit-transform: perspective(400px) rotateY(10deg);
        -moz-transform: perspective(400px) rotateY(10deg);
        -ms-transform: perspective(400px) rotateY(10deg);
        -o-transform: perspective(400px) rotateY(10deg);
        transform: perspective(400px) rotateY(10deg);
    }

    100% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        -moz-transform: perspective(400px) rotateY(0deg);
        -ms-transform: perspective(400px) rotateY(0deg);
        -o-transform: perspective(400px) rotateY(0deg);
        transform: perspective(400px) rotateY(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }
}

@-webkit-keyframes flipInY {
    0% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        -moz-transform: perspective(400px) rotateY(90deg);
        -ms-transform: perspective(400px) rotateY(90deg);
        -o-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotateY(-10deg);
        -moz-transform: perspective(400px) rotateY(-10deg);
        -ms-transform: perspective(400px) rotateY(-10deg);
        -o-transform: perspective(400px) rotateY(-10deg);
        transform: perspective(400px) rotateY(-10deg);
    }

    70% {
        -webkit-transform: perspective(400px) rotateY(10deg);
        -moz-transform: perspective(400px) rotateY(10deg);
        -ms-transform: perspective(400px) rotateY(10deg);
        -o-transform: perspective(400px) rotateY(10deg);
        transform: perspective(400px) rotateY(10deg);
    }

    100% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        -moz-transform: perspective(400px) rotateY(0deg);
        -ms-transform: perspective(400px) rotateY(0deg);
        -o-transform: perspective(400px) rotateY(0deg);
        transform: perspective(400px) rotateY(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }
}

@-o-keyframes flipInY {
    0% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        -moz-transform: perspective(400px) rotateY(90deg);
        -ms-transform: perspective(400px) rotateY(90deg);
        -o-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotateY(-10deg);
        -moz-transform: perspective(400px) rotateY(-10deg);
        -ms-transform: perspective(400px) rotateY(-10deg);
        -o-transform: perspective(400px) rotateY(-10deg);
        transform: perspective(400px) rotateY(-10deg);
    }

    70% {
        -webkit-transform: perspective(400px) rotateY(10deg);
        -moz-transform: perspective(400px) rotateY(10deg);
        -ms-transform: perspective(400px) rotateY(10deg);
        -o-transform: perspective(400px) rotateY(10deg);
        transform: perspective(400px) rotateY(10deg);
    }

    100% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        -moz-transform: perspective(400px) rotateY(0deg);
        -ms-transform: perspective(400px) rotateY(0deg);
        -o-transform: perspective(400px) rotateY(0deg);
        transform: perspective(400px) rotateY(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }
}

@-ms-keyframes flipInY {
    0% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        -moz-transform: perspective(400px) rotateY(90deg);
        -ms-transform: perspective(400px) rotateY(90deg);
        -o-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotateY(-10deg);
        -moz-transform: perspective(400px) rotateY(-10deg);
        -ms-transform: perspective(400px) rotateY(-10deg);
        -o-transform: perspective(400px) rotateY(-10deg);
        transform: perspective(400px) rotateY(-10deg);
    }

    70% {
        -webkit-transform: perspective(400px) rotateY(10deg);
        -moz-transform: perspective(400px) rotateY(10deg);
        -ms-transform: perspective(400px) rotateY(10deg);
        -o-transform: perspective(400px) rotateY(10deg);
        transform: perspective(400px) rotateY(10deg);
    }

    100% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        -moz-transform: perspective(400px) rotateY(0deg);
        -ms-transform: perspective(400px) rotateY(0deg);
        -o-transform: perspective(400px) rotateY(0deg);
        transform: perspective(400px) rotateY(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }
}

@keyframes flipInY {
    0% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        -moz-transform: perspective(400px) rotateY(90deg);
        -ms-transform: perspective(400px) rotateY(90deg);
        -o-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotateY(-10deg);
        -moz-transform: perspective(400px) rotateY(-10deg);
        -ms-transform: perspective(400px) rotateY(-10deg);
        -o-transform: perspective(400px) rotateY(-10deg);
        transform: perspective(400px) rotateY(-10deg);
    }

    70% {
        -webkit-transform: perspective(400px) rotateY(10deg);
        -moz-transform: perspective(400px) rotateY(10deg);
        -ms-transform: perspective(400px) rotateY(10deg);
        -o-transform: perspective(400px) rotateY(10deg);
        transform: perspective(400px) rotateY(10deg);
    }

    100% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        -moz-transform: perspective(400px) rotateY(0deg);
        -ms-transform: perspective(400px) rotateY(0deg);
        -o-transform: perspective(400px) rotateY(0deg);
        transform: perspective(400px) rotateY(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }
}

@-moz-keyframes flipOutY {
    0% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        -moz-transform: perspective(400px) rotateY(0deg);
        -ms-transform: perspective(400px) rotateY(0deg);
        -o-transform: perspective(400px) rotateY(0deg);
        transform: perspective(400px) rotateY(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        -moz-transform: perspective(400px) rotateY(90deg);
        -ms-transform: perspective(400px) rotateY(90deg);
        -o-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-webkit-keyframes flipOutY {
    0% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        -moz-transform: perspective(400px) rotateY(0deg);
        -ms-transform: perspective(400px) rotateY(0deg);
        -o-transform: perspective(400px) rotateY(0deg);
        transform: perspective(400px) rotateY(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        -moz-transform: perspective(400px) rotateY(90deg);
        -ms-transform: perspective(400px) rotateY(90deg);
        -o-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-o-keyframes flipOutY {
    0% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        -moz-transform: perspective(400px) rotateY(0deg);
        -ms-transform: perspective(400px) rotateY(0deg);
        -o-transform: perspective(400px) rotateY(0deg);
        transform: perspective(400px) rotateY(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        -moz-transform: perspective(400px) rotateY(90deg);
        -ms-transform: perspective(400px) rotateY(90deg);
        -o-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-ms-keyframes flipOutY {
    0% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        -moz-transform: perspective(400px) rotateY(0deg);
        -ms-transform: perspective(400px) rotateY(0deg);
        -o-transform: perspective(400px) rotateY(0deg);
        transform: perspective(400px) rotateY(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        -moz-transform: perspective(400px) rotateY(90deg);
        -ms-transform: perspective(400px) rotateY(90deg);
        -o-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@keyframes flipOutY {
    0% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        -moz-transform: perspective(400px) rotateY(0deg);
        -ms-transform: perspective(400px) rotateY(0deg);
        -o-transform: perspective(400px) rotateY(0deg);
        transform: perspective(400px) rotateY(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        -moz-transform: perspective(400px) rotateY(90deg);
        -ms-transform: perspective(400px) rotateY(90deg);
        -o-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-moz-keyframes lightSpeedIn {
    0% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        -moz-transform: translateX(100%) skewX(-30deg);
        -ms-transform: translateX(100%) skewX(-30deg);
        -o-transform: translateX(100%) skewX(-30deg);
        transform: translateX(100%) skewX(-30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }

    60% {
        -webkit-transform: translateX(-20%) skewX(30deg);
        -moz-transform: translateX(-20%) skewX(30deg);
        -ms-transform: translateX(-20%) skewX(30deg);
        -o-transform: translateX(-20%) skewX(30deg);
        transform: translateX(-20%) skewX(30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    80% {
        -webkit-transform: translateX(0%) skewX(-15deg);
        -moz-transform: translateX(0%) skewX(-15deg);
        -ms-transform: translateX(0%) skewX(-15deg);
        -o-transform: translateX(0%) skewX(-15deg);
        transform: translateX(0%) skewX(-15deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(0%) skewX(0deg);
        -moz-transform: translateX(0%) skewX(0deg);
        -ms-transform: translateX(0%) skewX(0deg);
        -o-transform: translateX(0%) skewX(0deg);
        transform: translateX(0%) skewX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }
}

@-webkit-keyframes lightSpeedIn {
    0% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        -moz-transform: translateX(100%) skewX(-30deg);
        -ms-transform: translateX(100%) skewX(-30deg);
        -o-transform: translateX(100%) skewX(-30deg);
        transform: translateX(100%) skewX(-30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }

    60% {
        -webkit-transform: translateX(-20%) skewX(30deg);
        -moz-transform: translateX(-20%) skewX(30deg);
        -ms-transform: translateX(-20%) skewX(30deg);
        -o-transform: translateX(-20%) skewX(30deg);
        transform: translateX(-20%) skewX(30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    80% {
        -webkit-transform: translateX(0%) skewX(-15deg);
        -moz-transform: translateX(0%) skewX(-15deg);
        -ms-transform: translateX(0%) skewX(-15deg);
        -o-transform: translateX(0%) skewX(-15deg);
        transform: translateX(0%) skewX(-15deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(0%) skewX(0deg);
        -moz-transform: translateX(0%) skewX(0deg);
        -ms-transform: translateX(0%) skewX(0deg);
        -o-transform: translateX(0%) skewX(0deg);
        transform: translateX(0%) skewX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }
}

@-o-keyframes lightSpeedIn {
    0% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        -moz-transform: translateX(100%) skewX(-30deg);
        -ms-transform: translateX(100%) skewX(-30deg);
        -o-transform: translateX(100%) skewX(-30deg);
        transform: translateX(100%) skewX(-30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }

    60% {
        -webkit-transform: translateX(-20%) skewX(30deg);
        -moz-transform: translateX(-20%) skewX(30deg);
        -ms-transform: translateX(-20%) skewX(30deg);
        -o-transform: translateX(-20%) skewX(30deg);
        transform: translateX(-20%) skewX(30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    80% {
        -webkit-transform: translateX(0%) skewX(-15deg);
        -moz-transform: translateX(0%) skewX(-15deg);
        -ms-transform: translateX(0%) skewX(-15deg);
        -o-transform: translateX(0%) skewX(-15deg);
        transform: translateX(0%) skewX(-15deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(0%) skewX(0deg);
        -moz-transform: translateX(0%) skewX(0deg);
        -ms-transform: translateX(0%) skewX(0deg);
        -o-transform: translateX(0%) skewX(0deg);
        transform: translateX(0%) skewX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }
}

@-ms-keyframes lightSpeedIn {
    0% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        -moz-transform: translateX(100%) skewX(-30deg);
        -ms-transform: translateX(100%) skewX(-30deg);
        -o-transform: translateX(100%) skewX(-30deg);
        transform: translateX(100%) skewX(-30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }

    60% {
        -webkit-transform: translateX(-20%) skewX(30deg);
        -moz-transform: translateX(-20%) skewX(30deg);
        -ms-transform: translateX(-20%) skewX(30deg);
        -o-transform: translateX(-20%) skewX(30deg);
        transform: translateX(-20%) skewX(30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    80% {
        -webkit-transform: translateX(0%) skewX(-15deg);
        -moz-transform: translateX(0%) skewX(-15deg);
        -ms-transform: translateX(0%) skewX(-15deg);
        -o-transform: translateX(0%) skewX(-15deg);
        transform: translateX(0%) skewX(-15deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(0%) skewX(0deg);
        -moz-transform: translateX(0%) skewX(0deg);
        -ms-transform: translateX(0%) skewX(0deg);
        -o-transform: translateX(0%) skewX(0deg);
        transform: translateX(0%) skewX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }
}

@keyframes lightSpeedIn {
    0% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        -moz-transform: translateX(100%) skewX(-30deg);
        -ms-transform: translateX(100%) skewX(-30deg);
        -o-transform: translateX(100%) skewX(-30deg);
        transform: translateX(100%) skewX(-30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }

    60% {
        -webkit-transform: translateX(-20%) skewX(30deg);
        -moz-transform: translateX(-20%) skewX(30deg);
        -ms-transform: translateX(-20%) skewX(30deg);
        -o-transform: translateX(-20%) skewX(30deg);
        transform: translateX(-20%) skewX(30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    80% {
        -webkit-transform: translateX(0%) skewX(-15deg);
        -moz-transform: translateX(0%) skewX(-15deg);
        -ms-transform: translateX(0%) skewX(-15deg);
        -o-transform: translateX(0%) skewX(-15deg);
        transform: translateX(0%) skewX(-15deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(0%) skewX(0deg);
        -moz-transform: translateX(0%) skewX(0deg);
        -ms-transform: translateX(0%) skewX(0deg);
        -o-transform: translateX(0%) skewX(0deg);
        transform: translateX(0%) skewX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }
}

@-moz-keyframes lightSpeedOut {
    0% {
        -webkit-transform: translateX(0%) skewX(0deg);
        -moz-transform: translateX(0%) skewX(0deg);
        -ms-transform: translateX(0%) skewX(0deg);
        -o-transform: translateX(0%) skewX(0deg);
        transform: translateX(0%) skewX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        -moz-transform: translateX(100%) skewX(-30deg);
        -ms-transform: translateX(100%) skewX(-30deg);
        -o-transform: translateX(100%) skewX(-30deg);
        transform: translateX(100%) skewX(-30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-webkit-keyframes lightSpeedOut {
    0% {
        -webkit-transform: translateX(0%) skewX(0deg);
        -moz-transform: translateX(0%) skewX(0deg);
        -ms-transform: translateX(0%) skewX(0deg);
        -o-transform: translateX(0%) skewX(0deg);
        transform: translateX(0%) skewX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        -moz-transform: translateX(100%) skewX(-30deg);
        -ms-transform: translateX(100%) skewX(-30deg);
        -o-transform: translateX(100%) skewX(-30deg);
        transform: translateX(100%) skewX(-30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-o-keyframes lightSpeedOut {
    0% {
        -webkit-transform: translateX(0%) skewX(0deg);
        -moz-transform: translateX(0%) skewX(0deg);
        -ms-transform: translateX(0%) skewX(0deg);
        -o-transform: translateX(0%) skewX(0deg);
        transform: translateX(0%) skewX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        -moz-transform: translateX(100%) skewX(-30deg);
        -ms-transform: translateX(100%) skewX(-30deg);
        -o-transform: translateX(100%) skewX(-30deg);
        transform: translateX(100%) skewX(-30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-ms-keyframes lightSpeedOut {
    0% {
        -webkit-transform: translateX(0%) skewX(0deg);
        -moz-transform: translateX(0%) skewX(0deg);
        -ms-transform: translateX(0%) skewX(0deg);
        -o-transform: translateX(0%) skewX(0deg);
        transform: translateX(0%) skewX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        -moz-transform: translateX(100%) skewX(-30deg);
        -ms-transform: translateX(100%) skewX(-30deg);
        -o-transform: translateX(100%) skewX(-30deg);
        transform: translateX(100%) skewX(-30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@keyframes lightSpeedOut {
    0% {
        -webkit-transform: translateX(0%) skewX(0deg);
        -moz-transform: translateX(0%) skewX(0deg);
        -ms-transform: translateX(0%) skewX(0deg);
        -o-transform: translateX(0%) skewX(0deg);
        transform: translateX(0%) skewX(0deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        -moz-transform: translateX(100%) skewX(-30deg);
        -ms-transform: translateX(100%) skewX(-30deg);
        -o-transform: translateX(100%) skewX(-30deg);
        transform: translateX(100%) skewX(-30deg);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-moz-keyframes rotateOut {
    0% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(200deg);
        -moz-transform: rotate(200deg);
        -ms-transform: rotate(200deg);
        -o-transform: rotate(200deg);
        transform: rotate(200deg);
        opacity: 0;
    }
}

@-webkit-keyframes rotateOut {
    0% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(200deg);
        -moz-transform: rotate(200deg);
        -ms-transform: rotate(200deg);
        -o-transform: rotate(200deg);
        transform: rotate(200deg);
        opacity: 0;
    }
}

@-o-keyframes rotateOut {
    0% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(200deg);
        -moz-transform: rotate(200deg);
        -ms-transform: rotate(200deg);
        -o-transform: rotate(200deg);
        transform: rotate(200deg);
        opacity: 0;
    }
}

@-ms-keyframes rotateOut {
    0% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(200deg);
        -moz-transform: rotate(200deg);
        -ms-transform: rotate(200deg);
        -o-transform: rotate(200deg);
        transform: rotate(200deg);
        opacity: 0;
    }
}

@keyframes rotateOut {
    0% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(200deg);
        -moz-transform: rotate(200deg);
        -ms-transform: rotate(200deg);
        -o-transform: rotate(200deg);
        transform: rotate(200deg);
        opacity: 0;
    }
}

@-moz-keyframes rotateOutDownLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }
}

@-webkit-keyframes rotateOutDownLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }
}

@-o-keyframes rotateOutDownLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }
}

@-ms-keyframes rotateOutDownLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }
}

@keyframes rotateOutDownLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }
}

@-moz-keyframes rotateOutUpLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }
}

@-webkit-keyframes rotateOutUpLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }
}

@-o-keyframes rotateOutUpLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }
}

@-ms-keyframes rotateOutUpLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }
}

@keyframes rotateOutUpLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }
}

@-moz-keyframes rotateOutDownRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }
}

@-webkit-keyframes rotateOutDownRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }
}

@-o-keyframes rotateOutDownRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }
}

@-ms-keyframes rotateOutDownRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }
}

@keyframes rotateOutDownRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }
}

@-moz-keyframes rotateOutUpRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }
}

@-webkit-keyframes rotateOutUpRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }
}

@-o-keyframes rotateOutUpRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }
}

@-ms-keyframes rotateOutUpRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }
}

@keyframes rotateOutUpRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }
}

@-moz-keyframes rotateIn {
    0% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(-200deg);
        -moz-transform: rotate(-200deg);
        -ms-transform: rotate(-200deg);
        -o-transform: rotate(-200deg);
        transform: rotate(-200deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-webkit-keyframes rotateIn {
    0% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(-200deg);
        -moz-transform: rotate(-200deg);
        -ms-transform: rotate(-200deg);
        -o-transform: rotate(-200deg);
        transform: rotate(-200deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-o-keyframes rotateIn {
    0% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(-200deg);
        -moz-transform: rotate(-200deg);
        -ms-transform: rotate(-200deg);
        -o-transform: rotate(-200deg);
        transform: rotate(-200deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-ms-keyframes rotateIn {
    0% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(-200deg);
        -moz-transform: rotate(-200deg);
        -ms-transform: rotate(-200deg);
        -o-transform: rotate(-200deg);
        transform: rotate(-200deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotateIn {
    0% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(-200deg);
        -moz-transform: rotate(-200deg);
        -ms-transform: rotate(-200deg);
        -o-transform: rotate(-200deg);
        transform: rotate(-200deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: center center 50%;
        -moz-transform-origin: center center 50%;
        -ms-transform-origin: center center 50%;
        -o-transform-origin: center center 50%;
        transform-origin: center center 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-moz-keyframes rotateInDownLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-webkit-keyframes rotateInDownLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-o-keyframes rotateInDownLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-ms-keyframes rotateInDownLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotateInDownLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-moz-keyframes rotateInUpLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-webkit-keyframes rotateInUpLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-o-keyframes rotateInUpLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-ms-keyframes rotateInUpLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotateInUpLeft {
    0% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: left bottom 50%;
        -moz-transform-origin: left bottom 50%;
        -ms-transform-origin: left bottom 50%;
        -o-transform-origin: left bottom 50%;
        transform-origin: left bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-moz-keyframes rotateInUpRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-webkit-keyframes rotateInUpRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-o-keyframes rotateInUpRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-ms-keyframes rotateInUpRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotateInUpRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-moz-keyframes rotateInDownRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-webkit-keyframes rotateInDownRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-o-keyframes rotateInDownRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-ms-keyframes rotateInDownRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotateInDownRight {
    0% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: right bottom 50%;
        -moz-transform-origin: right bottom 50%;
        -ms-transform-origin: right bottom 50%;
        -o-transform-origin: right bottom 50%;
        transform-origin: right bottom 50%;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@-moz-keyframes hinge {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    20%,
    60% {
        -webkit-transform: rotate(80deg);
        -moz-transform: rotate(80deg);
        -ms-transform: rotate(80deg);
        -o-transform: rotate(80deg);
        transform: rotate(80deg);
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    40% {
        -webkit-transform: rotate(60deg);
        -moz-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
        -o-transform: rotate(60deg);
        transform: rotate(60deg);
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    80% {
        -webkit-transform: rotate(60deg) translateY(0);
        -moz-transform: rotate(60deg) translateY(0);
        -ms-transform: rotate(60deg) translateY(0);
        -o-transform: rotate(60deg) translateY(0);
        transform: rotate(60deg) translateY(0);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    100% {
        -webkit-transform: translateY(700px);
        -moz-transform: translateY(700px);
        -ms-transform: translateY(700px);
        -o-transform: translateY(700px);
        transform: translateY(700px);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-webkit-keyframes hinge {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    20%,
    60% {
        -webkit-transform: rotate(80deg);
        -moz-transform: rotate(80deg);
        -ms-transform: rotate(80deg);
        -o-transform: rotate(80deg);
        transform: rotate(80deg);
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    40% {
        -webkit-transform: rotate(60deg);
        -moz-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
        -o-transform: rotate(60deg);
        transform: rotate(60deg);
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    80% {
        -webkit-transform: rotate(60deg) translateY(0);
        -moz-transform: rotate(60deg) translateY(0);
        -ms-transform: rotate(60deg) translateY(0);
        -o-transform: rotate(60deg) translateY(0);
        transform: rotate(60deg) translateY(0);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    100% {
        -webkit-transform: translateY(700px);
        -moz-transform: translateY(700px);
        -ms-transform: translateY(700px);
        -o-transform: translateY(700px);
        transform: translateY(700px);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-o-keyframes hinge {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    20%,
    60% {
        -webkit-transform: rotate(80deg);
        -moz-transform: rotate(80deg);
        -ms-transform: rotate(80deg);
        -o-transform: rotate(80deg);
        transform: rotate(80deg);
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    40% {
        -webkit-transform: rotate(60deg);
        -moz-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
        -o-transform: rotate(60deg);
        transform: rotate(60deg);
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    80% {
        -webkit-transform: rotate(60deg) translateY(0);
        -moz-transform: rotate(60deg) translateY(0);
        -ms-transform: rotate(60deg) translateY(0);
        -o-transform: rotate(60deg) translateY(0);
        transform: rotate(60deg) translateY(0);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    100% {
        -webkit-transform: translateY(700px);
        -moz-transform: translateY(700px);
        -ms-transform: translateY(700px);
        -o-transform: translateY(700px);
        transform: translateY(700px);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-ms-keyframes hinge {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    20%,
    60% {
        -webkit-transform: rotate(80deg);
        -moz-transform: rotate(80deg);
        -ms-transform: rotate(80deg);
        -o-transform: rotate(80deg);
        transform: rotate(80deg);
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    40% {
        -webkit-transform: rotate(60deg);
        -moz-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
        -o-transform: rotate(60deg);
        transform: rotate(60deg);
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    80% {
        -webkit-transform: rotate(60deg) translateY(0);
        -moz-transform: rotate(60deg) translateY(0);
        -ms-transform: rotate(60deg) translateY(0);
        -o-transform: rotate(60deg) translateY(0);
        transform: rotate(60deg) translateY(0);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    100% {
        -webkit-transform: translateY(700px);
        -moz-transform: translateY(700px);
        -ms-transform: translateY(700px);
        -o-transform: translateY(700px);
        transform: translateY(700px);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@keyframes hinge {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    20%,
    60% {
        -webkit-transform: rotate(80deg);
        -moz-transform: rotate(80deg);
        -ms-transform: rotate(80deg);
        -o-transform: rotate(80deg);
        transform: rotate(80deg);
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    40% {
        -webkit-transform: rotate(60deg);
        -moz-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
        -o-transform: rotate(60deg);
        transform: rotate(60deg);
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    80% {
        -webkit-transform: rotate(60deg) translateY(0);
        -moz-transform: rotate(60deg) translateY(0);
        -ms-transform: rotate(60deg) translateY(0);
        -o-transform: rotate(60deg) translateY(0);
        transform: rotate(60deg) translateY(0);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform-origin: top left 50%;
        -moz-transform-origin: top left 50%;
        -ms-transform-origin: top left 50%;
        -o-transform-origin: top left 50%;
        transform-origin: top left 50%;
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    100% {
        -webkit-transform: translateY(700px);
        -moz-transform: translateY(700px);
        -ms-transform: translateY(700px);
        -o-transform: translateY(700px);
        transform: translateY(700px);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
    }
}

@-moz-keyframes rollIn {
    0% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        -moz-transform: translateX(-100%) rotate(-120deg);
        -ms-transform: translateX(-100%) rotate(-120deg);
        -o-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg);
    }

    100% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
        -moz-transform: translateX(0px) rotate(0deg);
        -ms-transform: translateX(0px) rotate(0deg);
        -o-transform: translateX(0px) rotate(0deg);
        transform: translateX(0px) rotate(0deg);
    }
}

@-webkit-keyframes rollIn {
    0% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        -moz-transform: translateX(-100%) rotate(-120deg);
        -ms-transform: translateX(-100%) rotate(-120deg);
        -o-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg);
    }

    100% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
        -moz-transform: translateX(0px) rotate(0deg);
        -ms-transform: translateX(0px) rotate(0deg);
        -o-transform: translateX(0px) rotate(0deg);
        transform: translateX(0px) rotate(0deg);
    }
}

@-o-keyframes rollIn {
    0% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        -moz-transform: translateX(-100%) rotate(-120deg);
        -ms-transform: translateX(-100%) rotate(-120deg);
        -o-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg);
    }

    100% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
        -moz-transform: translateX(0px) rotate(0deg);
        -ms-transform: translateX(0px) rotate(0deg);
        -o-transform: translateX(0px) rotate(0deg);
        transform: translateX(0px) rotate(0deg);
    }
}

@-ms-keyframes rollIn {
    0% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        -moz-transform: translateX(-100%) rotate(-120deg);
        -ms-transform: translateX(-100%) rotate(-120deg);
        -o-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg);
    }

    100% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
        -moz-transform: translateX(0px) rotate(0deg);
        -ms-transform: translateX(0px) rotate(0deg);
        -o-transform: translateX(0px) rotate(0deg);
        transform: translateX(0px) rotate(0deg);
    }
}

@keyframes rollIn {
    0% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        -moz-transform: translateX(-100%) rotate(-120deg);
        -ms-transform: translateX(-100%) rotate(-120deg);
        -o-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg);
    }

    100% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
        -moz-transform: translateX(0px) rotate(0deg);
        -ms-transform: translateX(0px) rotate(0deg);
        -o-transform: translateX(0px) rotate(0deg);
        transform: translateX(0px) rotate(0deg);
    }
}

@-moz-keyframes rollOut {
    0% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
        -moz-transform: translateX(0px) rotate(0deg);
        -ms-transform: translateX(0px) rotate(0deg);
        -o-transform: translateX(0px) rotate(0deg);
        transform: translateX(0px) rotate(0deg);
    }

    100% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        -moz-transform: translateX(-100%) rotate(-120deg);
        -ms-transform: translateX(-100%) rotate(-120deg);
        -o-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg);
    }
}

@-webkit-keyframes rollOut {
    0% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
        -moz-transform: translateX(0px) rotate(0deg);
        -ms-transform: translateX(0px) rotate(0deg);
        -o-transform: translateX(0px) rotate(0deg);
        transform: translateX(0px) rotate(0deg);
    }

    100% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        -moz-transform: translateX(-100%) rotate(-120deg);
        -ms-transform: translateX(-100%) rotate(-120deg);
        -o-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg);
    }
}

@-o-keyframes rollOut {
    0% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
        -moz-transform: translateX(0px) rotate(0deg);
        -ms-transform: translateX(0px) rotate(0deg);
        -o-transform: translateX(0px) rotate(0deg);
        transform: translateX(0px) rotate(0deg);
    }

    100% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        -moz-transform: translateX(-100%) rotate(-120deg);
        -ms-transform: translateX(-100%) rotate(-120deg);
        -o-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg);
    }
}

@-ms-keyframes rollOut {
    0% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
        -moz-transform: translateX(0px) rotate(0deg);
        -ms-transform: translateX(0px) rotate(0deg);
        -o-transform: translateX(0px) rotate(0deg);
        transform: translateX(0px) rotate(0deg);
    }

    100% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        -moz-transform: translateX(-100%) rotate(-120deg);
        -ms-transform: translateX(-100%) rotate(-120deg);
        -o-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg);
    }
}

@keyframes rollOut {
    0% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
        -moz-transform: translateX(0px) rotate(0deg);
        -ms-transform: translateX(0px) rotate(0deg);
        -o-transform: translateX(0px) rotate(0deg);
        transform: translateX(0px) rotate(0deg);
    }

    100% {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        -moz-transform: translateX(-100%) rotate(-120deg);
        -ms-transform: translateX(-100%) rotate(-120deg);
        -o-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg);
    }
}

/*-------------------------------------------------------------------*/
/* === Boostrap Main SCSS === */
/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
    --blue: #5e50f9;
    --indigo: #6610f2;
    --purple: #6a008a;
    --pink: #e91e63;
    --red: #f96868;
    --orange: #f2a654;
    --yellow: #f6e84e;
    --green: #46c35f;
    --teal: #58d8a3;
    --cyan: #57c7d4;
    --white: #ffffff;
    --gray: #6c757d;
    --gray-dark: #0f1531;
    --blue: #5e50f9;
    --indigo: #6610f2;
    --purple: #6a008a;
    --pink: #e91e63;
    --red: #f96868;
    --orange: #f2a654;
    --yellow: #f6e84e;
    --green: #46c35f;
    --teal: #58d8a3;
    --cyan: #57c7d4;
    --white: #ffffff;
    --gray: #434a54;
    --gray-light: #aab2bd;
    --gray-lighter: #e8eff4;
    --gray-lightest: #e6e9ed;
    --black: #000000;
    --primary: #ec37fc;
    --secondary: #e4e6f6;
    --success: #51c81c;
    --info: #3794fc;
    --warning: #fcb41d;
    --danger: #fc381d;
    --light: #dee5ef;
    --dark: #001737;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif:
        -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --font-family-monospace:
        SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-family:
        -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #666666;
    text-align: left;
    background-color: #fff;
}

[tabindex='-1']:focus:not(:focus-visible) {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}

pre,
code,
kbd,
samp {
    font-family:
        SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 1rem 1rem;
    padding-bottom: 1rem 1rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

[role='button'] {
    cursor: pointer;
}

select {
    word-wrap: normal;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
    cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}

[type='search'] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1,
.h1 {
    font-size: 2.5rem;
}

h2,
.h2 {
    font-size: 2rem;
}

h3,
.h3 {
    font-size: 1.75rem;
}

h4,
.h4 {
    font-size: 1.5rem;
}

h5,
.h5 {
    font-size: 1.25rem;
}

h6,
.h6 {
    font-size: 1rem;
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
    font-size: 80%;
    font-weight: 400;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
}

.blockquote-footer::before {
    content: '\2014\00A0';
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 90%;
    color: #6c757d;
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word;
}

a > code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
}

pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    width: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container,
    .container-sm {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container,
    .container-sm,
    .container-md {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1140px;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.row-cols-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
}

.row-cols-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
}

.row-cols-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.row-cols-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
}

.row-cols-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
}

.row-cols-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    order: -1;
}

.order-last {
    order: 13;
}

.order-0 {
    order: 0;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.order-4 {
    order: 4;
}

.order-5 {
    order: 5;
}

.order-6 {
    order: 6;
}

.order-7 {
    order: 7;
}

.order-8 {
    order: 8;
}

.order-9 {
    order: 9;
}

.order-10 {
    order: 10;
}

.order-11 {
    order: 11;
}

.order-12 {
    order: 12;
}

.offset-1 {
    margin-left: 8.33333%;
}

.offset-2 {
    margin-left: 16.66667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.33333%;
}

.offset-5 {
    margin-left: 41.66667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.33333%;
}

.offset-8 {
    margin-left: 66.66667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.33333%;
}

.offset-11 {
    margin-left: 91.66667%;
}

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-sm-1 > * {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-sm-2 > * {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-sm-3 > * {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .row-cols-sm-4 > * {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-sm-5 > * {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-sm-6 > * {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-sm-first {
        order: -1;
    }

    .order-sm-last {
        order: 13;
    }

    .order-sm-0 {
        order: 0;
    }

    .order-sm-1 {
        order: 1;
    }

    .order-sm-2 {
        order: 2;
    }

    .order-sm-3 {
        order: 3;
    }

    .order-sm-4 {
        order: 4;
    }

    .order-sm-5 {
        order: 5;
    }

    .order-sm-6 {
        order: 6;
    }

    .order-sm-7 {
        order: 7;
    }

    .order-sm-8 {
        order: 8;
    }

    .order-sm-9 {
        order: 9;
    }

    .order-sm-10 {
        order: 10;
    }

    .order-sm-11 {
        order: 11;
    }

    .order-sm-12 {
        order: 12;
    }

    .offset-sm-0 {
        margin-left: 0;
    }

    .offset-sm-1 {
        margin-left: 8.33333%;
    }

    .offset-sm-2 {
        margin-left: 16.66667%;
    }

    .offset-sm-3 {
        margin-left: 25%;
    }

    .offset-sm-4 {
        margin-left: 33.33333%;
    }

    .offset-sm-5 {
        margin-left: 41.66667%;
    }

    .offset-sm-6 {
        margin-left: 50%;
    }

    .offset-sm-7 {
        margin-left: 58.33333%;
    }

    .offset-sm-8 {
        margin-left: 66.66667%;
    }

    .offset-sm-9 {
        margin-left: 75%;
    }

    .offset-sm-10 {
        margin-left: 83.33333%;
    }

    .offset-sm-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-md-1 > * {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-md-2 > * {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-md-3 > * {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .row-cols-md-4 > * {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-md-5 > * {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-md-6 > * {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-md-first {
        order: -1;
    }

    .order-md-last {
        order: 13;
    }

    .order-md-0 {
        order: 0;
    }

    .order-md-1 {
        order: 1;
    }

    .order-md-2 {
        order: 2;
    }

    .order-md-3 {
        order: 3;
    }

    .order-md-4 {
        order: 4;
    }

    .order-md-5 {
        order: 5;
    }

    .order-md-6 {
        order: 6;
    }

    .order-md-7 {
        order: 7;
    }

    .order-md-8 {
        order: 8;
    }

    .order-md-9 {
        order: 9;
    }

    .order-md-10 {
        order: 10;
    }

    .order-md-11 {
        order: 11;
    }

    .order-md-12 {
        order: 12;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: 8.33333%;
    }

    .offset-md-2 {
        margin-left: 16.66667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    .offset-md-4 {
        margin-left: 33.33333%;
    }

    .offset-md-5 {
        margin-left: 41.66667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    .offset-md-7 {
        margin-left: 58.33333%;
    }

    .offset-md-8 {
        margin-left: 66.66667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    .offset-md-10 {
        margin-left: 83.33333%;
    }

    .offset-md-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-lg-1 > * {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-lg-2 > * {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-lg-3 > * {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .row-cols-lg-4 > * {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-lg-5 > * {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-lg-6 > * {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-lg-first {
        order: -1;
    }

    .order-lg-last {
        order: 13;
    }

    .order-lg-0 {
        order: 0;
    }

    .order-lg-1 {
        order: 1;
    }

    .order-lg-2 {
        order: 2;
    }

    .order-lg-3 {
        order: 3;
    }

    .order-lg-4 {
        order: 4;
    }

    .order-lg-5 {
        order: 5;
    }

    .order-lg-6 {
        order: 6;
    }

    .order-lg-7 {
        order: 7;
    }

    .order-lg-8 {
        order: 8;
    }

    .order-lg-9 {
        order: 9;
    }

    .order-lg-10 {
        order: 10;
    }

    .order-lg-11 {
        order: 11;
    }

    .order-lg-12 {
        order: 12;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: 8.33333%;
    }

    .offset-lg-2 {
        margin-left: 16.66667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.33333%;
    }

    .offset-lg-5 {
        margin-left: 41.66667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.33333%;
    }

    .offset-lg-8 {
        margin-left: 66.66667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.33333%;
    }

    .offset-lg-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-xl-1 > * {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-xl-2 > * {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-xl-3 > * {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .row-cols-xl-4 > * {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-xl-5 > * {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-xl-6 > * {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xl-first {
        order: -1;
    }

    .order-xl-last {
        order: 13;
    }

    .order-xl-0 {
        order: 0;
    }

    .order-xl-1 {
        order: 1;
    }

    .order-xl-2 {
        order: 2;
    }

    .order-xl-3 {
        order: 3;
    }

    .order-xl-4 {
        order: 4;
    }

    .order-xl-5 {
        order: 5;
    }

    .order-xl-6 {
        order: 6;
    }

    .order-xl-7 {
        order: 7;
    }

    .order-xl-8 {
        order: 8;
    }

    .order-xl-9 {
        order: 9;
    }

    .order-xl-10 {
        order: 10;
    }

    .order-xl-11 {
        order: 11;
    }

    .order-xl-12 {
        order: 12;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: 8.33333%;
    }

    .offset-xl-2 {
        margin-left: 16.66667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.33333%;
    }

    .offset-xl-5 {
        margin-left: 41.66667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.33333%;
    }

    .offset-xl-8 {
        margin-left: 66.66667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.33333%;
    }

    .offset-xl-11 {
        margin-left: 91.66667%;
    }
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #666666;
}

.table th,
.table td {
    padding: 1rem 1rem;
    vertical-align: top;
    border-top: 1px solid #e4e6f6;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e4e6f6;
}

.table tbody + tbody {
    border-top: 2px solid #e4e6f6;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid #e4e6f6;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #e4e6f6;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f4f7fa;
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: #eaeaf1;
}

.table-primary,
.table-primary > th,
.table-primary > td {
    background-color: #fac7fe;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
    border-color: #f597fd;
}

.table-hover .table-primary:hover {
    background-color: #f8aefe;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
    background-color: #f8aefe;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
    background-color: #f7f8fc;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
    border-color: #f1f2fa;
}

.table-hover .table-secondary:hover {
    background-color: #e4e8f5;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
    background-color: #e4e8f5;
}

.table-success,
.table-success > th,
.table-success > td {
    background-color: #cef0bf;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
    border-color: #a5e289;
}

.table-hover .table-success:hover {
    background-color: #beebaa;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
    background-color: #beebaa;
}

.table-info,
.table-info > th,
.table-info > td {
    background-color: #c7e1fe;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
    border-color: #97c7fd;
}

.table-hover .table-info:hover {
    background-color: #aed4fe;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
    background-color: #aed4fe;
}

.table-warning,
.table-warning > th,
.table-warning > td {
    background-color: #feeac0;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
    border-color: #fdd889;
}

.table-hover .table-warning:hover {
    background-color: #fee2a7;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
    background-color: #fee2a7;
}

.table-danger,
.table-danger > th,
.table-danger > td {
    background-color: #fec7c0;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
    border-color: #fd9889;
}

.table-hover .table-danger:hover {
    background-color: #feb1a7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
    background-color: #feb1a7;
}

.table-light,
.table-light > th,
.table-light > td {
    background-color: #f6f8fb;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
    border-color: #eef1f7;
}

.table-hover .table-light:hover {
    background-color: #e4eaf3;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
    background-color: #e4eaf3;
}

.table-dark,
.table-dark > th,
.table-dark > td {
    background-color: #b8bec7;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #7a8697;
}

.table-hover .table-dark:hover {
    background-color: #aab1bc;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
    background-color: #aab1bc;
}

.table-active,
.table-active > th,
.table-active > td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #e4e6f6;
}

.table-dark {
    color: #fff;
    background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #454d55;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-sm > .table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-md > .table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-lg > .table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-xl > .table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
    border: 0;
}

.form-control,
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--single .select2-search__field,
.typeahead,
.tt-query,
.tt-hint {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.875rem 1.375rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2px;
    transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-control,
    .select2-container--default .select2-selection--single,
    .select2-container--default .select2-selection--single .select2-search__field,
    .typeahead,
    .tt-query,
    .tt-hint {
        transition: none;
    }
}

.form-control::-ms-expand,
.select2-container--default .select2-selection--single::-ms-expand,
.select2-container--default .select2-selection--single .select2-search__field::-ms-expand,
.typeahead::-ms-expand,
.tt-query::-ms-expand,
.tt-hint::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:-moz-focusring,
.select2-container--default .select2-selection--single:-moz-focusring,
.select2-container--default .select2-selection--single .select2-search__field:-moz-focusring,
.typeahead:-moz-focusring,
.tt-query:-moz-focusring,
.tt-hint:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
}

.form-control:focus,
.select2-container--default .select2-selection--single:focus,
.select2-container--default .select2-selection--single .select2-search__field:focus,
.typeahead:focus,
.tt-query:focus,
.tt-hint:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::placeholder,
.select2-container--default .select2-selection--single::placeholder,
.select2-container--default .select2-selection--single .select2-search__field::placeholder,
.typeahead::placeholder,
.tt-query::placeholder,
.tt-hint::placeholder {
    color: #666666;
    opacity: 1;
}

.form-control:disabled,
.select2-container--default .select2-selection--single:disabled,
.select2-container--default .select2-selection--single .select2-search__field:disabled,
.typeahead:disabled,
.tt-query:disabled,
.tt-hint:disabled,
.form-control[readonly],
.select2-container--default .select2-selection--single[readonly],
.select2-container--default .select2-selection--single .select2-search__field[readonly],
.typeahead[readonly],
.tt-query[readonly],
.tt-hint[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

input[type='date'].form-control,
.select2-container--default input.select2-selection--single[type='date'],
.select2-container--default .select2-selection--single input.select2-search__field[type='date'],
input.typeahead[type='date'],
input.tt-query[type='date'],
input.tt-hint[type='date'],
input[type='time'].form-control,
.select2-container--default input.select2-selection--single[type='time'],
.select2-container--default .select2-selection--single input.select2-search__field[type='time'],
input.typeahead[type='time'],
input.tt-query[type='time'],
input.tt-hint[type='time'],
input[type='datetime-local'].form-control,
.select2-container--default input.select2-selection--single[type='datetime-local'],
.select2-container--default
    .select2-selection--single
    input.select2-search__field[type='datetime-local'],
input.typeahead[type='datetime-local'],
input.tt-query[type='datetime-local'],
input.tt-hint[type='datetime-local'],
input[type='month'].form-control,
.select2-container--default input.select2-selection--single[type='month'],
.select2-container--default .select2-selection--single input.select2-search__field[type='month'],
input.typeahead[type='month'],
input.tt-query[type='month'],
input.tt-hint[type='month'] {
    appearance: none;
}

select.form-control:focus::-ms-value,
.select2-container--default select.select2-selection--single:focus::-ms-value,
.select2-container--default
    .select2-selection--single
    select.select2-search__field:focus::-ms-value,
select.typeahead:focus::-ms-value,
select.tt-query:focus::-ms-value,
select.tt-hint:focus::-ms-value {
    color: #495057;
    background-color: #ffffff;
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

.col-form-label {
    padding-top: calc(0.875rem + 1px);
    padding-bottom: calc(0.875rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1;
}

.col-form-label-lg {
    padding-top: calc(0.94rem + 1px);
    padding-bottom: calc(0.94rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.875rem 0;
    margin-bottom: 0;
    font-size: 0.875rem;
    line-height: 1;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.5rem 0.81rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.94rem 1.94rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

select.form-control[size],
.select2-container--default select.select2-selection--single[size],
.select2-container--default .select2-selection--single select.select2-search__field[size],
select.typeahead[size],
select.tt-query[size],
select.tt-hint[size],
select.form-control[multiple],
.select2-container--default select.select2-selection--single[multiple],
.select2-container--default .select2-selection--single select.select2-search__field[multiple],
select.typeahead[multiple],
select.tt-query[multiple],
select.tt-hint[multiple] {
    height: auto;
}

textarea.form-control,
.select2-container--default textarea.select2-selection--single,
.select2-container--default .select2-selection--single textarea.select2-search__field,
textarea.typeahead,
textarea.tt-query,
textarea.tt-hint {
    height: auto;
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row > .col,
.form-row > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
    color: #666666;
}

.form-check-label {
    margin-bottom: 0;
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #28a745;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(40, 167, 69, 0.9);
    border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
    display: block;
}

.was-validated .form-control:valid,
.was-validated .select2-container--default .select2-selection--single:valid,
.select2-container--default .was-validated .select2-selection--single:valid,
.was-validated .select2-container--default .select2-selection--single .select2-search__field:valid,
.select2-container--default .select2-selection--single .was-validated .select2-search__field:valid,
.was-validated .typeahead:valid,
.was-validated .tt-query:valid,
.was-validated .tt-hint:valid,
.form-control.is-valid,
.select2-container--default .is-valid.select2-selection--single,
.select2-container--default .select2-selection--single .is-valid.select2-search__field,
.is-valid.typeahead,
.is-valid.tt-query,
.is-valid.tt-hint {
    border-color: #28a745;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.was-validated .select2-container--default .select2-selection--single:valid:focus,
.select2-container--default .was-validated .select2-selection--single:valid:focus,
.was-validated
    .select2-container--default
    .select2-selection--single
    .select2-search__field:valid:focus,
.select2-container--default
    .select2-selection--single
    .was-validated
    .select2-search__field:valid:focus,
.was-validated .typeahead:valid:focus,
.was-validated .tt-query:valid:focus,
.was-validated .tt-hint:valid:focus,
.form-control.is-valid:focus,
.select2-container--default .is-valid.select2-selection--single:focus,
.select2-container--default .select2-selection--single .is-valid.select2-search__field:focus,
.is-valid.typeahead:focus,
.is-valid.tt-query:focus,
.is-valid.tt-hint:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid,
.was-validated .select2-container--default textarea.select2-selection--single:valid,
.select2-container--default .was-validated textarea.select2-selection--single:valid,
.was-validated
    .select2-container--default
    .select2-selection--single
    textarea.select2-search__field:valid,
.select2-container--default
    .select2-selection--single
    .was-validated
    textarea.select2-search__field:valid,
.was-validated textarea.typeahead:valid,
.was-validated textarea.tt-query:valid,
.was-validated textarea.tt-hint:valid,
textarea.form-control.is-valid,
.select2-container--default textarea.is-valid.select2-selection--single,
.select2-container--default .select2-selection--single textarea.is-valid.select2-search__field,
textarea.is-valid.typeahead,
textarea.is-valid.tt-query,
textarea.is-valid.tt-hint {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: #28a745;
    padding-right: calc(0.75em + 2.3125rem);
    background:
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
            no-repeat right 0.75rem center/8px 10px,
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
            #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
    color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
    color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
    border-color: #34ce57;
    background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
    border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
    display: block;
}

.was-validated .form-control:invalid,
.was-validated .select2-container--default .select2-selection--single:invalid,
.select2-container--default .was-validated .select2-selection--single:invalid,
.was-validated
    .select2-container--default
    .select2-selection--single
    .select2-search__field:invalid,
.select2-container--default
    .select2-selection--single
    .was-validated
    .select2-search__field:invalid,
.was-validated .typeahead:invalid,
.was-validated .tt-query:invalid,
.was-validated .tt-hint:invalid,
.form-control.is-invalid,
.select2-container--default .is-invalid.select2-selection--single,
.select2-container--default .select2-selection--single .is-invalid.select2-search__field,
.is-invalid.typeahead,
.is-invalid.tt-query,
.is-invalid.tt-hint {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.was-validated .select2-container--default .select2-selection--single:invalid:focus,
.select2-container--default .was-validated .select2-selection--single:invalid:focus,
.was-validated
    .select2-container--default
    .select2-selection--single
    .select2-search__field:invalid:focus,
.select2-container--default
    .select2-selection--single
    .was-validated
    .select2-search__field:invalid:focus,
.was-validated .typeahead:invalid:focus,
.was-validated .tt-query:invalid:focus,
.was-validated .tt-hint:invalid:focus,
.form-control.is-invalid:focus,
.select2-container--default .is-invalid.select2-selection--single:focus,
.select2-container--default .select2-selection--single .is-invalid.select2-search__field:focus,
.is-invalid.typeahead:focus,
.is-invalid.tt-query:focus,
.is-invalid.tt-hint:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid,
.was-validated .select2-container--default textarea.select2-selection--single:invalid,
.select2-container--default .was-validated textarea.select2-selection--single:invalid,
.was-validated
    .select2-container--default
    .select2-selection--single
    textarea.select2-search__field:invalid,
.select2-container--default
    .select2-selection--single
    .was-validated
    textarea.select2-search__field:invalid,
.was-validated textarea.typeahead:invalid,
.was-validated textarea.tt-query:invalid,
.was-validated textarea.tt-hint:invalid,
textarea.form-control.is-invalid,
.select2-container--default textarea.is-invalid.select2-selection--single,
.select2-container--default .select2-selection--single textarea.is-invalid.select2-search__field,
textarea.is-invalid.typeahead,
textarea.is-invalid.tt-query,
textarea.is-invalid.tt-hint {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: #dc3545;
    padding-right: calc(0.75em + 2.3125rem);
    background:
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
            no-repeat right 0.75rem center/8px 10px,
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
            #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
    color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.form-inline .form-check {
    width: 100%;
}

@media (min-width: 576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
    }

    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0;
    }

    .form-inline .form-control,
    .form-inline .select2-container--default .select2-selection--single,
    .select2-container--default .form-inline .select2-selection--single,
    .form-inline .select2-container--default .select2-selection--single .select2-search__field,
    .select2-container--default .select2-selection--single .form-inline .select2-search__field,
    .form-inline .typeahead,
    .form-inline .tt-query,
    .form-inline .tt-hint {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .form-inline .form-control-plaintext {
        display: inline-block;
    }

    .form-inline .input-group,
    .form-inline .custom-select {
        width: auto;
    }

    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }

    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0;
    }

    .form-inline .custom-control {
        align-items: center;
        justify-content: center;
    }

    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}

.btn,
.ajax-upload-dragdrop .ajax-file-upload {
    display: inline-block;
    font-weight: 400;
    color: #666666;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.687rem 0.875rem;
    font-size: 0.875rem;
    line-height: 1;
    border-radius: 0.1875rem;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .btn,
    .ajax-upload-dragdrop .ajax-file-upload {
        transition: none;
    }
}

.btn:hover,
.ajax-upload-dragdrop .ajax-file-upload:hover {
    color: #666666;
    text-decoration: none;
}

.btn:focus,
.ajax-upload-dragdrop .ajax-file-upload:focus,
.btn.focus,
.ajax-upload-dragdrop .focus.ajax-file-upload {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled,
.ajax-upload-dragdrop .disabled.ajax-file-upload,
.btn:disabled,
.ajax-upload-dragdrop .ajax-file-upload:disabled {
    opacity: 0.65;
}

.btn:not(:disabled):not(.disabled),
.ajax-upload-dragdrop .ajax-file-upload:not(:disabled):not(.disabled) {
    cursor: pointer;
}

a.btn.disabled,
.ajax-upload-dragdrop a.disabled.ajax-file-upload,
fieldset:disabled a.btn,
fieldset:disabled .ajax-upload-dragdrop a.ajax-file-upload,
.ajax-upload-dragdrop fieldset:disabled a.ajax-file-upload {
    pointer-events: none;
}

.btn-primary {
    color: #fff;
    background-color: #ec37fc;
    border-color: #ec37fc;
}

.btn-primary:hover {
    color: #fff;
    background-color: #e811fb;
    border-color: #e705fb;
}

.btn-primary:focus,
.btn-primary.focus {
    color: #fff;
    background-color: #e811fb;
    border-color: #e705fb;
    box-shadow: 0 0 0 0.2rem rgba(239, 85, 252, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #ec37fc;
    border-color: #ec37fc;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #e705fb;
    border-color: #dc04f0;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(239, 85, 252, 0.5);
}

.btn-secondary {
    color: #212529;
    background-color: #e4e6f6;
    border-color: #e4e6f6;
}

.btn-secondary:hover {
    color: #212529;
    background-color: #c7cbec;
    border-color: #bec3e9;
}

.btn-secondary:focus,
.btn-secondary.focus {
    color: #212529;
    background-color: #c7cbec;
    border-color: #bec3e9;
    box-shadow: 0 0 0 0.2rem rgba(199, 201, 215, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #212529;
    background-color: #e4e6f6;
    border-color: #e4e6f6;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #bec3e9;
    border-color: #b4bae6;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(199, 201, 215, 0.5);
}

.btn-success {
    color: #fff;
    background-color: #51c81c;
    border-color: #51c81c;
}

.btn-success:hover {
    color: #fff;
    background-color: #43a617;
    border-color: #3f9b16;
}

.btn-success:focus,
.btn-success.focus {
    color: #fff;
    background-color: #43a617;
    border-color: #3f9b16;
    box-shadow: 0 0 0 0.2rem rgba(107, 208, 62, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #51c81c;
    border-color: #51c81c;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3f9b16;
    border-color: #3a9014;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(107, 208, 62, 0.5);
}

.btn-info {
    color: #fff;
    background-color: #3794fc;
    border-color: #3794fc;
}

.btn-info:hover {
    color: #fff;
    background-color: #1180fb;
    border-color: #0579fb;
}

.btn-info:focus,
.btn-info.focus {
    color: #fff;
    background-color: #1180fb;
    border-color: #0579fb;
    box-shadow: 0 0 0 0.2rem rgba(85, 164, 252, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #3794fc;
    border-color: #3794fc;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #0579fb;
    border-color: #0473f0;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(85, 164, 252, 0.5);
}

.btn-warning {
    color: #212529;
    background-color: #fcb41d;
    border-color: #fcb41d;
}

.btn-warning:hover {
    color: #212529;
    background-color: #f0a303;
    border-color: #e39b03;
}

.btn-warning:focus,
.btn-warning.focus {
    color: #212529;
    background-color: #f0a303;
    border-color: #e39b03;
    box-shadow: 0 0 0 0.2rem rgba(219, 159, 31, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #212529;
    background-color: #fcb41d;
    border-color: #fcb41d;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #e39b03;
    border-color: #d69203;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(219, 159, 31, 0.5);
}

.btn-danger {
    color: #fff;
    background-color: #fc381d;
    border-color: #fc381d;
}

.btn-danger:hover {
    color: #fff;
    background-color: #f02003;
    border-color: #e31e03;
}

.btn-danger:focus,
.btn-danger.focus {
    color: #fff;
    background-color: #f02003;
    border-color: #e31e03;
    box-shadow: 0 0 0 0.2rem rgba(252, 86, 63, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #fc381d;
    border-color: #fc381d;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #e31e03;
    border-color: #d61c03;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 86, 63, 0.5);
}

.btn-light {
    color: #212529;
    background-color: #dee5ef;
    border-color: #dee5ef;
}

.btn-light:hover {
    color: #212529;
    background-color: #c4d1e3;
    border-color: #bccade;
}

.btn-light:focus,
.btn-light.focus {
    color: #212529;
    background-color: #c4d1e3;
    border-color: #bccade;
    box-shadow: 0 0 0 0.2rem rgba(194, 200, 209, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
    color: #212529;
    background-color: #dee5ef;
    border-color: #dee5ef;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #bccade;
    border-color: #b3c3da;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(194, 200, 209, 0.5);
}

.btn-dark {
    color: #fff;
    background-color: #001737;
    border-color: #001737;
}

.btn-dark:hover {
    color: #fff;
    background-color: #000711;
    border-color: #000204;
}

.btn-dark:focus,
.btn-dark.focus {
    color: #fff;
    background-color: #000711;
    border-color: #000204;
    box-shadow: 0 0 0 0.2rem rgba(38, 58, 85, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #001737;
    border-color: #001737;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #000204;
    border-color: black;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 58, 85, 0.5);
}

.btn-outline-primary {
    color: #ec37fc;
    border-color: #ec37fc;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #ec37fc;
    border-color: #ec37fc;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(236, 55, 252, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #ec37fc;
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #ec37fc;
    border-color: #ec37fc;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(236, 55, 252, 0.5);
}

.btn-outline-secondary {
    color: #e4e6f6;
    border-color: #e4e6f6;
}

.btn-outline-secondary:hover {
    color: #212529;
    background-color: #e4e6f6;
    border-color: #e4e6f6;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(228, 230, 246, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #e4e6f6;
    background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #e4e6f6;
    border-color: #e4e6f6;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(228, 230, 246, 0.5);
}

.btn-outline-success {
    color: #51c81c;
    border-color: #51c81c;
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #51c81c;
    border-color: #51c81c;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(81, 200, 28, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #51c81c;
    background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #51c81c;
    border-color: #51c81c;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(81, 200, 28, 0.5);
}

.btn-outline-info {
    color: #3794fc;
    border-color: #3794fc;
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #3794fc;
    border-color: #3794fc;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(55, 148, 252, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #3794fc;
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #3794fc;
    border-color: #3794fc;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(55, 148, 252, 0.5);
}

.btn-outline-warning {
    color: #fcb41d;
    border-color: #fcb41d;
}

.btn-outline-warning:hover {
    color: #212529;
    background-color: #fcb41d;
    border-color: #fcb41d;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 180, 29, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #fcb41d;
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #fcb41d;
    border-color: #fcb41d;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 180, 29, 0.5);
}

.btn-outline-danger {
    color: #fc381d;
    border-color: #fc381d;
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #fc381d;
    border-color: #fc381d;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 56, 29, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #fc381d;
    background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #fc381d;
    border-color: #fc381d;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 56, 29, 0.5);
}

.btn-outline-light {
    color: #dee5ef;
    border-color: #dee5ef;
}

.btn-outline-light:hover {
    color: #212529;
    background-color: #dee5ef;
    border-color: #dee5ef;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 229, 239, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #dee5ef;
    background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #dee5ef;
    border-color: #dee5ef;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 229, 239, 0.5);
}

.btn-outline-dark {
    color: #001737;
    border-color: #001737;
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #001737;
    border-color: #001737;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 23, 55, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #001737;
    background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #001737;
    border-color: #001737;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 23, 55, 0.5);
}

.btn-link {
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
}

.btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn,
.ajax-upload-dragdrop .btn-group-lg > .ajax-file-upload {
    padding: 1rem 3rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.1875rem;
}

.btn-sm,
.btn-group-sm > .btn,
.ajax-upload-dragdrop .btn-group-sm > .ajax-file-upload {
    padding: 0.5rem 0.81rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.1875rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block + .btn-block {
    margin-top: 0.5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
    width: 100%;
}

.fade {
    transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
}

.dropdown-toggle {
    white-space: nowrap;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #666666;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e6f6;
    border-radius: 0.25rem;
}

.dropdown-menu-left {
    right: auto;
    left: 0;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

@media (min-width: 576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-sm-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-md-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-lg-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-xl-right {
        right: 0;
        left: auto;
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-toggle::after {
    vertical-align: 0;
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
}

.dropleft .dropdown-toggle::after {
    display: none;
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0;
}

.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'] {
    right: auto;
    bottom: auto;
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e4e6f6;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #666666;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #eaeaf1;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #666666;
    white-space: nowrap;
}

.dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #666666;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}

.btn-group > .btn,
.ajax-upload-dragdrop .btn-group > .ajax-file-upload,
.btn-group-vertical > .btn,
.ajax-upload-dragdrop .btn-group-vertical > .ajax-file-upload {
    position: relative;
    flex: 1 1 auto;
}

.btn-group > .btn:hover,
.ajax-upload-dragdrop .btn-group > .ajax-file-upload:hover,
.btn-group-vertical > .btn:hover,
.ajax-upload-dragdrop .btn-group-vertical > .ajax-file-upload:hover {
    z-index: 1;
}

.btn-group > .btn:focus,
.ajax-upload-dragdrop .btn-group > .ajax-file-upload:focus,
.btn-group > .btn:active,
.ajax-upload-dragdrop .btn-group > .ajax-file-upload:active,
.btn-group > .btn.active,
.ajax-upload-dragdrop .btn-group > .active.ajax-file-upload,
.btn-group-vertical > .btn:focus,
.ajax-upload-dragdrop .btn-group-vertical > .ajax-file-upload:focus,
.btn-group-vertical > .btn:active,
.ajax-upload-dragdrop .btn-group-vertical > .ajax-file-upload:active,
.btn-group-vertical > .btn.active,
.ajax-upload-dragdrop .btn-group-vertical > .active.ajax-file-upload {
    z-index: 1;
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group > .btn:not(:first-child),
.ajax-upload-dragdrop .btn-group > .ajax-file-upload:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
    margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.ajax-upload-dragdrop .btn-group > .ajax-file-upload:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.ajax-upload-dragdrop .btn-group > .btn-group:not(:last-child) > .ajax-file-upload {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.ajax-upload-dragdrop .btn-group > .ajax-file-upload:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.ajax-upload-dragdrop .btn-group > .btn-group:not(:first-child) > .ajax-file-upload {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
    padding-right: 0.65625rem;
    padding-left: 0.65625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split,
.ajax-upload-dragdrop .btn-group-sm > .ajax-file-upload + .dropdown-toggle-split {
    padding-right: 0.6075rem;
    padding-left: 0.6075rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split,
.ajax-upload-dragdrop .btn-group-lg > .ajax-file-upload + .dropdown-toggle-split {
    padding-right: 2.25rem;
    padding-left: 2.25rem;
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.btn-group-vertical > .btn,
.ajax-upload-dragdrop .btn-group-vertical > .ajax-file-upload,
.btn-group-vertical > .btn-group {
    width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.ajax-upload-dragdrop .btn-group-vertical > .ajax-file-upload:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.ajax-upload-dragdrop
    .btn-group-vertical
    > .ajax-file-upload:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.ajax-upload-dragdrop .btn-group-vertical > .btn-group:not(:last-child) > .ajax-file-upload {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.ajax-upload-dragdrop .btn-group-vertical > .ajax-file-upload:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.ajax-upload-dragdrop .btn-group-vertical > .btn-group:not(:first-child) > .ajax-file-upload {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.ajax-upload-dragdrop .btn-group-toggle > .ajax-file-upload,
.btn-group-toggle > .btn-group > .btn,
.ajax-upload-dragdrop .btn-group-toggle > .btn-group > .ajax-file-upload {
    margin-bottom: 0;
}

.btn-group-toggle > .btn input[type='radio'],
.ajax-upload-dragdrop .btn-group-toggle > .ajax-file-upload input[type='radio'],
.btn-group-toggle > .btn input[type='checkbox'],
.ajax-upload-dragdrop .btn-group-toggle > .ajax-file-upload input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'],
.ajax-upload-dragdrop .btn-group-toggle > .btn-group > .ajax-file-upload input[type='radio'],
.btn-group-toggle > .btn-group > .btn input[type='checkbox'],
.ajax-upload-dragdrop .btn-group-toggle > .btn-group > .ajax-file-upload input[type='checkbox'] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group > .form-control,
.select2-container--default .input-group > .select2-selection--single,
.select2-container--default .select2-selection--single .input-group > .select2-search__field,
.input-group > .typeahead,
.input-group > .tt-query,
.input-group > .tt-hint,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.select2-container--default .input-group > .select2-selection--single + .form-control,
.select2-container--default
    .select2-selection--single
    .input-group
    > .select2-search__field
    + .form-control,
.input-group > .typeahead + .form-control,
.input-group > .tt-query + .form-control,
.input-group > .tt-hint + .form-control,
.select2-container--default .input-group > .form-control + .select2-selection--single,
.select2-container--default .input-group > .select2-selection--single + .select2-selection--single,
.select2-container--default
    .select2-selection--single
    .input-group
    > .select2-search__field
    + .select2-selection--single,
.select2-container--default .input-group > .typeahead + .select2-selection--single,
.select2-container--default .input-group > .tt-query + .select2-selection--single,
.select2-container--default .input-group > .tt-hint + .select2-selection--single,
.select2-container--default
    .select2-selection--single
    .input-group
    > .form-control
    + .select2-search__field,
.select2-container--default
    .select2-selection--single
    .input-group
    > .select2-selection--single
    + .select2-search__field,
.select2-container--default
    .select2-selection--single
    .input-group
    > .select2-search__field
    + .select2-search__field,
.select2-container--default
    .select2-selection--single
    .input-group
    > .typeahead
    + .select2-search__field,
.select2-container--default
    .select2-selection--single
    .input-group
    > .tt-query
    + .select2-search__field,
.select2-container--default
    .select2-selection--single
    .input-group
    > .tt-hint
    + .select2-search__field,
.input-group > .form-control + .typeahead,
.select2-container--default .input-group > .select2-selection--single + .typeahead,
.select2-container--default
    .select2-selection--single
    .input-group
    > .select2-search__field
    + .typeahead,
.input-group > .typeahead + .typeahead,
.input-group > .tt-query + .typeahead,
.input-group > .tt-hint + .typeahead,
.input-group > .form-control + .tt-query,
.select2-container--default .input-group > .select2-selection--single + .tt-query,
.select2-container--default
    .select2-selection--single
    .input-group
    > .select2-search__field
    + .tt-query,
.input-group > .typeahead + .tt-query,
.input-group > .tt-query + .tt-query,
.input-group > .tt-hint + .tt-query,
.input-group > .form-control + .tt-hint,
.select2-container--default .input-group > .select2-selection--single + .tt-hint,
.select2-container--default
    .select2-selection--single
    .input-group
    > .select2-search__field
    + .tt-hint,
.input-group > .typeahead + .tt-hint,
.input-group > .tt-query + .tt-hint,
.input-group > .tt-hint + .tt-hint,
.input-group > .form-control + .custom-select,
.select2-container--default .input-group > .select2-selection--single + .custom-select,
.select2-container--default
    .select2-selection--single
    .input-group
    > .select2-search__field
    + .custom-select,
.input-group > .typeahead + .custom-select,
.input-group > .tt-query + .custom-select,
.input-group > .tt-hint + .custom-select,
.input-group > .form-control + .custom-file,
.select2-container--default .input-group > .select2-selection--single + .custom-file,
.select2-container--default
    .select2-selection--single
    .input-group
    > .select2-search__field
    + .custom-file,
.input-group > .typeahead + .custom-file,
.input-group > .tt-query + .custom-file,
.input-group > .tt-hint + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.select2-container--default .input-group > .form-control-plaintext + .select2-selection--single,
.select2-container--default
    .select2-selection--single
    .input-group
    > .form-control-plaintext
    + .select2-search__field,
.input-group > .form-control-plaintext + .typeahead,
.input-group > .form-control-plaintext + .tt-query,
.input-group > .form-control-plaintext + .tt-hint,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.select2-container--default .input-group > .custom-select + .select2-selection--single,
.select2-container--default
    .select2-selection--single
    .input-group
    > .custom-select
    + .select2-search__field,
.input-group > .custom-select + .typeahead,
.input-group > .custom-select + .tt-query,
.input-group > .custom-select + .tt-hint,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.select2-container--default .input-group > .custom-file + .select2-selection--single,
.select2-container--default
    .select2-selection--single
    .input-group
    > .custom-file
    + .select2-search__field,
.input-group > .custom-file + .typeahead,
.input-group > .custom-file + .tt-query,
.input-group > .custom-file + .tt-hint,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
    margin-left: -1px;
}

.input-group > .form-control:focus,
.select2-container--default .input-group > .select2-selection--single:focus,
.select2-container--default .select2-selection--single .input-group > .select2-search__field:focus,
.input-group > .typeahead:focus,
.input-group > .tt-query:focus,
.input-group > .tt-hint:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
    z-index: 4;
}

.input-group > .form-control:not(:last-child),
.select2-container--default .input-group > .select2-selection--single:not(:last-child),
.select2-container--default
    .select2-selection--single
    .input-group
    > .select2-search__field:not(:last-child),
.input-group > .typeahead:not(:last-child),
.input-group > .tt-query:not(:last-child),
.input-group > .tt-hint:not(:last-child),
.input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.select2-container--default .input-group > .select2-selection--single:not(:first-child),
.select2-container--default
    .select2-selection--single
    .input-group
    > .select2-search__field:not(:first-child),
.input-group > .typeahead:not(:first-child),
.input-group > .tt-query:not(:first-child),
.input-group > .tt-hint:not(:first-child),
.input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group > .custom-file {
    display: flex;
    align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
    display: flex;
}

.input-group-prepend .btn,
.input-group-prepend .ajax-upload-dragdrop .ajax-file-upload,
.ajax-upload-dragdrop .input-group-prepend .ajax-file-upload,
.input-group-append .btn,
.input-group-append .ajax-upload-dragdrop .ajax-file-upload,
.ajax-upload-dragdrop .input-group-append .ajax-file-upload {
    position: relative;
    z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-prepend .ajax-upload-dragdrop .ajax-file-upload:focus,
.ajax-upload-dragdrop .input-group-prepend .ajax-file-upload:focus,
.input-group-append .btn:focus,
.input-group-append .ajax-upload-dragdrop .ajax-file-upload:focus,
.ajax-upload-dragdrop .input-group-append .ajax-file-upload:focus {
    z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .ajax-upload-dragdrop .ajax-file-upload + .btn,
.ajax-upload-dragdrop .input-group-prepend .ajax-file-upload + .btn,
.input-group-prepend .ajax-upload-dragdrop .btn + .ajax-file-upload,
.ajax-upload-dragdrop .input-group-prepend .btn + .ajax-file-upload,
.input-group-prepend .ajax-upload-dragdrop .ajax-file-upload + .ajax-file-upload,
.ajax-upload-dragdrop .input-group-prepend .ajax-file-upload + .ajax-file-upload,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .ajax-upload-dragdrop .ajax-file-upload + .input-group-text,
.ajax-upload-dragdrop .input-group-prepend .ajax-file-upload + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .ajax-upload-dragdrop .input-group-text + .ajax-file-upload,
.ajax-upload-dragdrop .input-group-prepend .input-group-text + .ajax-file-upload,
.input-group-append .btn + .btn,
.input-group-append .ajax-upload-dragdrop .ajax-file-upload + .btn,
.ajax-upload-dragdrop .input-group-append .ajax-file-upload + .btn,
.input-group-append .ajax-upload-dragdrop .btn + .ajax-file-upload,
.ajax-upload-dragdrop .input-group-append .btn + .ajax-file-upload,
.input-group-append .ajax-upload-dragdrop .ajax-file-upload + .ajax-file-upload,
.ajax-upload-dragdrop .input-group-append .ajax-file-upload + .ajax-file-upload,
.input-group-append .btn + .input-group-text,
.input-group-append .ajax-upload-dragdrop .ajax-file-upload + .input-group-text,
.ajax-upload-dragdrop .input-group-append .ajax-file-upload + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .ajax-upload-dragdrop .input-group-text + .ajax-file-upload,
.ajax-upload-dragdrop .input-group-append .input-group-text + .ajax-file-upload {
    margin-left: -1px;
}

.input-group-prepend {
    margin-right: -1px;
}

.input-group-append {
    margin-left: -1px;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.875rem 1.375rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 2px;
}

.input-group-text input[type='radio'],
.input-group-text input[type='checkbox'] {
    margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.select2-container--default .input-group-lg > .select2-selection--single:not(textarea),
.select2-container--default
    .select2-selection--single
    .input-group-lg
    > .select2-search__field:not(textarea),
.input-group-lg > .typeahead:not(textarea),
.input-group-lg > .tt-query:not(textarea),
.input-group-lg > .tt-hint:not(textarea),
.input-group-lg > .custom-select {
    height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.select2-container--default .input-group-lg > .select2-selection--single,
.select2-container--default .select2-selection--single .input-group-lg > .select2-search__field,
.input-group-lg > .typeahead,
.input-group-lg > .tt-query,
.input-group-lg > .tt-hint,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.ajax-upload-dragdrop .input-group-lg > .input-group-prepend > .ajax-file-upload,
.input-group-lg > .input-group-append > .btn,
.ajax-upload-dragdrop .input-group-lg > .input-group-append > .ajax-file-upload {
    padding: 0.94rem 1.94rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.select2-container--default .input-group-sm > .select2-selection--single:not(textarea),
.select2-container--default
    .select2-selection--single
    .input-group-sm
    > .select2-search__field:not(textarea),
.input-group-sm > .typeahead:not(textarea),
.input-group-sm > .tt-query:not(textarea),
.input-group-sm > .tt-hint:not(textarea),
.input-group-sm > .custom-select {
    height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.select2-container--default .input-group-sm > .select2-selection--single,
.select2-container--default .select2-selection--single .input-group-sm > .select2-search__field,
.input-group-sm > .typeahead,
.input-group-sm > .tt-query,
.input-group-sm > .tt-hint,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.ajax-upload-dragdrop .input-group-sm > .input-group-prepend > .ajax-file-upload,
.input-group-sm > .input-group-append > .btn,
.ajax-upload-dragdrop .input-group-sm > .input-group-append > .ajax-file-upload {
    padding: 0.5rem 0.81rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
    padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.ajax-upload-dragdrop .input-group > .input-group-prepend > .ajax-file-upload,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.ajax-upload-dragdrop .input-group > .input-group-append:not(:last-child) > .ajax-file-upload,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.ajax-upload-dragdrop
    .input-group
    > .input-group-append:last-child
    > .ajax-file-upload:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.ajax-upload-dragdrop .input-group > .input-group-append > .ajax-file-upload,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.ajax-upload-dragdrop .input-group > .input-group-prepend:not(:first-child) > .ajax-file-upload,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.ajax-upload-dragdrop
    .input-group
    > .input-group-prepend:first-child
    > .ajax-file-upload:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    color-adjust: exact;
}

.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #e9ecef;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: '';
    background-color: #fff;
    border: #adb5bd solid 1px;
}

.custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: '';
    background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #007bff;
    background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
    padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition:
        transform 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
        transition: none;
    }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        no-repeat right 0.75rem center/8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    appearance: none;
}

.custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select:focus::-ms-value {
    color: #495057;
    background-color: #ffffff;
}

.custom-select[multiple],
.custom-select[size]:not([size='1']) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
}

.custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
}

.custom-select::-ms-expand {
    display: none;
}

.custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
}

.custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
    content: 'Browse';
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse);
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: 'Browse';
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    appearance: none;
}

.custom-range:focus {
    outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow:
        0 0 0 1px #fff,
        0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
    box-shadow:
        0 0 0 1px #fff,
        0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
    box-shadow:
        0 0 0 1px #fff,
        0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
    border: 0;
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
        transition: none;
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
        transition: none;
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
        transition: none;
    }
}

.custom-range::-ms-thumb:active {
    background-color: #b3d7ff;
}

.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
    cursor: default;
}

.custom-range:disabled::-ms-thumb {
    background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        transition: none;
    }
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none;
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff;
    background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

.tab-content > .tab-pane {
    display: none;
}

.tab-content > .active {
    display: block;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
    text-decoration: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: '';
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-xl {
        flex-wrap: nowrap;
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}

@media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-xl {
        flex-wrap: nowrap;
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

@media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-xl {
        flex-wrap: nowrap;
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-xl {
        flex-wrap: nowrap;
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0;
}

.navbar-expand .navbar-nav {
    flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
    flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
}

.navbar-expand .navbar-toggler {
    display: none;
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
    color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
    color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: #fff;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e4e6f6;
    border-radius: 0;
}

.card > hr {
    margin-right: 0;
    margin-left: 0;
}

.card > .list-group {
    border-top: inherit;
    border-bottom: inherit;
}

.card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
    border-top: 0;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link + .card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid #e4e6f6;
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid #e4e6f6;
}

.card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
    flex-shrink: 0;
    width: 100%;
}

.card-img,
.card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-deck {
        display: flex;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .card-deck .card {
        flex: 1 0 0%;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.card-group > .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap;
    }

    .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0;
    }

    .card-group > .card + .card {
        margin-left: 0;
        border-left: 0;
    }

    .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-group > .card:not(:last-child) .card-img-top,
    .card-group > .card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }

    .card-group > .card:not(:last-child) .card-img-bottom,
    .card-group > .card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }

    .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .card-group > .card:not(:first-child) .card-img-top,
    .card-group > .card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }

    .card-group > .card:not(:first-child) .card-img-bottom,
    .card-group > .card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.accordion {
    overflow-anchor: none;
}

.accordion > .card {
    overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.breadcrumb-item {
    display: flex;
}

.breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: '/';
}

.breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #6c757d;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none;
    }
}

a.badge:hover,
a.badge:focus {
    text-decoration: none;
}

.badge:empty {
    display: none;
}

.btn .badge,
.ajax-upload-dragdrop .ajax-file-upload .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-primary {
    color: #fff;
    background-color: #ec37fc;
}

a.badge-primary:hover,
a.badge-primary:focus {
    color: #fff;
    background-color: #e705fb;
}

a.badge-primary:focus,
a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(236, 55, 252, 0.5);
}

.badge-secondary {
    color: #212529;
    background-color: #e4e6f6;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
    color: #212529;
    background-color: #bec3e9;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(228, 230, 246, 0.5);
}

.badge-success,
.preview-list .preview-item .preview-thumbnail .badge.badge-online {
    color: #fff;
    background-color: #51c81c;
}

a.badge-success:hover,
.preview-list .preview-item .preview-thumbnail a.badge.badge-online:hover,
a.badge-success:focus,
.preview-list .preview-item .preview-thumbnail a.badge.badge-online:focus {
    color: #fff;
    background-color: #3f9b16;
}

a.badge-success:focus,
.preview-list .preview-item .preview-thumbnail a.badge.badge-online:focus,
a.badge-success.focus,
.preview-list .preview-item .preview-thumbnail a.focus.badge.badge-online {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(81, 200, 28, 0.5);
}

.badge-info,
.preview-list .preview-item .preview-thumbnail .badge.badge-offline {
    color: #fff;
    background-color: #3794fc;
}

a.badge-info:hover,
.preview-list .preview-item .preview-thumbnail a.badge.badge-offline:hover,
a.badge-info:focus,
.preview-list .preview-item .preview-thumbnail a.badge.badge-offline:focus {
    color: #fff;
    background-color: #0579fb;
}

a.badge-info:focus,
.preview-list .preview-item .preview-thumbnail a.badge.badge-offline:focus,
a.badge-info.focus,
.preview-list .preview-item .preview-thumbnail a.focus.badge.badge-offline {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(55, 148, 252, 0.5);
}

.badge-warning,
.preview-list .preview-item .preview-thumbnail .badge.badge-busy {
    color: #212529;
    background-color: #fcb41d;
}

a.badge-warning:hover,
.preview-list .preview-item .preview-thumbnail a.badge.badge-busy:hover,
a.badge-warning:focus,
.preview-list .preview-item .preview-thumbnail a.badge.badge-busy:focus {
    color: #212529;
    background-color: #e39b03;
}

a.badge-warning:focus,
.preview-list .preview-item .preview-thumbnail a.badge.badge-busy:focus,
a.badge-warning.focus,
.preview-list .preview-item .preview-thumbnail a.focus.badge.badge-busy {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(252, 180, 29, 0.5);
}

.badge-danger {
    color: #fff;
    background-color: #fc381d;
}

a.badge-danger:hover,
a.badge-danger:focus {
    color: #fff;
    background-color: #e31e03;
}

a.badge-danger:focus,
a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(252, 56, 29, 0.5);
}

.badge-light {
    color: #212529;
    background-color: #dee5ef;
}

a.badge-light:hover,
a.badge-light:focus {
    color: #212529;
    background-color: #bccade;
}

a.badge-light:focus,
a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(222, 229, 239, 0.5);
}

.badge-dark {
    color: #fff;
    background-color: #001737;
}

a.badge-dark:hover,
a.badge-dark:focus {
    color: #fff;
    background-color: #000204;
}

a.badge-dark:focus,
a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 23, 55, 0.5);
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem;
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 4rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

.alert-primary {
    color: #7b1d83;
    background-color: #fbd7fe;
    border-color: #fac7fe;
}

.alert-primary hr {
    border-top-color: #f8aefe;
}

.alert-primary .alert-link {
    color: #541459;
}

.alert-secondary {
    color: #777880;
    background-color: #fafafd;
    border-color: #f7f8fc;
}

.alert-secondary hr {
    border-top-color: #e4e8f5;
}

.alert-secondary .alert-link {
    color: #5e5f66;
}

.alert-success {
    color: #2a680f;
    background-color: #dcf4d2;
    border-color: #cef0bf;
}

.alert-success hr {
    border-top-color: #beebaa;
}

.alert-success .alert-link {
    color: #183b09;
}

.alert-info {
    color: #1d4d83;
    background-color: #d7eafe;
    border-color: #c7e1fe;
}

.alert-info hr {
    border-top-color: #aed4fe;
}

.alert-info .alert-link {
    color: #143459;
}

.alert-warning {
    color: #835e0f;
    background-color: #fef0d2;
    border-color: #feeac0;
}

.alert-warning hr {
    border-top-color: #fee2a7;
}

.alert-warning .alert-link {
    color: #553d0a;
}

.alert-danger {
    color: #831d0f;
    background-color: #fed7d2;
    border-color: #fec7c0;
}

.alert-danger hr {
    border-top-color: #feb1a7;
}

.alert-danger .alert-link {
    color: #55130a;
}

.alert-light {
    color: #73777c;
    background-color: #f8fafc;
    border-color: #f6f8fb;
}

.alert-light hr {
    border-top-color: #e4eaf3;
}

.alert-light .alert-link {
    color: #5a5e62;
}

.alert-dark {
    color: #000c1d;
    background-color: #ccd1d7;
    border-color: #b8bec7;
}

.alert-dark hr {
    border-top-color: #aab1bc;
}

.alert-dark .alert-link {
    color: black;
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }

    to {
        background-position: 0 0;
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}

.progress-bar-striped {
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-size: 1rem 1rem;
}

.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        animation: none;
    }
}

.media {
    display: flex;
    align-items: flex-start;
}

.media-body {
    flex: 1;
    font-size: 0.8rem;
}

.media.body p,
.media-body h4 {
    font-size: 0.8rem !important;
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem;
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
}

.list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.list-group-item + .list-group-item {
    border-top-width: 0;
}

.list-group-item + .list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
}

.list-group-horizontal {
    flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
    margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction: row;
    }

    .list-group-horizontal-sm > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-sm > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-sm > .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-sm > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction: row;
    }

    .list-group-horizontal-md > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-md > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-md > .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-md > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction: row;
    }

    .list-group-horizontal-lg > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-lg > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-lg > .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-lg > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction: row;
    }

    .list-group-horizontal-xl > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-xl > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-xl > .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-xl > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

.list-group-flush {
    border-radius: 0;
}

.list-group-flush > .list-group-item {
    border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0;
}

.list-group-item-primary {
    color: #7b1d83;
    background-color: #fac7fe;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #7b1d83;
    background-color: #f8aefe;
}

.list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #7b1d83;
    border-color: #7b1d83;
}

.list-group-item-secondary {
    color: #777880;
    background-color: #f7f8fc;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #777880;
    background-color: #e4e8f5;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #777880;
    border-color: #777880;
}

.list-group-item-success {
    color: #2a680f;
    background-color: #cef0bf;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #2a680f;
    background-color: #beebaa;
}

.list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #2a680f;
    border-color: #2a680f;
}

.list-group-item-info {
    color: #1d4d83;
    background-color: #c7e1fe;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #1d4d83;
    background-color: #aed4fe;
}

.list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #1d4d83;
    border-color: #1d4d83;
}

.list-group-item-warning {
    color: #835e0f;
    background-color: #feeac0;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #835e0f;
    background-color: #fee2a7;
}

.list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #835e0f;
    border-color: #835e0f;
}

.list-group-item-danger {
    color: #831d0f;
    background-color: #fec7c0;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #831d0f;
    background-color: #feb1a7;
}

.list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #831d0f;
    border-color: #831d0f;
}

.list-group-item-light {
    color: #73777c;
    background-color: #f6f8fb;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #73777c;
    background-color: #e4eaf3;
}

.list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #73777c;
    border-color: #73777c;
}

.list-group-item-dark {
    color: #000c1d;
    background-color: #b8bec7;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #000c1d;
    background-color: #aab1bc;
}

.list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #000c1d;
    border-color: #000c1d;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}

.close:hover {
    color: #000;
    text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
    opacity: 0.75;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}

a.close.disabled {
    pointer-events: none;
}

.toast {
    flex-basis: 350px;
    max-width: 350px;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    opacity: 0;
    border-radius: 0.25rem;
}

.toast:not(:last-child) {
    margin-bottom: 0.75rem;
}

.toast.showing {
    opacity: 1;
}

.toast.show {
    display: block;
    opacity: 1;
}

.toast.hide {
    display: none;
}

.toast-header {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
    padding: 0.75rem;
}

.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal.show .modal-dialog {
    transform: none;
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02);
}

.modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
    flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: '';
}

.modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050 !important;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
    margin: 0.25rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }

    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem);
    }

    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem);
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }

    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
        height: min-content;
    }

    .modal-sm {
        max-width: 300px !important;
    }
}

@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family:
        -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.75rem;
    word-wrap: break-word;
    opacity: 0;
}

.tooltip.show {
    opacity: 0.9;
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}

.tooltip .arrow::before {
    position: absolute;
    content: '';
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^='top'] {
    padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^='top'] .arrow {
    bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^='right'] {
    padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^='right'] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^='bottom'] {
    padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^='bottom'] .arrow {
    top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^='left'] {
    padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^='left'] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
}

.tooltip-inner {
    max-width: 200px;
    padding: 0.4rem 0.75rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.375rem;
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family:
        -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
    position: absolute;
    display: block;
    content: '';
    border-color: transparent;
    border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^='top'] {
    margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^='top'] > .arrow {
    bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^='top'] > .arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^='top'] > .arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^='right'] {
    margin-left: 0.5rem;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^='right'] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^='right'] > .arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^='right'] > .arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^='bottom'] {
    margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^='bottom'] > .arrow {
    top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^='bottom'] > .arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^='bottom'] > .arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^='bottom'] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: '';
    border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^='left'] {
    margin-right: 0.5rem;
}

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^='left'] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^='left'] > .arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^='left'] > .arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
}

.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
    display: none;
}

.popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529;
}

.carousel {
    position: relative;
}

.carousel.pointer-event {
    touch-action: pan-y;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: '';
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-item {
        transition: none;
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
    transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
    transform: translateX(-100%);
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        transition: none;
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
        transition: none;
    }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
        transition: none;
    }
}

.carousel-indicators .active {
    opacity: 1;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@keyframes spinner-grow {
    0% {
        transform: scale(0);
    }

    50% {
        opacity: 1;
        transform: none;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.bg-primary {
    background-color: #ec37fc !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #e705fb !important;
}

.bg-secondary {
    background-color: #e4e6f6 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: #bec3e9 !important;
}

.bg-success {
    background-color: #51c81c !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: #3f9b16 !important;
}

.bg-info {
    background-color: #3794fc !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: #0579fb !important;
}

.bg-warning {
    background-color: #fcb41d !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #e39b03 !important;
}

.bg-danger {
    background-color: #fc381d !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #e31e03 !important;
}

.bg-light {
    background-color: #dee5ef !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
    background-color: #bccade !important;
}

.bg-dark {
    background-color: #001737 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
    background-color: #000204 !important;
}

.bg-white {
    background-color: #ffffff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.border {
    border: 1px solid #e4e6f6 !important;
}

.border-top {
    border-top: 1px solid #e4e6f6 !important;
}

.border-right {
    border-right: 1px solid #e4e6f6 !important;
}

.border-bottom {
    border-bottom: 1px solid #e4e6f6 !important;
}

.border-left {
    border-left: 1px solid #e4e6f6 !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: #ec37fc !important;
}

.border-secondary {
    border-color: #e4e6f6 !important;
}

.border-success {
    border-color: #51c81c !important;
}

.border-info {
    border-color: #3794fc !important;
}

.border-warning {
    border-color: #fcb41d !important;
}

.border-danger {
    border-color: #fc381d !important;
}

.border-light {
    border-color: #dee5ef !important;
}

.border-dark {
    border-color: #001737 !important;
}

.border-white {
    border-color: #ffffff !important;
}

.rounded-sm {
    border-radius: 0.2rem !important;
}

.rounded {
    border-radius: 0.25rem !important;
}

.rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}

.rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
    border-radius: 0.3rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: '';
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex,
.navbar .navbar-menu-wrapper .navbar-nav,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }

    .d-sm-inline {
        display: inline !important;
    }

    .d-sm-inline-block {
        display: inline-block !important;
    }

    .d-sm-block {
        display: block !important;
    }

    .d-sm-table {
        display: table !important;
    }

    .d-sm-table-row {
        display: table-row !important;
    }

    .d-sm-table-cell {
        display: table-cell !important;
    }

    .d-sm-flex {
        display: flex !important;
    }

    .d-sm-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }

    .d-md-inline {
        display: inline !important;
    }

    .d-md-inline-block {
        display: inline-block !important;
    }

    .d-md-block {
        display: block !important;
    }

    .d-md-table {
        display: table !important;
    }

    .d-md-table-row {
        display: table-row !important;
    }

    .d-md-table-cell {
        display: table-cell !important;
    }

    .d-md-flex {
        display: flex !important;
    }

    .d-md-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }

    .d-lg-inline {
        display: inline !important;
    }

    .d-lg-inline-block {
        display: inline-block !important;
    }

    .d-lg-block {
        display: block !important;
    }

    .d-lg-table {
        display: table !important;
    }

    .d-lg-table-row {
        display: table-row !important;
    }

    .d-lg-table-cell {
        display: table-cell !important;
    }

    .d-lg-flex {
        display: flex !important;
    }

    .d-lg-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }

    .d-xl-inline {
        display: inline !important;
    }

    .d-xl-inline-block {
        display: inline-block !important;
    }

    .d-xl-block {
        display: block !important;
    }

    .d-xl-table {
        display: table !important;
    }

    .d-xl-table-row {
        display: table-row !important;
    }

    .d-xl-table-cell {
        display: table-cell !important;
    }

    .d-xl-flex {
        display: flex !important;
    }

    .d-xl-inline-flex {
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }

    .d-print-inline {
        display: inline !important;
    }

    .d-print-inline-block {
        display: inline-block !important;
    }

    .d-print-block {
        display: block !important;
    }

    .d-print-table {
        display: table !important;
    }

    .d-print-table-row {
        display: table-row !important;
    }

    .d-print-table-cell {
        display: table-cell !important;
    }

    .d-print-flex {
        display: flex !important;
    }

    .d-print-inline-flex {
        display: inline-flex !important;
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    display: block;
    content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.flex-row,
.navbar .navbar-menu-wrapper .navbar-nav {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    flex-shrink: 1 !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center,
.navbar .navbar-menu-wrapper .navbar-nav,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item,
.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right .nav-item {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch,
.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
    align-items: stretch !important;
}

.align-content-start {
    align-content: flex-start !important;
}

.align-content-end {
    align-content: flex-end !important;
}

.align-content-center {
    align-content: center !important;
}

.align-content-between {
    align-content: space-between !important;
}

.align-content-around {
    align-content: space-around !important;
}

.align-content-stretch {
    align-content: stretch !important;
}

.align-self-auto {
    align-self: auto !important;
}

.align-self-start {
    align-self: flex-start !important;
}

.align-self-end {
    align-self: flex-end !important;
}

.align-self-center {
    align-self: center !important;
}

.align-self-baseline {
    align-self: baseline !important;
}

.align-self-stretch,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings,
.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
    align-self: stretch !important;
}

@media (min-width: 576px) {
    .flex-sm-row {
        flex-direction: row !important;
    }

    .flex-sm-column {
        flex-direction: column !important;
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-sm-fill {
        flex: 1 1 auto !important;
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-sm-start {
        justify-content: flex-start !important;
    }

    .justify-content-sm-end {
        justify-content: flex-end !important;
    }

    .justify-content-sm-center {
        justify-content: center !important;
    }

    .justify-content-sm-between {
        justify-content: space-between !important;
    }

    .justify-content-sm-around {
        justify-content: space-around !important;
    }

    .align-items-sm-start {
        align-items: flex-start !important;
    }

    .align-items-sm-end {
        align-items: flex-end !important;
    }

    .align-items-sm-center {
        align-items: center !important;
    }

    .align-items-sm-baseline {
        align-items: baseline !important;
    }

    .align-items-sm-stretch {
        align-items: stretch !important;
    }

    .align-content-sm-start {
        align-content: flex-start !important;
    }

    .align-content-sm-end {
        align-content: flex-end !important;
    }

    .align-content-sm-center {
        align-content: center !important;
    }

    .align-content-sm-between {
        align-content: space-between !important;
    }

    .align-content-sm-around {
        align-content: space-around !important;
    }

    .align-content-sm-stretch {
        align-content: stretch !important;
    }

    .align-self-sm-auto {
        align-self: auto !important;
    }

    .align-self-sm-start {
        align-self: flex-start !important;
    }

    .align-self-sm-end {
        align-self: flex-end !important;
    }

    .align-self-sm-center {
        align-self: center !important;
    }

    .align-self-sm-baseline {
        align-self: baseline !important;
    }

    .align-self-sm-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row !important;
    }

    .flex-md-column {
        flex-direction: column !important;
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-md-wrap {
        flex-wrap: wrap !important;
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-md-fill {
        flex: 1 1 auto !important;
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-md-start {
        justify-content: flex-start !important;
    }

    .justify-content-md-end {
        justify-content: flex-end !important;
    }

    .justify-content-md-center {
        justify-content: center !important;
    }

    .justify-content-md-between {
        justify-content: space-between !important;
    }

    .justify-content-md-around {
        justify-content: space-around !important;
    }

    .align-items-md-start {
        align-items: flex-start !important;
    }

    .align-items-md-end {
        align-items: flex-end !important;
    }

    .align-items-md-center {
        align-items: center !important;
    }

    .align-items-md-baseline {
        align-items: baseline !important;
    }

    .align-items-md-stretch {
        align-items: stretch !important;
    }

    .align-content-md-start {
        align-content: flex-start !important;
    }

    .align-content-md-end {
        align-content: flex-end !important;
    }

    .align-content-md-center {
        align-content: center !important;
    }

    .align-content-md-between {
        align-content: space-between !important;
    }

    .align-content-md-around {
        align-content: space-around !important;
    }

    .align-content-md-stretch {
        align-content: stretch !important;
    }

    .align-self-md-auto {
        align-self: auto !important;
    }

    .align-self-md-start {
        align-self: flex-start !important;
    }

    .align-self-md-end {
        align-self: flex-end !important;
    }

    .align-self-md-center {
        align-self: center !important;
    }

    .align-self-md-baseline {
        align-self: baseline !important;
    }

    .align-self-md-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        flex-direction: row !important;
    }

    .flex-lg-column {
        flex-direction: column !important;
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-lg-fill {
        flex: 1 1 auto !important;
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-lg-start {
        justify-content: flex-start !important;
    }

    .justify-content-lg-end {
        justify-content: flex-end !important;
    }

    .justify-content-lg-center {
        justify-content: center !important;
    }

    .justify-content-lg-between {
        justify-content: space-between !important;
    }

    .justify-content-lg-around {
        justify-content: space-around !important;
    }

    .align-items-lg-start {
        align-items: flex-start !important;
    }

    .align-items-lg-end {
        align-items: flex-end !important;
    }

    .align-items-lg-center {
        align-items: center !important;
    }

    .align-items-lg-baseline {
        align-items: baseline !important;
    }

    .align-items-lg-stretch {
        align-items: stretch !important;
    }

    .align-content-lg-start {
        align-content: flex-start !important;
    }

    .align-content-lg-end {
        align-content: flex-end !important;
    }

    .align-content-lg-center {
        align-content: center !important;
    }

    .align-content-lg-between {
        align-content: space-between !important;
    }

    .align-content-lg-around {
        align-content: space-around !important;
    }

    .align-content-lg-stretch {
        align-content: stretch !important;
    }

    .align-self-lg-auto {
        align-self: auto !important;
    }

    .align-self-lg-start {
        align-self: flex-start !important;
    }

    .align-self-lg-end {
        align-self: flex-end !important;
    }

    .align-self-lg-center {
        align-self: center !important;
    }

    .align-self-lg-baseline {
        align-self: baseline !important;
    }

    .align-self-lg-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        flex-direction: row !important;
    }

    .flex-xl-column {
        flex-direction: column !important;
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-xl-fill {
        flex: 1 1 auto !important;
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-xl-start {
        justify-content: flex-start !important;
    }

    .justify-content-xl-end {
        justify-content: flex-end !important;
    }

    .justify-content-xl-center {
        justify-content: center !important;
    }

    .justify-content-xl-between {
        justify-content: space-between !important;
    }

    .justify-content-xl-around {
        justify-content: space-around !important;
    }

    .align-items-xl-start {
        align-items: flex-start !important;
    }

    .align-items-xl-end {
        align-items: flex-end !important;
    }

    .align-items-xl-center {
        align-items: center !important;
    }

    .align-items-xl-baseline {
        align-items: baseline !important;
    }

    .align-items-xl-stretch {
        align-items: stretch !important;
    }

    .align-content-xl-start {
        align-content: flex-start !important;
    }

    .align-content-xl-end {
        align-content: flex-end !important;
    }

    .align-content-xl-center {
        align-content: center !important;
    }

    .align-content-xl-between {
        align-content: space-between !important;
    }

    .align-content-xl-around {
        align-content: space-around !important;
    }

    .align-content-xl-stretch {
        align-content: stretch !important;
    }

    .align-self-xl-auto {
        align-self: auto !important;
    }

    .align-self-xl-start {
        align-self: flex-start !important;
    }

    .align-self-xl-end {
        align-self: flex-end !important;
    }

    .align-self-xl-center {
        align-self: center !important;
    }

    .align-self-xl-baseline {
        align-self: baseline !important;
    }

    .align-self-xl-stretch {
        align-self: stretch !important;
    }
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-none {
    float: none !important;
}

@media (min-width: 576px) {
    .float-sm-left {
        float: left !important;
    }

    .float-sm-right {
        float: right !important;
    }

    .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: left !important;
    }

    .float-md-right {
        float: right !important;
    }

    .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: left !important;
    }

    .float-lg-right {
        float: right !important;
    }

    .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important;
    }

    .float-xl-right {
        float: right !important;
    }

    .float-xl-none {
        float: none !important;
    }
}

.user-select-all {
    user-select: all !important;
}

.user-select-auto {
    user-select: auto !important;
}

.user-select-none {
    user-select: none !important;
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: sticky !important;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@supports (position: sticky) {
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
    box-shadow: none !important;
}

.w-25 {
    width: 25% !important;
}

.w-33 {
    width: 33% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.vw-100 {
    width: 100vw !important;
}

.vh-100 {
    height: 100vh !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item i,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.rtl .preview-list .preview-item .preview-thumbnail .preview-icon i,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.template-demo > .btn-group,
.template-demo > .btn-group-vertical,
.template-demo .circle-progress,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.template-demo > .btn,
.ajax-upload-dragdrop .template-demo > .ajax-file-upload,
.template-demo > .btn-group,
.template-demo > .btn-group-vertical,
.template-demo > .dropdown,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.btn-toolbar .btn-group + .btn-group,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.template-demo > .btn,
.ajax-upload-dragdrop .template-demo > .ajax-file-upload,
.template-demo > .btn-toolbar,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.template-demo > .btn-toolbar,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.template-demo .circle-progress-block,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2,
.template-demo .circle-progress-block {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2,
.template-demo .circle-progress-block {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.m-n1 {
    margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
    margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
    margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
    margin-left: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
    margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
    margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
    margin-left: -0.5rem !important;
}

.m-n3 {
    margin: -1rem !important;
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important;
}

.m-n4 {
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important;
}

.m-n5 {
    margin: -3rem !important;
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }

    .m-sm-1 {
        margin: 0.25rem !important;
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }

    .m-sm-2 {
        margin: 0.5rem !important;
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }

    .m-sm-3 {
        margin: 1rem !important;
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important;
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important;
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
    }

    .m-sm-4 {
        margin: 1.5rem !important;
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important;
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
    }

    .m-sm-5 {
        margin: 3rem !important;
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important;
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important;
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
    }

    .p-sm-0 {
        padding: 0 !important;
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }

    .p-sm-1 {
        padding: 0.25rem !important;
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }

    .p-sm-2 {
        padding: 0.5rem !important;
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }

    .p-sm-3 {
        padding: 1rem !important;
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important;
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important;
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
    }

    .p-sm-4 {
        padding: 1.5rem !important;
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important;
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
    }

    .p-sm-5 {
        padding: 3rem !important;
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important;
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important;
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
    }

    .m-sm-n1 {
        margin: -0.25rem !important;
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -0.25rem !important;
    }

    .m-sm-n2 {
        margin: -0.5rem !important;
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -0.5rem !important;
    }

    .m-sm-n3 {
        margin: -1rem !important;
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important;
    }

    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important;
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important;
    }

    .m-sm-n4 {
        margin: -1.5rem !important;
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important;
    }

    .m-sm-n5 {
        margin: -3rem !important;
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important;
    }

    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important;
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important;
    }

    .m-sm-auto {
        margin: auto !important;
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }

    .m-md-1 {
        margin: 0.25rem !important;
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }

    .m-md-2 {
        margin: 0.5rem !important;
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }

    .m-md-3 {
        margin: 1rem !important;
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important;
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
    }

    .m-md-4 {
        margin: 1.5rem !important;
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important;
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }

    .m-md-5 {
        margin: 3rem !important;
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important;
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important;
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
    }

    .p-md-0 {
        padding: 0 !important;
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }

    .p-md-1 {
        padding: 0.25rem !important;
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }

    .p-md-2 {
        padding: 0.5rem !important;
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }

    .p-md-3 {
        padding: 1rem !important;
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important;
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important;
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
    }

    .p-md-4 {
        padding: 1.5rem !important;
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important;
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
    }

    .p-md-5 {
        padding: 3rem !important;
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important;
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important;
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }

    .m-md-n1 {
        margin: -0.25rem !important;
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -0.25rem !important;
    }

    .m-md-n2 {
        margin: -0.5rem !important;
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -0.5rem !important;
    }

    .m-md-n3 {
        margin: -1rem !important;
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important;
    }

    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important;
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important;
    }

    .m-md-n4 {
        margin: -1.5rem !important;
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important;
    }

    .m-md-n5 {
        margin: -3rem !important;
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important;
    }

    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important;
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important;
    }

    .m-md-auto {
        margin: auto !important;
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }

    .m-lg-1 {
        margin: 0.25rem !important;
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }

    .m-lg-2 {
        margin: 0.5rem !important;
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }

    .m-lg-3 {
        margin: 1rem !important;
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important;
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important;
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
    }

    .m-lg-4 {
        margin: 1.5rem !important;
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important;
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
    }

    .m-lg-5 {
        margin: 3rem !important;
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important;
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }

    .p-lg-0 {
        padding: 0 !important;
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }

    .p-lg-1 {
        padding: 0.25rem !important;
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }

    .p-lg-2 {
        padding: 0.5rem !important;
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }

    .p-lg-3 {
        padding: 1rem !important;
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important;
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important;
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important;
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
    }

    .p-lg-4 {
        padding: 1.5rem !important;
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important;
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
    }

    .p-lg-5 {
        padding: 3rem !important;
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important;
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
    }

    .m-lg-n1 {
        margin: -0.25rem !important;
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -0.25rem !important;
    }

    .m-lg-n2 {
        margin: -0.5rem !important;
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -0.5rem !important;
    }

    .m-lg-n3 {
        margin: -1rem !important;
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important;
    }

    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important;
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important;
    }

    .m-lg-n4 {
        margin: -1.5rem !important;
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important;
    }

    .m-lg-n5 {
        margin: -3rem !important;
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important;
    }

    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important;
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important;
    }

    .m-lg-auto {
        margin: auto !important;
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }

    .m-xl-1 {
        margin: 0.25rem !important;
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }

    .m-xl-2 {
        margin: 0.5rem !important;
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }

    .m-xl-3 {
        margin: 1rem !important;
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important;
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important;
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
    }

    .m-xl-4 {
        margin: 1.5rem !important;
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important;
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
    }

    .m-xl-5 {
        margin: 3rem !important;
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important;
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important;
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
    }

    .p-xl-0 {
        padding: 0 !important;
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }

    .p-xl-1 {
        padding: 0.25rem !important;
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }

    .p-xl-2 {
        padding: 0.5rem !important;
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }

    .p-xl-3 {
        padding: 1rem !important;
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important;
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important;
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
    }

    .p-xl-4 {
        padding: 1.5rem !important;
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important;
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
    }

    .p-xl-5 {
        padding: 3rem !important;
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important;
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important;
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important;
    }

    .m-xl-n1 {
        margin: -0.25rem !important;
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -0.25rem !important;
    }

    .m-xl-n2 {
        margin: -0.5rem !important;
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -0.5rem !important;
    }

    .m-xl-n3 {
        margin: -1rem !important;
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important;
    }

    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important;
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important;
    }

    .m-xl-n4 {
        margin: -1.5rem !important;
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important;
    }

    .m-xl-n5 {
        margin: -3rem !important;
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important;
    }

    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important;
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important;
    }

    .m-xl-auto {
        margin: auto !important;
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: '';
    background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
    font-family:
        SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important;
}

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important;
    }

    .text-xl-right {
        text-align: right !important;
    }

    .text-xl-center {
        text-align: center !important;
    }
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-lighter {
    font-weight: lighter !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-bolder {
    font-weight: bolder !important;
}

.font-italic {
    font-style: italic !important;
}

.text-white {
    color: #ffffff !important;
}

.text-primary {
    color: #ec37fc !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #d103e3 !important;
}

.text-secondary {
    color: #e4e6f6 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: #abb1e3 !important;
}

.text-success {
    color: #51c81c !important;
}

a.text-success:hover,
a.text-success:focus {
    color: #368513 !important;
}

.text-info {
    color: #3794fc !important;
}

a.text-info:hover,
a.text-info:focus {
    color: #036de3 !important;
}

.text-warning {
    color: #fcb41d !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: #ca8a03 !important;
}

.text-danger {
    color: #fc381d !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: #ca1b03 !important;
}

.text-light {
    color: #dee5ef !important;
}

a.text-light:hover,
a.text-light:focus {
    color: #aabcd6 !important;
}

.text-dark {
    color: #001737 !important;
}

a.text-dark:hover,
a.text-dark:focus {
    color: black !important;
}

.text-body {
    color: #666666 !important;
}

.text-muted,
.preview-list .preview-item .preview-item-content p .content-category {
    color: #666666 !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-break {
    word-break: break-word !important;
    word-wrap: break-word !important;
}

.text-reset {
    color: inherit !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

@media print {
    *,
    *::before,
    *::after {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    abbr[title]::after {
        content: ' (' attr(title) ')';
    }

    pre {
        white-space: pre-wrap !important;
    }

    pre,
    blockquote {
        border: 1px solid #adb5bd;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    @page {
        size: a3;
    }

    body {
        min-width: 992px !important;
    }

    .container {
        min-width: 992px !important;
    }

    .navbar {
        display: none;
    }

    .badge {
        border: 1px solid #000;
    }

    .table {
        border-collapse: collapse !important;
    }

    .table td,
    .table th {
        background-color: #ffffff !important;
    }

    .table-bordered th,
    .table-bordered td {
        border: 1px solid #dee2e6 !important;
    }

    .table-dark {
        color: inherit;
    }

    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
        border-color: #e4e6f6;
    }

    .table .thead-dark th {
        color: inherit;
        border-color: #e4e6f6;
    }
}

/*-------------------------------------------------------------------*/
/* === Template mixins === */
/* Miscellaneous Mixins */
/* Animation Mixins */
@keyframes dropdownAnimation {
    from {
        opacity: 0;
        transform: translate3d(0, -30px, 0);
    }

    to {
        opacity: 1;
        transform: none;
        transform: translate3d(0, 0px, 0);
    }
}

.dropdownAnimation,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
    animation-name: dropdownAnimation;
    -webkit-animation-duration: 0.25s;
    -moz-animation-duration: 0.25s;
    -ms-animation-duration: 0.25s;
    -o-animation-duration: 0.25s;
    animation-duration: 0.25s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fadeOut {
    animation-name: fadeOut;
}

.infinite-spin {
    animation-name: spin;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInUp {
    animation-name: fadeInUp;
}

/*-------------------------------------------------------------------*/
/* === Core Styles === */
/* Reset Styles */
body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

.form-control,
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--single .select2-search__field,
.typeahead,
.tt-query,
.tt-hint,
.form-control:focus,
.select2-container--default .select2-selection--single:focus,
.select2-container--default .select2-selection--single .select2-search__field:focus,
.typeahead:focus,
.tt-query:focus,
.tt-hint:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: 0;
}

a,
div,
h1,
h2,
h3,
h4,
h5,
p,
span {
    text-shadow: none;
}

[type='button']:focus,
a:active,
a:focus,
a:visited,
button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner,
select::-moz-focus-inner {
    outline: 0;
}

input,
.form-control:focus,
.select2-container--default .select2-selection--single:focus,
.select2-container--default .select2-selection--single .select2-search__field:focus,
.typeahead:focus,
.tt-query:focus,
.tt-hint:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    outline-width: 0;
    outline-color: transparent;
    box-shadow: none;
    outline-style: none;
}

textarea {
    resize: none;
    overflow-x: hidden;
}

.btn,
.ajax-upload-dragdrop .ajax-file-upload,
.btn-group.open .dropdown-toggle,
.btn:active,
.ajax-upload-dragdrop .ajax-file-upload:active,
.btn:focus,
.ajax-upload-dragdrop .ajax-file-upload:focus,
.btn:hover,
.ajax-upload-dragdrop .ajax-file-upload:hover,
.btn:visited,
.ajax-upload-dragdrop .ajax-file-upload:visited,
a,
a:active,
a:checked,
a:focus,
a:hover,
a:visited,
body,
button,
button:active,
button:hover,
button:visited,
div,
input,
input:active,
input:focus,
input:hover,
input:visited,
select,
select:active,
select:focus,
select:visited,
textarea,
textarea:active,
textarea:focus,
textarea:hover,
textarea:visited {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.btn.active.focus,
.ajax-upload-dragdrop .active.focus.ajax-file-upload,
.btn.active:focus,
.ajax-upload-dragdrop .active.ajax-file-upload:focus,
.btn.focus,
.ajax-upload-dragdrop .focus.ajax-file-upload,
.btn:active.focus,
.ajax-upload-dragdrop .ajax-file-upload:active.focus,
.btn:active:focus,
.ajax-upload-dragdrop .ajax-file-upload:active:focus,
.btn:focus,
.ajax-upload-dragdrop .ajax-file-upload:focus,
button,
button:active,
button:checked,
button:focus,
button:hover,
button:visited {
    outline: 0;
    outline-offset: 0;
}

.bootstrap-select .dropdown-toggle:focus {
    outline: 0;
    outline-offset: 0;
}

.dropdown-menu > li > a:active,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:visited {
    outline: 0;
}

a:focus,
input:focus {
    border-color: transparent;
    outline: none;
}

@font-face {
    font-family: 'Karla';
    src: url('../fonts/karla/Karla-Bold.eot');
    src:
        url('../fonts/karla/Karla-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/karla/Karla-Bold.woff2') format('woff2'),
        url('../fonts/karla/Karla-Bold.woff') format('woff'),
        url('../fonts/karla/Karla-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Karla';
    src: url('../fonts/karla/Karla-Regular.eot');
    src:
        url('../fonts/karla/Karla-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/karla/Karla-Regular.woff2') format('woff2'),
        url('../fonts/karla/Karla-Regular.woff') format('woff'),
        url('../fonts/karla/Karla-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.bg-twitter {
    background: #2caae1;
}

.bg-facebook {
    background: #3b579d;
}

.bg-google {
    background: #dc4a38;
}

.bg-linkedin {
    background: #0177b5;
}

.bg-pinterest {
    background: #cc2127;
}

.bg-youtube {
    background: #e52d27;
}

.bg-github {
    background: #333333;
}

.bg-behance {
    background: #1769ff;
}

.bg-dribbble {
    background: #ea4c89;
}

.bg-reddit {
    background: #ff4500;
}

.bg-gradient-primary {
    background: linear-gradient(230deg, #759bff, #843cf6);
}

.bg-gradient-secondary {
    background: linear-gradient(to right, #e7ebf0, #868e96);
}

.bg-gradient-success {
    background: linear-gradient(45deg, #7bffce, #30c93e);
}

.bg-gradient-info {
    background: linear-gradient(to bottom, #0e4cfd, #6a8eff);
}

.bg-gradient-warning {
    background: linear-gradient(135deg, #ffc480, #ff763b);
}

.bg-gradient-danger {
    background: linear-gradient(316deg, #fc5286, #fbaaa2);
}

.bg-gradient-light {
    background: linear-gradient(to right, #cfd9df 0%, #e2ebf0 100%);
}

.bg-gradient-dark {
    background: linear-gradient(to right, #7d7979 0%, #000000 100%);
}

/* Typography */
body {
    font-size: 0.875rem;
    font-family: 'Karla', sans-serif;
    font-weight: initial;
    line-height: normal;
    -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 500;
    line-height: 1;
}

p {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    line-height: 1.3rem;
}

h1,
.h1 {
    font-size: 2.5rem;
}

h2,
.h2 {
    font-size: 2rem;
}

h3,
.h3 {
    font-size: 1.875rem;
}

h4,
.h4 {
    font-size: 1.5rem;
}

h5,
.h5 {
    font-size: 1.25rem;
}

h6,
.h6 {
    font-size: 1rem;
}

.display-1 {
    font-size: 3.75rem;
}

@media (max-width: 991px) {
    .display-1 {
        font-size: 3rem;
    }
}

.display-2 {
    font-size: 3.125rem;
}

@media (max-width: 991px) {
    .display-2 {
        font-size: 2.5rem;
    }
}

.display-3 {
    font-size: 2.5rem;
}

@media (max-width: 991px) {
    .display-3 {
        font-size: 2rem;
    }
}

.display-4 {
    font-size: 1.875rem;
}

@media (max-width: 991px) {
    .display-4 {
        font-size: 1.5rem;
    }
}

.display-5 {
    font-size: 1.25rem;
}

@media (max-width: 991px) {
    .display-5 {
        font-size: 1rem;
    }
}

.blockquote {
    padding: 1.25rem;
    border: 1px solid #e4e6f6;
}

address p {
    margin-bottom: 0;
}

.blockquote-primary {
    border-color: #ec37fc;
}

.blockquote-primary .blockquote-footer {
    color: #ec37fc;
}

.blockquote-secondary {
    border-color: #e4e6f6;
}

.blockquote-secondary .blockquote-footer {
    color: #e4e6f6;
}

.blockquote-success {
    border-color: #51c81c;
}

.blockquote-success .blockquote-footer {
    color: #51c81c;
}

.blockquote-info {
    border-color: #3794fc;
}

.blockquote-info .blockquote-footer {
    color: #3794fc;
}

.blockquote-warning {
    border-color: #fcb41d;
}

.blockquote-warning .blockquote-footer {
    color: #fcb41d;
}

.blockquote-danger {
    border-color: #fc381d;
}

.blockquote-danger .blockquote-footer {
    color: #fc381d;
}

.blockquote-light {
    border-color: #dee5ef;
}

.blockquote-light .blockquote-footer {
    color: #dee5ef;
}

.blockquote-dark {
    border-color: #001737;
}

.blockquote-dark .blockquote-footer {
    color: #001737;
}

.error-page h1 {
    font-size: 12rem;
}

@media (max-width: 991px) {
    .error-page h1 {
        font-size: 8rem;
    }
}

.text-twitter {
    color: #2caae1;
}

.text-facebook {
    color: #3b579d;
}

.text-google {
    color: #dc4a38;
}

.text-linkedin {
    color: #0177b5;
}

.text-pinterest {
    color: #cc2127;
}

.text-youtube {
    color: #e52d27;
}

.text-github {
    color: #333333;
}

.text-behance {
    color: #1769ff;
}

.text-dribbble {
    color: #ea4c89;
}

.text-reddit {
    color: #ff4500;
}

.page-header {
    margin-bottom: 2.5rem;
}

.page-header .page-title {
    margin-bottom: 0;
    line-height: 0.85;
}

.font-weight-medium {
    font-weight: 500;
}

/* Miscellanoeous */
body,
html {
    padding-right: 0 !important;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
    overflow: auto;
}

.container-scroller {
    overflow: hidden;
}

pre {
    background: #e8eff4;
    padding: 15px;
    font-size: 14px;
}

code {
    padding: 5px;
    color: #fc381d;
    font-family: 'Karla', sans-serif;
    font-weight: 300;
    font-size: 0.875rem;
    border-radius: 4px;
}

/* Footer */
.footer {
    background: #fff;
    padding: 1.5rem 2.5rem;
    transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    font-size: 0.875rem;
    font-family: 'Karla', sans-serif;
    font-weight: 400;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.footer a {
    color: #ec37fc;
    font-size: inherit;
}

@media (max-width: 991px) {
    .footer {
        margin-left: 0;
        width: 100%;
    }
}

/* Utilities */
.grid-margin {
    margin-bottom: 1rem;
}

@media (min-width: 576px) {
    .grid-margin-sm-0 {
        margin-bottom: 0;
    }
}

@media (min-width: 768px) {
    .grid-margin-md-0 {
        margin-bottom: 0;
    }
}

@media (min-width: 992px) {
    .grid-margin-lg-0 {
        margin-bottom: 0;
    }
}

@media (min-width: 1200px) {
    .grid-margin-xl-0 {
        margin-bottom: 0;
    }
}

.img-lg {
    width: 92px;
    height: 92px;
}

.img-sm {
    width: 43px;
    height: 43px;
}

.img-xs {
    width: 37px;
    height: 37px;
}

.img-ss {
    width: 26px;
    height: 26px;
}

.stretch-card {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: stretch;
    align-items: stretch;
    -webkit-justify-content: stretch;
    justify-content: stretch;
}

.stretch-card > .card {
    width: 100%;
    min-width: 100%;
}

@media (min-width: 576px) {
    .border-right-sm {
        border-right: 1px solid #e4e6f6;
    }
}

@media (min-width: 768px) {
    .border-right-md {
        border-right: 1px solid #e4e6f6;
    }
}

@media (min-width: 992px) {
    .border-right-lg {
        border-right: 1px solid #e4e6f6;
    }
}

@media (min-width: 576px) {
    .border-left-sm {
        border-left: 1px solid #e4e6f6;
    }
}

@media (min-width: 768px) {
    .border-left-md {
        border-left: 1px solid #e4e6f6;
    }
}

@media (min-width: 992px) {
    .border-left-lg {
        border-left: 1px solid #e4e6f6;
    }
}

.text-gray,
.card .card-subtitle {
    color: #8c8c8c;
}

.text-black {
    color: #000;
}

.text-small {
    font-size: 12px;
}

.flex-grow {
    flex-grow: 1;
}

/* Demo Styles */
.template-demo > h2,
.template-demo > h3,
.template-demo > h4,
.template-demo > h5,
.template-demo > h6,
.template-demo > h1 {
    border-top: 1px solid #e4e6f6;
    padding: 0.5rem 0 0;
}

.template-demo > .dropdown {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.template-demo nav:last-child .breadcrumb {
    margin-bottom: 0;
}

.template-demo .circle-progress {
    padding: 15px;
}

.documentation {
    padding-top: 0;
    max-width: 100%;
}

.documentation .content-wrapper {
    padding-top: 0;
    min-height: calc(100vh - 75px);
}

.doc-table-contents,
.doc-header {
    position: fixed;
}

.doc-header {
    padding-top: 24px;
    padding-bottom: 24px;
    z-index: 2;
    background: #f4f7fa;
    z-index: 999;
}

@media screen and (max-width: 991px) {
    .doc-header h1 {
        font-size: 2rem;
    }
}

.doc-content {
    padding-top: 157px;
}

.doc-table-contents {
    overflow: auto;
    max-height: calc(100% - 157px);
}

.doc-table-contents ul li {
    margin-top: 10px;
}

.chart-legends .legend-label {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    display: block;
    margin-right: 5px;
}

.traffic-source-legend .legend-label {
    padding: 7px 0;
}

.traffic-source-legend .legend-label span {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    display: inline-block;
    margin-right: 0.5rem;
}

/*-------------------------------------------------------------------*/
/* === Components === */
/* Bootstrap Progress */
.progress {
    border-radius: 6px;
    height: 8px;
}

.progress .progress-bar {
    border-radius: 2px;
}

.progress.progress-sm {
    height: 0.375rem;
}

.progress.progress-md {
    height: 10px;
}

.progress.progress-lg {
    height: 12px;
}

.progress.progress-xl {
    height: 18px;
}

.progress.grouped {
    border-radius: 3px;
}

.progress.grouped .progress-bar {
    border-radius: 0;
}

/* Buttons */
.btn,
.ajax-upload-dragdrop .ajax-file-upload {
    font-size: 0.875rem;
    line-height: 1;
    font-weight: 400;
    border-radius: 4px;
    /* Buttons with only icons */
    /* Buttons with icon and text */
}

.btn i,
.ajax-upload-dragdrop .ajax-file-upload i {
    font-size: 1rem;
}

.btn.btn-rounded,
.ajax-upload-dragdrop .btn-rounded.ajax-file-upload {
    border-radius: 50px;
}

.btn.btn-sm,
.btn-group-sm > .btn,
.ajax-upload-dragdrop .btn-group-sm > .ajax-file-upload,
.ajax-upload-dragdrop .btn-sm.ajax-file-upload {
    font-size: 0.875rem;
}

.btn.btn-lg,
.btn-group-lg > .btn,
.ajax-upload-dragdrop .btn-group-lg > .ajax-file-upload,
.ajax-upload-dragdrop .btn-lg.ajax-file-upload {
    font-size: 0.875rem;
}

.btn.btn-xs,
.ajax-upload-dragdrop .btn-xs.ajax-file-upload {
    padding: 0.5rem 0.75rem;
    font-size: 0.625rem;
}

.btn.btn-icon,
.ajax-upload-dragdrop .btn-icon.ajax-file-upload {
    width: 42px;
    height: 42px;
    padding: 0;
}

.btn.btn-icon-text .btn-icon-prepend,
.ajax-upload-dragdrop .btn-icon-text.ajax-file-upload .btn-icon-prepend {
    margin-right: 0.5rem;
}

.btn.btn-icon-text .btn-icon-append,
.ajax-upload-dragdrop .btn-icon-text.ajax-file-upload .btn-icon-append {
    margin-left: 0.5rem;
}

.btn.btn-social-icon,
.ajax-upload-dragdrop .btn-social-icon.ajax-file-upload {
    width: 50px;
    height: 50px;
    padding: 0;
}

.btn-group .btn + .btn,
.btn-group .ajax-upload-dragdrop .ajax-file-upload + .btn,
.ajax-upload-dragdrop .btn-group .ajax-file-upload + .btn,
.btn-group .ajax-upload-dragdrop .btn + .ajax-file-upload,
.ajax-upload-dragdrop .btn-group .btn + .ajax-file-upload,
.btn-group .ajax-upload-dragdrop .ajax-file-upload + .ajax-file-upload,
.ajax-upload-dragdrop .btn-group .ajax-file-upload + .ajax-file-upload {
    border-left: 0;
}

/*social buttons*/
.btn-twitter {
    background: #2caae1;
    color: #ffffff;
}

.btn-twitter:hover,
.btn-twitter:focus {
    background: #1b8dbf;
    color: #ffffff;
}

.btn-twitter.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #59bce7;
}

.btn-twitter.btn-social-icon-text i {
    background: #2caae1;
    padding: 0.75rem;
    display: inline-block;
    margin-right: 1.5rem;
}

.btn-outline-twitter {
    border: 1px solid #2caae1;
    color: #2caae1;
}

.btn-outline-twitter:hover {
    background: #2caae1;
    color: #ffffff;
}

.btn-facebook {
    background: #3b579d;
    color: #ffffff;
}

.btn-facebook:hover,
.btn-facebook:focus {
    background: #2d4278;
    color: #ffffff;
}

.btn-facebook.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #4e6ebd;
}

.btn-facebook.btn-social-icon-text i {
    background: #3b579d;
    padding: 0.75rem;
    display: inline-block;
    margin-right: 1.5rem;
}

.btn-outline-facebook {
    border: 1px solid #3b579d;
    color: #3b579d;
}

.btn-outline-facebook:hover {
    background: #3b579d;
    color: #ffffff;
}

.btn-google {
    background: #dc4a38;
    color: #ffffff;
}

.btn-google:hover,
.btn-google:focus {
    background: #bf3322;
    color: #ffffff;
}

.btn-google.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #e47163;
}

.btn-google.btn-social-icon-text i {
    background: #dc4a38;
    padding: 0.75rem;
    display: inline-block;
    margin-right: 1.5rem;
}

.btn-outline-google {
    border: 1px solid #dc4a38;
    color: #dc4a38;
}

.btn-outline-google:hover {
    background: #dc4a38;
    color: #ffffff;
}

.btn-linkedin {
    background: #0177b5;
    color: #ffffff;
}

.btn-linkedin:hover,
.btn-linkedin:focus {
    background: #015682;
    color: #ffffff;
}

.btn-linkedin.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #0198e8;
}

.btn-linkedin.btn-social-icon-text i {
    background: #0177b5;
    padding: 0.75rem;
    display: inline-block;
    margin-right: 1.5rem;
}

.btn-outline-linkedin {
    border: 1px solid #0177b5;
    color: #0177b5;
}

.btn-outline-linkedin:hover {
    background: #0177b5;
    color: #ffffff;
}

.btn-pinterest {
    background: #cc2127;
    color: #ffffff;
}

.btn-pinterest:hover,
.btn-pinterest:focus {
    background: #a01a1f;
    color: #ffffff;
}

.btn-pinterest.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #e04046;
}

.btn-pinterest.btn-social-icon-text i {
    background: #cc2127;
    padding: 0.75rem;
    display: inline-block;
    margin-right: 1.5rem;
}

.btn-outline-pinterest {
    border: 1px solid #cc2127;
    color: #cc2127;
}

.btn-outline-pinterest:hover {
    background: #cc2127;
    color: #ffffff;
}

.btn-youtube {
    background: #e52d27;
    color: #ffffff;
}

.btn-youtube:hover,
.btn-youtube:focus {
    background: #c21d17;
    color: #ffffff;
}

.btn-youtube.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #ea5955;
}

.btn-youtube.btn-social-icon-text i {
    background: #e52d27;
    padding: 0.75rem;
    display: inline-block;
    margin-right: 1.5rem;
}

.btn-outline-youtube {
    border: 1px solid #e52d27;
    color: #e52d27;
}

.btn-outline-youtube:hover {
    background: #e52d27;
    color: #ffffff;
}

.btn-github {
    background: #333333;
    color: #ffffff;
}

.btn-github:hover,
.btn-github:focus {
    background: #1a1a1a;
    color: #ffffff;
}

.btn-github.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #4d4d4d;
}

.btn-github.btn-social-icon-text i {
    background: #333333;
    padding: 0.75rem;
    display: inline-block;
    margin-right: 1.5rem;
}

.btn-outline-github {
    border: 1px solid #333333;
    color: #333333;
}

.btn-outline-github:hover {
    background: #333333;
    color: #ffffff;
}

.btn-behance {
    background: #1769ff;
    color: #ffffff;
}

.btn-behance:hover,
.btn-behance:focus {
    background: #0050e3;
    color: #ffffff;
}

.btn-behance.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #4a8aff;
}

.btn-behance.btn-social-icon-text i {
    background: #1769ff;
    padding: 0.75rem;
    display: inline-block;
    margin-right: 1.5rem;
}

.btn-outline-behance {
    border: 1px solid #1769ff;
    color: #1769ff;
}

.btn-outline-behance:hover {
    background: #1769ff;
    color: #ffffff;
}

.btn-dribbble {
    background: #ea4c89;
    color: #ffffff;
}

.btn-dribbble:hover,
.btn-dribbble:focus {
    background: #e51e6b;
    color: #ffffff;
}

.btn-dribbble.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #ef7aa7;
}

.btn-dribbble.btn-social-icon-text i {
    background: #ea4c89;
    padding: 0.75rem;
    display: inline-block;
    margin-right: 1.5rem;
}

.btn-outline-dribbble {
    border: 1px solid #ea4c89;
    color: #ea4c89;
}

.btn-outline-dribbble:hover {
    background: #ea4c89;
    color: #ffffff;
}

.btn-reddit {
    background: #ff4500;
    color: #ffffff;
}

.btn-reddit:hover,
.btn-reddit:focus {
    background: #cc3700;
    color: #ffffff;
}

.btn-reddit.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: #ff6a33;
}

.btn-reddit.btn-social-icon-text i {
    background: #ff4500;
    padding: 0.75rem;
    display: inline-block;
    margin-right: 1.5rem;
}

.btn-outline-reddit {
    border: 1px solid #ff4500;
    color: #ff4500;
}

.btn-outline-reddit:hover {
    background: #ff4500;
    color: #ffffff;
}

/* inverse buttons */
.btn-inverse-primary {
    background-color: rgba(236, 55, 252, 0.2);
    background-image: none;
    border-color: rgba(236, 55, 252, 0);
}

.btn-inverse-primary:not(.btn-inverse-light) {
    color: #8c0298;
}

.btn-inverse-primary:hover {
    color: #ffffff;
    background-color: #ec37fc;
    border-color: #ec37fc;
}

.btn-inverse-primary.focus,
.btn-inverse-primary:focus {
    box-shadow: 0 0 0 3px rgba(236, 55, 252, 0.5);
}

.btn-inverse-primary.disabled,
.btn-inverse-primary:disabled {
    color: #ec37fc;
    background-color: transparent;
}

.btn-inverse-primary.active,
.btn-inverse-primary:active,
.show > .btn-inverse-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #ec37fc;
    border-color: #ec37fc;
}

.btn-primary,
.btn-primary:hover {
    box-shadow:
        0 2px 2px 0 rgba(236, 55, 252, 0.14),
        0 3px 1px -2px rgba(236, 55, 252, 0.2),
        0 1px 5px 0 rgba(236, 55, 252, 0.12);
}

.btn-inverse-secondary {
    background-color: rgba(228, 230, 246, 0.2);
    background-image: none;
    border-color: rgba(228, 230, 246, 0);
}

.btn-inverse-secondary:not(.btn-inverse-light) {
    color: #717cd0;
}

.btn-inverse-secondary:hover {
    color: #ffffff;
    background-color: #e4e6f6;
    border-color: #e4e6f6;
}

.btn-inverse-secondary.focus,
.btn-inverse-secondary:focus {
    box-shadow: 0 0 0 3px rgba(228, 230, 246, 0.5);
}

.btn-inverse-secondary.disabled,
.btn-inverse-secondary:disabled {
    color: #e4e6f6;
    background-color: transparent;
}

.btn-inverse-secondary.active,
.btn-inverse-secondary:active,
.show > .btn-inverse-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #e4e6f6;
    border-color: #e4e6f6;
}

.btn-secondary,
.btn-secondary:hover {
    box-shadow:
        0 2px 2px 0 rgba(228, 230, 246, 0.14),
        0 3px 1px -2px rgba(228, 230, 246, 0.2),
        0 1px 5px 0 rgba(228, 230, 246, 0.12);
}

.btn-inverse-success {
    background-color: rgba(81, 200, 28, 0.2);
    background-image: none;
    border-color: rgba(81, 200, 28, 0);
}

.btn-inverse-success:not(.btn-inverse-light) {
    color: #1b4209;
}

.btn-inverse-success:hover {
    color: #ffffff;
    background-color: #51c81c;
    border-color: #51c81c;
}

.btn-inverse-success.focus,
.btn-inverse-success:focus {
    box-shadow: 0 0 0 3px rgba(81, 200, 28, 0.5);
}

.btn-inverse-success.disabled,
.btn-inverse-success:disabled {
    color: #51c81c;
    background-color: transparent;
}

.btn-inverse-success.active,
.btn-inverse-success:active,
.show > .btn-inverse-success.dropdown-toggle {
    color: #ffffff;
    background-color: #51c81c;
    border-color: #51c81c;
}

.btn-success,
.btn-success:hover {
    box-shadow:
        0 2px 2px 0 rgba(81, 200, 28, 0.14),
        0 3px 1px -2px rgba(81, 200, 28, 0.2),
        0 1px 5px 0 rgba(81, 200, 28, 0.12);
}

.btn-inverse-info {
    background-color: rgba(55, 148, 252, 0.2);
    background-image: none;
    border-color: rgba(55, 148, 252, 0);
}

.btn-inverse-info:not(.btn-inverse-light) {
    color: #024998;
}

.btn-inverse-info:hover {
    color: #ffffff;
    background-color: #3794fc;
    border-color: #3794fc;
}

.btn-inverse-info.focus,
.btn-inverse-info:focus {
    box-shadow: 0 0 0 3px rgba(55, 148, 252, 0.5);
}

.btn-inverse-info.disabled,
.btn-inverse-info:disabled {
    color: #3794fc;
    background-color: transparent;
}

.btn-inverse-info.active,
.btn-inverse-info:active,
.show > .btn-inverse-info.dropdown-toggle {
    color: #ffffff;
    background-color: #3794fc;
    border-color: #3794fc;
}

.btn-info,
.btn-info:hover {
    box-shadow:
        0 2px 2px 0 rgba(55, 148, 252, 0.14),
        0 3px 1px -2px rgba(55, 148, 252, 0.2),
        0 1px 5px 0 rgba(55, 148, 252, 0.12);
}

.btn-inverse-warning {
    background-color: rgba(252, 180, 29, 0.2);
    background-image: none;
    border-color: rgba(252, 180, 29, 0);
}

.btn-inverse-warning:not(.btn-inverse-light) {
    color: #7e5602;
}

.btn-inverse-warning:hover {
    color: #ffffff;
    background-color: #fcb41d;
    border-color: #fcb41d;
}

.btn-inverse-warning.focus,
.btn-inverse-warning:focus {
    box-shadow: 0 0 0 3px rgba(252, 180, 29, 0.5);
}

.btn-inverse-warning.disabled,
.btn-inverse-warning:disabled {
    color: #fcb41d;
    background-color: transparent;
}

.btn-inverse-warning.active,
.btn-inverse-warning:active,
.show > .btn-inverse-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #fcb41d;
    border-color: #fcb41d;
}

.btn-warning,
.btn-warning:hover {
    box-shadow:
        0 2px 2px 0 rgba(252, 180, 29, 0.14),
        0 3px 1px -2px rgba(252, 180, 29, 0.2),
        0 1px 5px 0 rgba(252, 180, 29, 0.12);
}

.btn-inverse-danger {
    background-color: rgba(252, 56, 29, 0.2);
    background-image: none;
    border-color: rgba(252, 56, 29, 0);
}

.btn-inverse-danger:not(.btn-inverse-light) {
    color: #7e1102;
}

.btn-inverse-danger:hover {
    color: #ffffff;
    background-color: #fc381d;
    border-color: #fc381d;
}

.btn-inverse-danger.focus,
.btn-inverse-danger:focus {
    box-shadow: 0 0 0 3px rgba(252, 56, 29, 0.5);
}

.btn-inverse-danger.disabled,
.btn-inverse-danger:disabled {
    color: #fc381d;
    background-color: transparent;
}

.btn-inverse-danger.active,
.btn-inverse-danger:active,
.show > .btn-inverse-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #fc381d;
    border-color: #fc381d;
}

.btn-danger,
.btn-danger:hover {
    box-shadow:
        0 2px 2px 0 rgba(252, 56, 29, 0.14),
        0 3px 1px -2px rgba(252, 56, 29, 0.2),
        0 1px 5px 0 rgba(252, 56, 29, 0.12);
}

.btn-inverse-light {
    background-color: rgba(222, 229, 239, 0.2);
    background-image: none;
    border-color: rgba(222, 229, 239, 0);
}

.btn-inverse-light:not(.btn-inverse-light) {
    color: #7794bd;
}

.btn-inverse-light:hover {
    color: #ffffff;
    background-color: #dee5ef;
    border-color: #dee5ef;
}

.btn-inverse-light.focus,
.btn-inverse-light:focus {
    box-shadow: 0 0 0 3px rgba(222, 229, 239, 0.5);
}

.btn-inverse-light.disabled,
.btn-inverse-light:disabled {
    color: #dee5ef;
    background-color: transparent;
}

.btn-inverse-light.active,
.btn-inverse-light:active,
.show > .btn-inverse-light.dropdown-toggle {
    color: #ffffff;
    background-color: #dee5ef;
    border-color: #dee5ef;
}

.btn-light,
.btn-light:hover {
    box-shadow:
        0 2px 2px 0 rgba(222, 229, 239, 0.14),
        0 3px 1px -2px rgba(222, 229, 239, 0.2),
        0 1px 5px 0 rgba(222, 229, 239, 0.12);
}

.btn-inverse-dark {
    background-color: rgba(0, 23, 55, 0.2);
    background-image: none;
    border-color: rgba(0, 23, 55, 0);
}

.btn-inverse-dark:not(.btn-inverse-light) {
    color: black;
}

.btn-inverse-dark:hover {
    color: #ffffff;
    background-color: #001737;
    border-color: #001737;
}

.btn-inverse-dark.focus,
.btn-inverse-dark:focus {
    box-shadow: 0 0 0 3px rgba(0, 23, 55, 0.5);
}

.btn-inverse-dark.disabled,
.btn-inverse-dark:disabled {
    color: #001737;
    background-color: transparent;
}

.btn-inverse-dark.active,
.btn-inverse-dark:active,
.show > .btn-inverse-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #001737;
    border-color: #001737;
}

.btn-dark,
.btn-dark:hover {
    box-shadow:
        0 2px 2px 0 rgba(0, 23, 55, 0.14),
        0 3px 1px -2px rgba(0, 23, 55, 0.2),
        0 1px 5px 0 rgba(0, 23, 55, 0.12);
}

/* outlinw buttons */
.btn-outline-primary {
    color: #ec37fc;
    border-color: #ec37fc;
}

.btn-outline-primary:not(.btn-outline-light) {
    color: #8c0298;
}

.btn-outline-primary:not(.btn-outline-secondary) {
    color: #8c0298;
}

.btn-primary,
.btn-primary:hover {
    box-shadow:
        0 2px 2px 0 rgba(236, 55, 252, 0.14),
        0 3px 1px -2px rgba(236, 55, 252, 0.2),
        0 1px 5px 0 rgba(236, 55, 252, 0.12);
}

.btn-outline-secondary {
    color: #e4e6f6;
    border-color: #e4e6f6;
}

.btn-outline-secondary:not(.btn-outline-light) {
    color: #717cd0;
}

.btn-outline-secondary:not(.btn-outline-secondary) {
    color: #717cd0;
}

.btn-secondary,
.btn-secondary:hover {
    box-shadow:
        0 2px 2px 0 rgba(228, 230, 246, 0.14),
        0 3px 1px -2px rgba(228, 230, 246, 0.2),
        0 1px 5px 0 rgba(228, 230, 246, 0.12);
}

.btn-outline-success {
    color: #51c81c;
    border-color: #51c81c;
}

.btn-outline-success:not(.btn-outline-light) {
    color: #1b4209;
}

.btn-outline-success:not(.btn-outline-secondary) {
    color: #1b4209;
}

.btn-success,
.btn-success:hover {
    box-shadow:
        0 2px 2px 0 rgba(81, 200, 28, 0.14),
        0 3px 1px -2px rgba(81, 200, 28, 0.2),
        0 1px 5px 0 rgba(81, 200, 28, 0.12);
}

.btn-outline-info {
    color: #3794fc;
    border-color: #3794fc;
}

.btn-outline-info:not(.btn-outline-light) {
    color: #024998;
}

.btn-outline-info:not(.btn-outline-secondary) {
    color: #024998;
}

.btn-info,
.btn-info:hover {
    box-shadow:
        0 2px 2px 0 rgba(55, 148, 252, 0.14),
        0 3px 1px -2px rgba(55, 148, 252, 0.2),
        0 1px 5px 0 rgba(55, 148, 252, 0.12);
}

.btn-outline-warning {
    color: #fcb41d;
    border-color: #fcb41d;
}

.btn-outline-warning:not(.btn-outline-light) {
    color: #7e5602;
}

.btn-outline-warning:not(.btn-outline-secondary) {
    color: #7e5602;
}

.btn-warning,
.btn-warning:hover {
    box-shadow:
        0 2px 2px 0 rgba(252, 180, 29, 0.14),
        0 3px 1px -2px rgba(252, 180, 29, 0.2),
        0 1px 5px 0 rgba(252, 180, 29, 0.12);
}

.btn-outline-danger {
    color: #fc381d;
    border-color: #fc381d;
}

.btn-outline-danger:not(.btn-outline-light) {
    color: #7e1102;
}

.btn-outline-danger:not(.btn-outline-secondary) {
    color: #7e1102;
}

.btn-danger,
.btn-danger:hover {
    box-shadow:
        0 2px 2px 0 rgba(252, 56, 29, 0.14),
        0 3px 1px -2px rgba(252, 56, 29, 0.2),
        0 1px 5px 0 rgba(252, 56, 29, 0.12);
}

.btn-outline-light {
    color: #dee5ef;
    border-color: #dee5ef;
}

.btn-outline-light:not(.btn-outline-light) {
    color: #7794bd;
}

.btn-outline-light:not(.btn-outline-secondary) {
    color: #7794bd;
}

.btn-light,
.btn-light:hover {
    box-shadow:
        0 2px 2px 0 rgba(222, 229, 239, 0.14),
        0 3px 1px -2px rgba(222, 229, 239, 0.2),
        0 1px 5px 0 rgba(222, 229, 239, 0.12);
}

.btn-outline-dark {
    color: #001737;
    border-color: #001737;
}

.btn-outline-dark:not(.btn-outline-light) {
    color: black;
}

.btn-outline-dark:not(.btn-outline-secondary) {
    color: black;
}

.btn-dark,
.btn-dark:hover {
    box-shadow:
        0 2px 2px 0 rgba(0, 23, 55, 0.14),
        0 3px 1px -2px rgba(0, 23, 55, 0.2),
        0 1px 5px 0 rgba(0, 23, 55, 0.12);
}

/* Cards */
.card {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
}

.card .card-body {
    padding: 1.875rem 1.875rem;
}

.card .card-body + .card-body {
    padding-top: 1rem;
}

.card .card-title {
    color: #001737;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
    font-size: 16px !important;
    font-weight: bold;
}

.card .card-subtitle {
    font-weight: 400;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
}

.card .card-description {
    margin-bottom: 0.875rem;
    font-weight: 400;
    color: #76838f;
}

.card.card-outline-success {
    border: 1px solid #51c81c;
}

.card.card-outline-primary {
    border: 1px solid #ec37fc;
}

.card.card-outline-warning {
    border: 1px solid #fcb41d;
}

.card.card-outline-danger {
    border: 1px solid #fc381d;
}

.card.card-rounded {
    border-radius: 5px;
}

.card.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2;
}

.card.card-circle-progress {
    color: #ffffff;
    text-align: center;
}

.card.card-img-holder {
    position: relative;
}

.card.card-img-holder .card-img-absolute {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
}

.card.card-weather .weather-daily .weather-day {
    opacity: 0.5;
    font-weight: 900;
}

.card.card-weather .weather-daily i {
    font-size: 20px;
}

.card.card-weather .weather-daily .weather-temp {
    margin-top: 0.5rem;
    margin-bottom: 0;
    opacity: 0.5;
    font-size: 0.75rem;
}

.card-inverse-primary {
    background: rgba(236, 55, 252, 0.2);
    border: 1px solid #d933e8;
    color: #b32ac0;
}

.card-inverse-secondary {
    background: rgba(228, 230, 246, 0.2);
    border: 1px solid #d2d4e2;
    color: #adafbb;
}

.card-inverse-success {
    background: rgba(81, 200, 28, 0.2);
    border: 1px solid #4bb81a;
    color: #3e9815;
}

.card-inverse-info {
    background: rgba(55, 148, 252, 0.2);
    border: 1px solid #3388e8;
    color: #2a70c0;
}

.card-inverse-warning {
    background: rgba(252, 180, 29, 0.2);
    border: 1px solid #e8a61b;
    color: #c08916;
}

.card-inverse-danger {
    background: rgba(252, 56, 29, 0.2);
    border: 1px solid #e8341b;
    color: #c02b16;
}

.card-inverse-light {
    background: rgba(222, 229, 239, 0.2);
    border: 1px solid #ccd3dc;
    color: #a9aeb6;
}

.card-inverse-dark {
    background: rgba(0, 23, 55, 0.2);
    border: 1px solid #001533;
    color: #00112a;
}

/* Checkboxes and Radios */
.form-check {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 0;
}

.form-check .form-check-label {
    min-height: 18px;
    display: block;
    margin-left: 1.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
}

.rtl .form-check .form-check-label {
    margin-left: 0;
    margin-right: 1.75rem;
}

.form-check .form-check-label input {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 0;
    margin-top: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.rtl .form-check .form-check-label input {
    left: auto;
    right: 0;
}

.form-check .form-check-label input[type='checkbox'] + .input-helper:before,
.form-check .form-check-label input[type='checkbox'] + .input-helper:after {
    position: absolute;
    top: 0;
    left: 0;
}

.rtl .form-check .form-check-label input[type='checkbox'] + .input-helper:before,
.rtl .form-check .form-check-label input[type='checkbox'] + .input-helper:after {
    left: auto;
    right: 0;
}

.form-check .form-check-label input[type='checkbox'] + .input-helper:before {
    content: '';
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: solid #ec37fc;
    border-width: 2px;
    -webkit-transition: all;
    -moz-transition: all;
    -ms-transition: all;
    -o-transition: all;
    transition: all;
    transition-duration: 0s;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
}

.form-check .form-check-label input[type='checkbox'] + .input-helper:after {
    -webkit-transition: all;
    -moz-transition: all;
    -ms-transition: all;
    -o-transition: all;
    transition: all;
    transition-duration: 0s;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
    font-family: 'Material Design Icons';
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    content: '\F12C';
    font-size: 0.9375rem;
    font-weight: bold;
    color: #ffffff;
}

.form-check .form-check-label input[type='checkbox']:checked + .input-helper:before {
    background: #ec37fc;
    border-width: 0;
}

.form-check .form-check-label input[type='checkbox']:checked + .input-helper:after {
    width: 18px;
    opacity: 1;
    line-height: 18px;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.form-check .form-check-label input[type='checkbox']:disabled + .input-helper:before {
    border-color: #e4e6f6;
}

.form-check .form-check-label input[type='checkbox']:disabled:checked + .input-helper:after {
    background: #e4e6f6;
    color: #ffffff;
}

.form-check .form-check-label input[type='radio'] + .input-helper:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    border: solid #ec37fc;
    border-width: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -webkit-transition: all;
    -moz-transition: all;
    -ms-transition: all;
    -o-transition: all;
    transition: all;
    transition-duration: 0s;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
}

.rtl .form-check .form-check-label input[type='radio'] + .input-helper:before {
    left: auto;
    right: 0;
}

.form-check .form-check-label input[type='radio'] + .input-helper:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #ffffff;
    border-radius: 50%;
    top: 6px;
    left: 6px;
    -webkit-transition: all;
    -o-transition: all;
    transition: all;
    transition-duration: 0s;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    position: absolute;
}

.rtl .form-check .form-check-label input[type='radio'] + .input-helper:after {
    left: auto;
    right: 6px;
}

.form-check .form-check-label input[type='radio']:checked + .input-helper:before {
    background: #ec37fc;
    border-width: 0;
}

.form-check .form-check-label input[type='radio']:checked + .input-helper:after {
    opacity: 1;
    line-height: 1.5;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.form-check .form-check-label input[type='radio']:disabled + .input-helper:before {
    border-color: #e4e6f6;
}

.form-check .form-check-label input[type='radio']:disabled:checked + .input-helper:before {
    background: #e4e6f6;
}

.form-check .form-check-label input[type='radio']:disabled:checked + .input-helper:after {
    background: #ffffff;
}

.form-check-primary.form-check label input[type='checkbox'] + .input-helper:before,
.form-check-primary.form-check label input[type='radio'] + .input-helper:before {
    border-color: #ec37fc;
}

.form-check-primary.form-check label input[type='checkbox']:checked + .input-helper:before,
.form-check-primary.form-check label input[type='radio']:checked + .input-helper:before {
    background: #ec37fc;
}

.form-check-secondary.form-check label input[type='checkbox'] + .input-helper:before,
.form-check-secondary.form-check label input[type='radio'] + .input-helper:before {
    border-color: #e4e6f6;
}

.form-check-secondary.form-check label input[type='checkbox']:checked + .input-helper:before,
.form-check-secondary.form-check label input[type='radio']:checked + .input-helper:before {
    background: #e4e6f6;
}

.form-check-success.form-check label input[type='checkbox'] + .input-helper:before,
.form-check-success.form-check label input[type='radio'] + .input-helper:before {
    border-color: #51c81c;
}

.form-check-success.form-check label input[type='checkbox']:checked + .input-helper:before,
.form-check-success.form-check label input[type='radio']:checked + .input-helper:before {
    background: #51c81c;
}

.form-check-info.form-check label input[type='checkbox'] + .input-helper:before,
.form-check-info.form-check label input[type='radio'] + .input-helper:before {
    border-color: #3794fc;
}

.form-check-info.form-check label input[type='checkbox']:checked + .input-helper:before,
.form-check-info.form-check label input[type='radio']:checked + .input-helper:before {
    background: #3794fc;
}

.form-check-warning.form-check label input[type='checkbox'] + .input-helper:before,
.form-check-warning.form-check label input[type='radio'] + .input-helper:before {
    border-color: #fcb41d;
}

.form-check-warning.form-check label input[type='checkbox']:checked + .input-helper:before,
.form-check-warning.form-check label input[type='radio']:checked + .input-helper:before {
    background: #fcb41d;
}

.form-check-danger.form-check label input[type='checkbox'] + .input-helper:before,
.form-check-danger.form-check label input[type='radio'] + .input-helper:before {
    border-color: #fc381d;
}

.form-check-danger.form-check label input[type='checkbox']:checked + .input-helper:before,
.form-check-danger.form-check label input[type='radio']:checked + .input-helper:before {
    background: #fc381d;
}

.form-check-light.form-check label input[type='checkbox'] + .input-helper:before,
.form-check-light.form-check label input[type='radio'] + .input-helper:before {
    border-color: #dee5ef;
}

.form-check-light.form-check label input[type='checkbox']:checked + .input-helper:before,
.form-check-light.form-check label input[type='radio']:checked + .input-helper:before {
    background: #dee5ef;
}

.form-check-dark.form-check label input[type='checkbox'] + .input-helper:before,
.form-check-dark.form-check label input[type='radio'] + .input-helper:before {
    border-color: #001737;
}

.form-check-dark.form-check label input[type='checkbox']:checked + .input-helper:before,
.form-check-dark.form-check label input[type='radio']:checked + .input-helper:before {
    background: #001737;
}

/* Dropdowns */
.dropdown .dropdown-toggle:after {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
    font: normal normal normal 24px/1 'Material Design Icons';
    content: '\F140';
    width: auto;
    height: auto;
    vertical-align: middle;
    line-height: 0.625rem;
    font-size: 1.25rem;
    margin-left: 0;
}

.dropdown .dropdown-menu {
    margin-top: 0.75rem;
    font-size: 0.875rem;
    box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
    border-radius: 0;
}

.dropdown .dropdown-menu .dropdown-item {
    font-size: 0.875rem;
    padding: 0.25rem 1.5rem;
}

.dropdown .dropdown-menu .dropdown-item:active {
    background: initial;
}

/* Forms */
.form-group {
    margin-bottom: 1.5rem;
}

.input-group-append,
.input-group-prepend {
    color: #666666;
    width: auto;
    border: none;
}

.input-group-append .input-group-text,
.input-group-prepend .input-group-text {
    border-color: #e4e6f6;
    padding: 0.875rem 0.75rem;
    color: #666666;
}

.form-control,
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--single .select2-search__field,
.typeahead,
.tt-query,
.tt-hint {
    border: 1px solid #e4e6f6;
    font-weight: 400;
    font-size: 0.875rem;
    height: auto;
}

select.form-control,
.select2-container--default select.select2-selection--single,
.select2-container--default .select2-selection--single select.select2-search__field,
select.typeahead,
select.tt-query,
select.tt-hint {
    padding: 0.4375rem 0.75rem;
    border: 0;
    outline: 1px solid #e4e6f6;
    color: #666666;
}

select.form-control:focus,
.select2-container--default select.select2-selection--single:focus,
.select2-container--default .select2-selection--single select.select2-search__field:focus,
select.typeahead:focus,
select.tt-query:focus,
select.tt-hint:focus {
    outline: 1px solid #e4e6f6;
}

select.form-control.border-primary,
.select2-container--default select.border-primary.select2-selection--single,
.select2-container--default .select2-selection--single select.border-primary.select2-search__field,
select.border-primary.typeahead,
select.border-primary.tt-query,
select.border-primary.tt-hint {
    outline: 1px solid #ec37fc;
}

select.form-control.border-primary:focus,
.select2-container--default select.border-primary.select2-selection--single:focus,
.select2-container--default
    .select2-selection--single
    select.border-primary.select2-search__field:focus,
select.border-primary.typeahead:focus,
select.border-primary.tt-query:focus,
select.border-primary.tt-hint:focus {
    outline: 1px solid #ec37fc;
}

select.form-control.border-secondary,
.select2-container--default select.border-secondary.select2-selection--single,
.select2-container--default
    .select2-selection--single
    select.border-secondary.select2-search__field,
select.border-secondary.typeahead,
select.border-secondary.tt-query,
select.border-secondary.tt-hint {
    outline: 1px solid #e4e6f6;
}

select.form-control.border-secondary:focus,
.select2-container--default select.border-secondary.select2-selection--single:focus,
.select2-container--default
    .select2-selection--single
    select.border-secondary.select2-search__field:focus,
select.border-secondary.typeahead:focus,
select.border-secondary.tt-query:focus,
select.border-secondary.tt-hint:focus {
    outline: 1px solid #e4e6f6;
}

select.form-control.border-success,
.select2-container--default select.border-success.select2-selection--single,
.select2-container--default .select2-selection--single select.border-success.select2-search__field,
select.border-success.typeahead,
select.border-success.tt-query,
select.border-success.tt-hint {
    outline: 1px solid #51c81c;
}

select.form-control.border-success:focus,
.select2-container--default select.border-success.select2-selection--single:focus,
.select2-container--default
    .select2-selection--single
    select.border-success.select2-search__field:focus,
select.border-success.typeahead:focus,
select.border-success.tt-query:focus,
select.border-success.tt-hint:focus {
    outline: 1px solid #51c81c;
}

select.form-control.border-info,
.select2-container--default select.border-info.select2-selection--single,
.select2-container--default .select2-selection--single select.border-info.select2-search__field,
select.border-info.typeahead,
select.border-info.tt-query,
select.border-info.tt-hint {
    outline: 1px solid #3794fc;
}

select.form-control.border-info:focus,
.select2-container--default select.border-info.select2-selection--single:focus,
.select2-container--default
    .select2-selection--single
    select.border-info.select2-search__field:focus,
select.border-info.typeahead:focus,
select.border-info.tt-query:focus,
select.border-info.tt-hint:focus {
    outline: 1px solid #3794fc;
}

select.form-control.border-warning,
.select2-container--default select.border-warning.select2-selection--single,
.select2-container--default .select2-selection--single select.border-warning.select2-search__field,
select.border-warning.typeahead,
select.border-warning.tt-query,
select.border-warning.tt-hint {
    outline: 1px solid #fcb41d;
}

select.form-control.border-warning:focus,
.select2-container--default select.border-warning.select2-selection--single:focus,
.select2-container--default
    .select2-selection--single
    select.border-warning.select2-search__field:focus,
select.border-warning.typeahead:focus,
select.border-warning.tt-query:focus,
select.border-warning.tt-hint:focus {
    outline: 1px solid #fcb41d;
}

select.form-control.border-danger,
.select2-container--default select.border-danger.select2-selection--single,
.select2-container--default .select2-selection--single select.border-danger.select2-search__field,
select.border-danger.typeahead,
select.border-danger.tt-query,
select.border-danger.tt-hint {
    outline: 1px solid #fc381d;
}

select.form-control.border-danger:focus,
.select2-container--default select.border-danger.select2-selection--single:focus,
.select2-container--default
    .select2-selection--single
    select.border-danger.select2-search__field:focus,
select.border-danger.typeahead:focus,
select.border-danger.tt-query:focus,
select.border-danger.tt-hint:focus {
    outline: 1px solid #fc381d;
}

select.form-control.border-light,
.select2-container--default select.border-light.select2-selection--single,
.select2-container--default .select2-selection--single select.border-light.select2-search__field,
select.border-light.typeahead,
select.border-light.tt-query,
select.border-light.tt-hint {
    outline: 1px solid #dee5ef;
}

select.form-control.border-light:focus,
.select2-container--default select.border-light.select2-selection--single:focus,
.select2-container--default
    .select2-selection--single
    select.border-light.select2-search__field:focus,
select.border-light.typeahead:focus,
select.border-light.tt-query:focus,
select.border-light.tt-hint:focus {
    outline: 1px solid #dee5ef;
}

select.form-control.border-dark,
.select2-container--default select.border-dark.select2-selection--single,
.select2-container--default .select2-selection--single select.border-dark.select2-search__field,
select.border-dark.typeahead,
select.border-dark.tt-query,
select.border-dark.tt-hint {
    outline: 1px solid #001737;
}

select.form-control.border-dark:focus,
.select2-container--default select.border-dark.select2-selection--single:focus,
.select2-container--default
    .select2-selection--single
    select.border-dark.select2-search__field:focus,
select.border-dark.typeahead:focus,
select.border-dark.tt-query:focus,
select.border-dark.tt-hint:focus {
    outline: 1px solid #001737;
}

.form-group label {
    font-size: 0.875rem;
    line-height: 1.4rem;
    vertical-align: top;
    margin-bottom: 0.5rem;
}

.form-group.has-danger .form-control,
.form-group.has-danger .select2-container--default .select2-selection--single,
.select2-container--default .form-group.has-danger .select2-selection--single,
.form-group.has-danger
    .select2-container--default
    .select2-selection--single
    .select2-search__field,
.select2-container--default
    .select2-selection--single
    .form-group.has-danger
    .select2-search__field,
.form-group.has-danger .typeahead,
.form-group.has-danger .tt-query,
.form-group.has-danger .tt-hint {
    border-color: #fc381d;
}

.form-group .file-upload-default {
    visibility: hidden;
    position: absolute;
}

.form-group .file-upload-info {
    background: transparent;
}

/* Lists */
ul,
ol,
dl {
    padding-left: 1rem;
    font-size: 0.875rem;
}

ul li,
ol li,
dl li {
    line-height: 1.8;
}

.list-ticked,
.list-arrow,
.list-star {
    list-style: none;
    padding: 0;
}

.list-ticked li,
.list-arrow li,
.list-star li {
    padding-left: 1.5rem;
}

.list-ticked li:before,
.list-arrow li:before,
.list-star li:before {
    font-family: 'Material Design Icons';
    margin-left: -1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;
}

.list-ticked li:before {
    content: '\F12D';
    color: #fc381d;
}

.list-arrow li:before {
    content: '\F142';
    color: #51c81c;
}

.list-star li:before {
    content: '\F4CE';
    color: #fcb41d;
}

.solid-bullet-list {
    position: relative;
    padding-left: 0;
}

.rtl .solid-bullet-list {
    padding-right: 0;
}

.solid-bullet-list li {
    position: relative;
    list-style-type: none;
    padding-left: 25px;
    line-height: 1;
    padding-bottom: 2.125rem;
}

.solid-bullet-list li * {
    line-height: 0.8;
}

.solid-bullet-list li:before,
.solid-bullet-list li:after {
    content: '';
    position: absolute;
}

.solid-bullet-list li:before {
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #ec37fc;
    z-index: 1;
}

.solid-bullet-list:after {
    content: '';
    border: 1px solid #e4e6f6;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 4px;
    z-index: 0;
}

.bullet-line-list {
    padding-left: 30px;
    position: relative;
    list-style-type: none;
}

.rtl .bullet-line-list {
    padding-left: unset;
    padding-right: 30px;
}

.bullet-line-list li {
    position: relative;
}

.bullet-line-list li:before {
    width: 15px;
    height: 15px;
    left: -30px;
    top: 0;
    border: 4px solid #ec37fc;
    margin-right: 15px;
    z-index: 2;
    background: #ffffff;
}

.rtl .bullet-line-list li:before {
    left: unset;
    right: -45px;
}

.bullet-line-list li:before {
    content: '';
    position: absolute;
    border-radius: 100%;
}

.bullet-line-list:after {
    content: '';
    border: 1px solid #e4e6f6;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 7px;
}

.rtl .bullet-line-list:after {
    left: unset;
    right: 7px;
}

/* Icons */
.icons-list {
    border-left: 1px solid #e4e6f6;
    border-top: 1px solid #e4e6f6;
}

.icons-list > div {
    background: #ffffff;
    border-bottom: 1px solid #e4e6f6;
    border-right: 1px solid #e4e6f6;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 15px 15px;
    font-weight: 400;
    font-size: 0.875rem;
}

.icons-list > div i {
    display: inline-block;
    font-size: 20px;
    width: 40px;
    text-align: left;
    color: #ec37fc;
}

.icon-in-bg {
    width: 55px;
    height: 55px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.icon-lg {
    font-size: 2.5rem;
}

.icon-md {
    font-size: 1.5rem;
}

.icon-sm {
    font-size: 1rem;
}

/*icon boxes*/
.icon-box-primary {
    width: 2.812rem;
    height: 2.812rem;
    border-radius: 5px;
    line-height: 2.812rem;
    text-align: center;
    background: #ec37fc;
}

.icon-box-primary i {
    color: white;
    font-size: 1.25rem;
}

.icon-box-primary.icon-box-lg {
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
}

.icon-box-primary.icon-box-lg i {
    font-size: 2.5rem;
}

.icon-box-secondary {
    width: 2.812rem;
    height: 2.812rem;
    border-radius: 5px;
    line-height: 2.812rem;
    text-align: center;
    background: #e4e6f6;
}

.icon-box-secondary i {
    color: white;
    font-size: 1.25rem;
}

.icon-box-secondary.icon-box-lg {
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
}

.icon-box-secondary.icon-box-lg i {
    font-size: 2.5rem;
}

.icon-box-success {
    width: 2.812rem;
    height: 2.812rem;
    border-radius: 5px;
    line-height: 2.812rem;
    text-align: center;
    background: #51c81c;
}

.icon-box-success i {
    color: white;
    font-size: 1.25rem;
}

.icon-box-success.icon-box-lg {
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
}

.icon-box-success.icon-box-lg i {
    font-size: 2.5rem;
}

.icon-box-info {
    width: 2.812rem;
    height: 2.812rem;
    border-radius: 5px;
    line-height: 2.812rem;
    text-align: center;
    background: #3794fc;
}

.icon-box-info i {
    color: white;
    font-size: 1.25rem;
}

.icon-box-info.icon-box-lg {
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
}

.icon-box-info.icon-box-lg i {
    font-size: 2.5rem;
}

.icon-box-warning {
    width: 2.812rem;
    height: 2.812rem;
    border-radius: 5px;
    line-height: 2.812rem;
    text-align: center;
    background: #fcb41d;
}

.icon-box-warning i {
    color: white;
    font-size: 1.25rem;
}

.icon-box-warning.icon-box-lg {
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
}

.icon-box-warning.icon-box-lg i {
    font-size: 2.5rem;
}

.icon-box-danger {
    width: 2.812rem;
    height: 2.812rem;
    border-radius: 5px;
    line-height: 2.812rem;
    text-align: center;
    background: #fc381d;
}

.icon-box-danger i {
    color: white;
    font-size: 1.25rem;
}

.icon-box-danger.icon-box-lg {
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
}

.icon-box-danger.icon-box-lg i {
    font-size: 2.5rem;
}

.icon-box-light {
    width: 2.812rem;
    height: 2.812rem;
    border-radius: 5px;
    line-height: 2.812rem;
    text-align: center;
    background: #dee5ef;
}

.icon-box-light i {
    color: white;
    font-size: 1.25rem;
}

.icon-box-light.icon-box-lg {
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
}

.icon-box-light.icon-box-lg i {
    font-size: 2.5rem;
}

.icon-box-dark {
    width: 2.812rem;
    height: 2.812rem;
    border-radius: 5px;
    line-height: 2.812rem;
    text-align: center;
    background: #001737;
}

.icon-box-dark i {
    color: white;
    font-size: 1.25rem;
}

.icon-box-dark.icon-box-lg {
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
}

.icon-box-dark.icon-box-lg i {
    font-size: 2.5rem;
}

/* Preview */
.preview-list .preview-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    padding: 1.25rem 0;
}

.preview-list .preview-item:last-child {
    border-bottom: 0;
}

.preview-list .preview-item .form-check {
    margin-top: 8px;
    margin-right: 1rem;
}

.preview-list .preview-item .preview-thumbnail {
    color: #ffffff;
    position: relative;
}

.preview-list .preview-item .preview-thumbnail img,
.preview-list .preview-item .preview-thumbnail .preview-icon {
    width: 36px;
    height: 36px;
    border-radius: 100%;
}

.preview-list .preview-item .preview-thumbnail .preview-icon {
    padding: 6px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.preview-list .preview-item .preview-thumbnail .preview-icon i {
    font-size: 1.125rem;
    margin: 0;
}

.preview-list .preview-item .preview-thumbnail .badge {
    border: 2px solid #ffffff;
    border-radius: 100%;
    bottom: 5px;
    display: block;
    height: 14px;
    left: -5px;
    padding: 0;
    position: absolute;
    width: 14px;
}

.preview-list .preview-item .preview-item-content {
    line-height: 1;
    padding-left: 15px;
}

.preview-list .preview-item .preview-item-content:first-child {
    padding-left: 0;
}

.preview-list .preview-item .preview-item-content p {
    margin-bottom: 10px;
}

.preview-list .preview-item .preview-item-content p .content-category {
    font-family: 'source-sans-pro-semibold', sans-serif;
    padding-right: 15px;
    border-right: 1px solid #e4e6f6;
}

.rtl .preview-list .preview-item .preview-item-content {
    padding-left: 0;
    padding-right: 1rem;
    margin-right: 0;
    margin-left: auto;
}

.preview-list .preview-item .preview-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
}

.preview-list .preview-item .preview-actions i {
    width: 29px;
    color: #e6e9ed;
    height: 29px;
    border: 2px solid #e6e9ed;
    border-radius: 100%;
    padding: 3px 6px;
    display: inline-block;
}

.preview-list .preview-item .preview-actions i:first-child {
    margin-right: 10px;
}

.preview-list.comment-preview .preview-item {
    padding: 0.87rem 0;
}

.preview-list.comment-preview .preview-item:first-child {
    padding-top: 0;
}

.preview-list.comment-preview .preview-item p {
    line-height: 27px;
}

.preview-list.bordered .preview-item {
    border-bottom: 1px solid #e4e6f6;
}

.preview-list.bordered .preview-item:last-child {
    border-bottom: 0;
}

/* Tables */
.table {
    margin-bottom: 0;
}

.table thead th {
    border-top: 0;
    border-bottom-width: 1px;
    font-weight: 500;
    font-size: 1rem;
}

.table thead th i {
    margin-left: 0.325rem;
}

.table th,
.table td {
    vertical-align: middle;
    line-height: 1;
}

.table td {
    font-size: 0.875rem;
}

.table td img {
    width: 36px;
    height: 36px;
    border-radius: 100%;
}

.table td .badge {
    margin-bottom: 0;
}

.table.table-borderless {
    border: none;
}

.table.table-borderless tr,
.table.table-borderless td,
.table.table-borderless th {
    border: none;
}

.table.table-bordered {
    border-top: 1px solid #e4e6f6;
}

.table.table-header-bg th {
    background: #dee5ef;
    font-weight: bold;
}

.table.table-header-bg td {
    color: #001737;
}

.table.table-header-bg td .flag-icon {
    width: 2rem;
    line-height: 2rem;
}

.table.table-header-bg td .progress {
    width: 200px;
}

@media screen and (max-width: 991px) {
    .table.table-header-bg td .progress {
        width: 100%;
    }
}

/* Toggle Switch */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 25px;
}

.toggle-switch .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e9ecef;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
    border: 1px solid #e4e6f6;
}

.toggle-switch .toggle-slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: #ffffff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
}

.toggle-switch input {
    visibility: hidden;
}

.toggle-switch input:checked + .toggle-slider {
    background-color: #ec37fc;
}

.toggle-switch input:checked + .toggle-slider:before {
    -webkit-transform: translateX(23px);
    -ms-transform: translateX(23px);
    transform: translateX(23px);
}

.toggle-switch.toggle-switch-primary input:checked + .toggle-slider {
    background-color: #ec37fc;
}

.toggle-switch.toggle-switch-secondary input:checked + .toggle-slider {
    background-color: #e4e6f6;
}

.toggle-switch.toggle-switch-success input:checked + .toggle-slider {
    background-color: #51c81c;
}

.toggle-switch.toggle-switch-info input:checked + .toggle-slider {
    background-color: #3794fc;
}

.toggle-switch.toggle-switch-warning input:checked + .toggle-slider {
    background-color: #fcb41d;
}

.toggle-switch.toggle-switch-danger input:checked + .toggle-slider {
    background-color: #fc381d;
}

.toggle-switch.toggle-switch-light input:checked + .toggle-slider {
    background-color: #dee5ef;
}

.toggle-switch.toggle-switch-dark input:checked + .toggle-slider {
    background-color: #001737;
}

/* Rounded sliders */
/*-------------------------------------------------------------------*/
/*-------------------------------------------------------------------*/
/* === Plugin overrides === */
/* Jquery File Upload */
.ajax-upload-dragdrop {
    border-color: #e4e6f6;
    border-style: dotted;
    max-width: 100%;
}

.ajax-upload-dragdrop span b {
    font-size: 0.8125rem;
    color: #666666;
    font-weight: initial;
}

.ajax-upload-dragdrop .ajax-file-upload {
    background: #3794fc;
    box-shadow: none;
    height: auto;
}

.ajax-file-upload-container {
    min-height: 100px;
}

.ajax-file-upload-container .ajax-file-upload-statusbar {
    max-width: 100%;
}

.ajax-file-upload-container .ajax-file-upload-filename {
    max-width: 100%;
}

.ajax-file-upload-container .ajax-file-upload-error {
    color: #fc381d;
}

/* Progressbar-js */
.progress-bar-js-line {
    height: 6px;
}

.progressbar-js-circle {
    width: 100%;
    position: relative;
}

/* Rating */
.br-theme-fontawesome-stars .br-widget a,
.br-theme-css-stars .br-widget a {
    font-size: 16px;
    margin-right: 5px;
    margin-top: 4px;
    display: inline-block;
}

.br-theme-fontawesome-stars .br-widget a.br-selected:after,
.br-theme-fontawesome-stars .br-widget a.br-active:after,
.br-theme-css-stars .br-widget a.br-selected:after,
.br-theme-css-stars .br-widget a.br-active:after {
    color: #fcb41d;
}

.br-theme-bars-1to10 .br-widget a,
.br-theme-bars-movie .br-widget a,
.br-theme-bars-pill .br-widget a,
.br-theme-bars-reversed .br-widget a,
.br-theme-bars-horizontal .br-widget a {
    background-color: rgba(236, 55, 252, 0.6);
    color: #ec37fc;
}

.br-theme-bars-1to10 .br-widget a.br-selected,
.br-theme-bars-1to10 .br-widget a.br-active,
.br-theme-bars-movie .br-widget a.br-selected,
.br-theme-bars-movie .br-widget a.br-active,
.br-theme-bars-pill .br-widget a.br-selected,
.br-theme-bars-pill .br-widget a.br-active,
.br-theme-bars-reversed .br-widget a.br-selected,
.br-theme-bars-reversed .br-widget a.br-active,
.br-theme-bars-horizontal .br-widget a.br-selected,
.br-theme-bars-horizontal .br-widget a.br-active {
    background-color: #ec37fc;
}

.br-theme-bars-square .br-widget a {
    border-color: rgba(236, 55, 252, 0.6);
    color: rgba(236, 55, 252, 0.6);
}

.br-theme-bars-square .br-widget a.br-selected,
.br-theme-bars-square .br-widget a.br-active {
    border-color: #ec37fc;
    color: #ec37fc;
}

.br-theme-bars-movie .br-widget .br-current-rating,
.br-theme-bars-reversed .br-widget .br-current-rating,
.br-theme-bars-1to10 .br-widget .br-current-rating,
.br-theme-bars-horizontal .br-widget .br-current-rating {
    color: #666666;
    font-size: 0.875rem;
    font-weight: initial;
}

.br-theme-bars-1to10 {
    height: 26px;
}

.br-theme-bars-1to10 .br-widget {
    height: 24px;
}

.br-theme-bars-1to10 .br-widget .br-current-rating {
    line-height: 1;
}

.br-theme-bars-pill .br-widget a {
    line-height: 2.5;
}

/* Select2 */
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: #ec37fc;
}

.select2-container--default .select2-selection--single,
.select2-container--default .select2-dropdown,
.select2-container--default .select2-selection--multiple {
    border-color: #e4e6f6;
}

.select2-container--default .select2-selection--single .select2-search__field,
.select2-container--default .select2-dropdown .select2-search__field,
.select2-container--default .select2-selection--multiple .select2-search__field {
    border-color: #e4e6f6;
}

.select2-container--default .select2-selection--single {
    height: auto;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 14px;
}

.select2-container--default .select2-dropdown {
    font-size: 0.8125rem;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #e4e6f6;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    color: #ffffff;
    border: 0;
    border-radius: 3px;
    padding: 6px;
    font-size: 0.625rem;
    font-family: inherit;
    line-height: 1;
}

.select2-container--default
    .select2-selection--multiple
    .select2-selection__choice
    .select2-selection__choice__remove {
    color: #ffffff;
}

.select2-container--default
    .select2-selection--multiple
    .select2-selection__choice:nth-child(5n + 1) {
    background: #ec37fc;
}

.select2-container--default
    .select2-selection--multiple
    .select2-selection__choice:nth-child(5n + 2) {
    background: #51c81c;
}

.select2-container--default
    .select2-selection--multiple
    .select2-selection__choice:nth-child(5n + 3) {
    background: #3794fc;
}

.select2-container--default
    .select2-selection--multiple
    .select2-selection__choice:nth-child(5n + 4) {
    background: #fc381d;
}

.select2-container--default
    .select2-selection--multiple
    .select2-selection__choice:nth-child(5n + 5) {
    background: #fcb41d;
}

/* Typeahead */
.tt-menu,
.gist {
    text-align: left;
}

.twitter-typeahead {
    max-width: 100%;
}

.typeahead {
    background-color: #ffffff;
}

.typeahead:focus {
    border-color: #e4e6f6;
}

.tt-query {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
    color: #434a54;
}

.tt-menu {
    width: 100%;
    margin: 12px 0;
    padding: 8px 0;
    background-color: #ffffff;
    border: 1px solid #e4e6f6;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.tt-suggestion {
    padding: 3px 20px;
    font-size: inherit;
}

.tt-suggestion:hover {
    cursor: pointer;
    color: #ffffff;
    background-color: #ec37fc;
}

.tt-suggestion .tt-cursor {
    color: #ffffff;
    background-color: #ec37fc;
}

.tt-suggestion p {
    margin: 0;
}

/*-------------------------------------------------------------------*/
/* === Landing screens === */
/* Auth */
.auth .login-half-bg {
    background: url('../images/auth/login-bg.jpg');
    background-size: cover;
}

.auth .register-half-bg {
    background: url('../images/auth/register-bg.jpg');
    background-size: cover;
}

.auth.lock-full-bg {
    background: url('	../images/auth/lockscreen-bg.jpg');
    background-size: cover;
}

.auth .lock-profile-img {
    width: 90px;
    height: 90px;
    border-radius: 100%;
}

.auth .auth-form-light {
    background: #ffffff;
}

.auth .auth-form-light select {
    color: #666666;
}

.auth .auth-form-light .input-group .form-control:focus,
.auth .auth-form-light .input-group .select2-container--default .select2-selection--single:focus,
.select2-container--default .auth .auth-form-light .input-group .select2-selection--single:focus,
.auth
    .auth-form-light
    .input-group
    .select2-container--default
    .select2-selection--single
    .select2-search__field:focus,
.select2-container--default
    .select2-selection--single
    .auth
    .auth-form-light
    .input-group
    .select2-search__field:focus,
.auth .auth-form-light .input-group .typeahead:focus,
.auth .auth-form-light .input-group .tt-query:focus,
.auth .auth-form-light .input-group .tt-hint:focus,
.auth .auth-form-light .input-group .form-control:active,
.auth .auth-form-light .input-group .select2-container--default .select2-selection--single:active,
.select2-container--default .auth .auth-form-light .input-group .select2-selection--single:active,
.auth
    .auth-form-light
    .input-group
    .select2-container--default
    .select2-selection--single
    .select2-search__field:active,
.select2-container--default
    .select2-selection--single
    .auth
    .auth-form-light
    .input-group
    .select2-search__field:active,
.auth .auth-form-light .input-group .typeahead:active,
.auth .auth-form-light .input-group .tt-query:active,
.auth .auth-form-light .input-group .tt-hint:active {
    border-color: #e4e6f6;
}

.auth .auth-form-transparent {
    background: transparent;
}

.auth .auth-form-transparent .form-control,
.auth .auth-form-transparent .select2-container--default .select2-selection--single,
.select2-container--default .auth .auth-form-transparent .select2-selection--single,
.auth
    .auth-form-transparent
    .select2-container--default
    .select2-selection--single
    .select2-search__field,
.select2-container--default
    .select2-selection--single
    .auth
    .auth-form-transparent
    .select2-search__field,
.auth .auth-form-transparent .typeahead,
.auth .auth-form-transparent .tt-query,
.auth .auth-form-transparent .tt-hint,
.auth .auth-form-transparent .input-group-text {
    border-color: #e4e6f6;
}

.auth .auth-form-transparent .form-control:focus,
.auth .auth-form-transparent .select2-container--default .select2-selection--single:focus,
.select2-container--default .auth .auth-form-transparent .select2-selection--single:focus,
.auth
    .auth-form-transparent
    .select2-container--default
    .select2-selection--single
    .select2-search__field:focus,
.select2-container--default
    .select2-selection--single
    .auth
    .auth-form-transparent
    .select2-search__field:focus,
.auth .auth-form-transparent .typeahead:focus,
.auth .auth-form-transparent .tt-query:focus,
.auth .auth-form-transparent .tt-hint:focus,
.auth .auth-form-transparent .form-control:active,
.auth .auth-form-transparent .select2-container--default .select2-selection--single:active,
.select2-container--default .auth .auth-form-transparent .select2-selection--single:active,
.auth
    .auth-form-transparent
    .select2-container--default
    .select2-selection--single
    .select2-search__field:active,
.select2-container--default
    .select2-selection--single
    .auth
    .auth-form-transparent
    .select2-search__field:active,
.auth .auth-form-transparent .typeahead:active,
.auth .auth-form-transparent .tt-query:active,
.auth .auth-form-transparent .tt-hint:active,
.auth .auth-form-transparent .input-group-text:focus,
.auth .auth-form-transparent .input-group-text:active {
    border-color: #e4e6f6;
}

.auth .auth-form-transparent select {
    outline-color: #e4e6f6;
}

.auth.auth-img-bg {
    padding: 0;
}

@media (min-width: 768px) {
    .auth.auth-img-bg .auth-form-transparent {
        width: 55%;
        margin: auto;
    }
}

.auth .brand-logo {
    margin-bottom: 2rem;
}

.auth .brand-logo img {
    width: 115px;
}

.auth form .form-group {
    margin-bottom: 1.5rem;
}

.auth form .form-group label {
    font-size: 0.8125rem;
}

.auth form .form-group .form-control,
.auth form .form-group .select2-container--default .select2-selection--single,
.select2-container--default .auth form .form-group .select2-selection--single,
.auth
    form
    .form-group
    .select2-container--default
    .select2-selection--single
    .select2-search__field,
.select2-container--default
    .select2-selection--single
    .auth
    form
    .form-group
    .select2-search__field,
.auth form .form-group .typeahead,
.auth form .form-group .tt-query,
.auth form .form-group .tt-hint {
    background: transparent;
    border-radius: 0;
    font-size: 0.9375rem;
}

.auth form .auth-form-btn {
    height: 50px;
    line-height: 1.5;
}

.auth form .auth-link {
    font-size: 0.875rem;
}

.auth form .auth-link:hover {
    color: initial;
}

.page-body-wrapper {
    min-height: calc(100vh - 60px);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
    /* padding-top: 60px; */
}

.page-body-wrapper.full-page-wrapper {
    width: 100%;
    min-height: 100vh;
    padding-top: 0;
}

.sidebar-icon-only .brand-logo {
    display: none;
}

.brand-logo-mini {
    display: none;
}

.sidebar-icon-only .brand-logo-mini {
    display: inline-block;
}

.main-panel {
    transition:
        width 0.25s ease,
        margin 0.25s ease;
    width: calc(100% - 240px);
    min-height: calc(100vh - 60px);
    margin-left: 250px;
    gap: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.myheader {
    padding-left: 5px;
    padding-right: 10px;
}

.main-panel .main-content {
    overflow-y: auto;
    max-height: 90vh;
    height: 90vh;
}

@media (max-width: 991px) {
    .main-panel {
        margin-left: 0;
        width: 100%;
    }
}

.content-wrapper {
    background: white;
    padding-left: 5px;
    max-height: 90vh;
    padding-right: 10px;
    overflow: auto;
    width: 100%;
    -webkit-flex-grow: 1;
    flex-grow: 1;
}

@media (max-width: 767px) {
    .content-wrapper {
        padding: 1.5rem 1.5rem;
    }
}

/* Sidebar */
.sidebar {
    min-height: calc(100vh - 60px);
    background: #ec37fc;
    font-family: Poppins !important;
    font-weight: normal;
    padding: 0;
    width: 250px;
    position: fixed;
    z-index: 11;
    transition:
        width 0.25s ease,
        background 0.25s ease;
    -webkit-transition:
        width 0.25s ease,
        background 0.25s ease;
    -moz-transition:
        width 0.25s ease,
        background 0.25s ease;
    -ms-transition:
        width 0.25s ease,
        background 0.25s ease;
    box-shadow: 15px 0px 10px -18px #b7bcd1;
    -webkit-box-shadow: 15px 0px 10px -18px #b7bcd1;
    -moz-box-shadow: 15px 0px 10px -18px #b7bcd1;
    -ms-box-shadow: 15px 0px 10px -18px #b7bcd1;
}

.sidebar.select2-selection--single:disabled {
    width: 0px;
}

.sidebar.open {
    width: 280px;
}

.sidebar .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 60px;
}

.sidebar .nav .nav-item {
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    -o-transition-duration: 0.25s;
    transition-duration: 0.25s;
    transition-property: background;
    -webkit-transition-property: background;
}

.sidebar .nav .nav-item .collapse {
    z-index: 999;
}

.sidebar .nav .nav-item .nav-link {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: baseline;
    align-items: baseline;
    white-space: nowrap;
    /* padding: 1rem 0.875rem 1rem 0.875rem;
   margin: 0 1.5rem; */
    color: #fff;
    -webkit-transition-duration: 0.45s;
    -moz-transition-duration: 0.45s;
    -o-transition-duration: 0.45s;
    transition-duration: 0.45s;
    transition-property: background;
    -webkit-transition-property: background;
    /* font-weight: bold; */
    border-top: 1px solid rgba(255, 255, 255, 0.36);
    font-size: 13px !important;
    font-family: Poppins !important;
}

.sidebar .nav .nav-item .nav-link i {
    color: inherit;
}

.sidebar .nav .nav-item .nav-link i.menu-icon {
    font-size: 1.3rem;
    line-height: 1;
    margin-right: 1.125rem;
    color: #fff;
}

.rtl .sidebar .nav .nav-item .nav-link i.menu-icon {
    margin-left: 2rem;
    margin-right: 0;
}

.sidebar .nav .nav-item .nav-link i.menu-icon:before {
    vertical-align: middle;
}

.sidebar .nav .nav-item .nav-link i.menu-arrow {
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-left: auto;
    margin-right: 0;
    color: #fff;
}

.rtl .sidebar .nav .nav-item .nav-link i.menu-arrow {
    margin-left: 0;
    margin-right: auto;
}

.sidebar .nav .nav-item .nav-link i.menu-arrow:before {
    content: '\F142';
    font-family: 'Material Design Icons';
    font-style: normal;
    display: block;
    font-size: 1rem;
    line-height: 10px;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

.sidebar .nav .nav-item .nav-link .menu-title {
    color: inherit;
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1;
    vertical-align: middle;
}

.sidebar .nav .nav-item .nav-link .badge {
    margin-left: auto;
}

.rtl .sidebar .nav .nav-item .nav-link .badge {
    margin-left: 0;
    margin-right: auto;
}

.sidebar .nav .nav-item .nav-link[aria-expanded='true'] {
    background: rgba(255, 255, 255, 0.4);
    border-top: 1px solid #ec37fc;
    border-radius: 4px;
}

.sidebar .nav .nav-item .nav-link[aria-expanded='true']:hover {
    border-top: 1px solid none;
}

.sidebar .nav .nav-item .nav-link[aria-expanded='true'] i.menu-arrow:before {
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sidebar .nav .nav-item.active > .nav-link {
    background: rgba(255, 255, 255, 0.4);
    position: relative;
    border-top: 1px solid transparent;
    border-radius: 4px;
}

.sidebar .nav .nav-item.active > .nav-link i,
.sidebar .nav .nav-item.active > .nav-link .menu-title,
.sidebar .nav .nav-item.active > .nav-link .menu-arrow {
    color: #fff;
}

.sidebar .nav .nav-item.active + .nav-item .nav-link {
    border-top: 1px solid #ec37fc;
}

.sidebar .nav .nav-item:first-child .nav-link {
    border-top: 1px solid #ec37fc;
}

.sidebar .nav:not(.sub-menu) {
    margin-top: 0.5rem;
}

.sidebar .nav:not(.sub-menu) > .nav-item {
    margin-top: 0;
}

.sidebar .nav:not(.sub-menu) > .nav-item:hover > .nav-link {
    background: rgba(255, 255, 255, 0.4);
    color: #fff;
    border-radius: 4px;
    border-top: 1px solid #ec37fc;
}

.sidebar .nav:not(.sub-menu) > .nav-item:hover > .nav-link i.menu-arrow {
    color: #fff;
}

.sidebar .nav:not(.sub-menu) > .nav-item:hover > .nav-link i.menu-icon {
    color: #fff;
}

.sidebar .nav:not(.sub-menu) > .nav-item:hover + .nav-item .nav-link {
    border-top: 1px solid #ec37fc;
}

.sidebar .nav.sub-menu {
    margin-bottom: 0;
    margin-top: 0;
    list-style: none;
    padding: 0.25rem 0rem 0 0rem;
    /* margin: 0 1.5rem; */
    background: transparent;
}

.sidebar .nav.sub-menu .nav-item {
    padding: 0rem;
    position: relative;
}

.sidebar .nav.sub-menu .nav-item .nav-link {
    color: #fff;
    padding: 1rem 4rem;
    position: relative;
    font-size: 0.875rem;
    line-height: 1;
    height: auto;
    border-top: 1px solid #ec37fc;
    margin: 0;
}

.sidebar .nav.sub-menu .nav-item .nav-link:hover {
    color: #b10dbf;
    background: transparent;
}

.sidebar .nav.sub-menu .nav-item .nav-link:hover::before {
    border: 1px solid #b10dbf;
}

.sidebar .nav.sub-menu .nav-item .nav-link.active {
    color: #b10dbf;
}

.sidebar .nav.sub-menu .nav-item .nav-link.active::before {
    border: 1px solid #b10dbf;
}

.sidebar .nav.sub-menu .nav-item .nav-link::before {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    margin-top: 21px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    left: 2.5rem;
    top: -2px;
}

.sidebar .nav.sub-menu .nav-item:first-child .nav-link {
    border-top: 1px solid #ec37fc;
}

.sidebar .user-profile {
    margin: 2rem 0;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
}

.sidebar .user-profile .user-image img {
    width: 79px;
    height: 79px;
    border-radius: 100%;
    margin-bottom: 0.625rem;
}

.sidebar .user-profile .user-name {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo {
    display: none;
}

.sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo-mini {
    display: inline-block;
}

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
    .sidebar-offcanvas {
        position: fixed;
        max-height: calc(100vh - 60px);
        top: 60px;
        bottom: 0;
        overflow: auto;
        right: -240px;
        -webkit-transition: all 0.25s ease-out;
        -o-transition: all 0.25s ease-out;
        transition: all 0.25s ease-out;
    }

    .sidebar-offcanvas.active {
        right: 0;
    }
}

/* Navbar */
.navbar {
    font-weight: 400;
    transition: background 0.25s ease;
    -webkit-transition: background 0.25s ease;
    -moz-transition: background 0.25s ease;
    -ms-transition: background 0.25s ease;
    /* Navbar color variations */
}

.navbar .navbar-brand-wrapper {
    background: #ec37fc;
    transition:
        width 0.25s ease,
        background 0.25s ease;
    -webkit-transition:
        width 0.25s ease,
        background 0.25s ease;
    -moz-transition:
        width 0.25s ease,
        background 0.25s ease;
    -ms-transition:
        width 0.25s ease,
        background 0.25s ease;
    width: 240px;
    height: 60px;
}

.navbar .navbar-brand-wrapper .navbar-brand {
    color: #27367f;
    font-size: 1.5rem;
    margin-right: 0;
    padding: 0.25rem 0;
}

.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
    display: none;
}

.navbar .navbar-brand-wrapper .navbar-brand:active,
.navbar .navbar-brand-wrapper .navbar-brand:focus,
.navbar .navbar-brand-wrapper .navbar-brand:hover {
    color: #1b2658;
}

.navbar .navbar-brand-wrapper .navbar-brand img {
    width: calc(240px - 130px);
    max-width: 100%;
    height: 20px;
    margin: auto;
    vertical-align: middle;
}

.navbar .navbar-brand-wrapper .brand-logo-mini {
    padding-left: 0;
    text-align: center;
}

.navbar .navbar-brand-wrapper .brand-logo-mini img {
    width: calc(70px - 30px);
    max-width: 100%;
    margin: auto;
}

.navbar .navbar-menu-wrapper {
    background: #fff;
    transition: width 0.25s ease;
    -webkit-transition: width 0.25s ease;
    -moz-transition: width 0.25s ease;
    -ms-transition: width 0.25s ease;
    color: #000000;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    width: calc(100% - 280px);
    height: 60px;
    -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.1);
}

@media (max-width: 991px) {
    .navbar .navbar-menu-wrapper {
        width: calc(100% - 55px);
        padding-left: 15px;
        padding-right: 15px;
    }
}

.navbar .navbar-menu-wrapper .navbar-toggler {
    border: 0;
    color: inherit;
    font-size: 1rem;
    padding: 2px;
    padding-right: 1.125rem;
    border-right: 1px solid #bec3e9;
    border-radius: 0;
}

@media (max-width: 991px) {
    .navbar .navbar-menu-wrapper .navbar-toggler {
        border-right: none;
    }
}

@media (max-width: 991px) {
    .navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
        display: none;
    }
}

@media (max-width: 991px) {
    .navbar .navbar-menu-wrapper .navbar-toggler.navbar-toggler-right {
        padding-left: 15px;
        padding-right: 11px;
    }
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item {
    margin-left: 1.125rem;
    margin-right: 1.125rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item:last-child {
    margin-right: 0;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
    color: inherit;
    font-size: 1rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search {
    margin-left: 1.5rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group {
    background: rgba(255, 255, 255, 0.13);
    border-radius: 4px;
    padding: 0.75rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .form-control,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-container--default
    .select2-selection--single,
.select2-container--default
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-selection--single,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-container--default
    .select2-selection--single
    .select2-search__field,
.select2-container--default
    .select2-selection--single
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-search__field,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .typeahead,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .tt-query,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .tt-hint,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .input-group-text {
    background: transparent;
    border: 0;
    color: #666;
    padding: 0;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .form-control,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-container--default
    .select2-selection--single,
.select2-container--default
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-selection--single,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-container--default
    .select2-selection--single
    .select2-search__field,
.select2-container--default
    .select2-selection--single
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-search__field,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .typeahead,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .tt-query,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .tt-hint {
    margin-left: 0.5rem;
}

.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .form-control::-webkit-input-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-container--default
    .select2-selection--single::-webkit-input-placeholder,
.select2-container--default
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-selection--single::-webkit-input-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-container--default
    .select2-selection--single
    .select2-search__field::-webkit-input-placeholder,
.select2-container--default
    .select2-selection--single
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-search__field::-webkit-input-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .typeahead::-webkit-input-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .tt-query::-webkit-input-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .tt-hint::-webkit-input-placeholder {
    color: #666666;
}

.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .form-control:-moz-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-container--default
    .select2-selection--single:-moz-placeholder,
.select2-container--default
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-selection--single:-moz-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-container--default
    .select2-selection--single
    .select2-search__field:-moz-placeholder,
.select2-container--default
    .select2-selection--single
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-search__field:-moz-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .typeahead:-moz-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .tt-query:-moz-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .tt-hint:-moz-placeholder {
    color: #666666;
}

.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .form-control::-moz-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-container--default
    .select2-selection--single::-moz-placeholder,
.select2-container--default
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-selection--single::-moz-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-container--default
    .select2-selection--single
    .select2-search__field::-moz-placeholder,
.select2-container--default
    .select2-selection--single
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-search__field::-moz-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .typeahead::-moz-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .tt-query::-moz-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .tt-hint::-moz-placeholder {
    color: #666666;
}

.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .form-control:-ms-input-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-container--default
    .select2-selection--single:-ms-input-placeholder,
.select2-container--default
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-selection--single:-ms-input-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-container--default
    .select2-selection--single
    .select2-search__field:-ms-input-placeholder,
.select2-container--default
    .select2-selection--single
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .select2-search__field:-ms-input-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .typeahead:-ms-input-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .tt-query:-ms-input-placeholder,
.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .tt-hint:-ms-input-placeholder {
    color: #666666;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings {
    border-left: 1px solid rgba(255, 255, 255, 0.24);
    margin: 0;
    padding-left: 1.5rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings .nav-link {
    padding: 0;
    text-align: center;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings i {
    font-size: 1.5rem;
    vertical-align: middle;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    -webkit-box-shadow: 4px 5px 6px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 4px 5px 6px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 4px 5px 6px 0px rgba(0, 0, 0, 0.5);
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-profile-name {
    margin-left: 0.5rem;
}

@media (max-width: 767px) {
    .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-profile-name {
        display: none;
    }
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
    border: none;
    -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
    position: absolute;
    font-size: 0.9rem;
    margin-top: 0;
    right: 0;
    left: auto;
    top: 60px;
}

.rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
    right: auto;
    left: 0;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item {
    margin-bottom: 0;
    padding: 0.65rem 1.5rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item i {
    font-size: 17px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item .badge {
    margin-left: 2.5rem;
}

.navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.dropdown
    .navbar-dropdown
    .dropdown-item
    .ellipsis {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item i {
    margin-left: 10px;
}

.rtl
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.dropdown
    .navbar-dropdown
    .dropdown-item
    .badge {
    margin-left: 0;
    margin-right: 2.5rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-divider {
    margin: 0;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown i {
    margin-right: 0.5rem;
    vertical-align: middle;
}

@media (max-width: 991px) {
    .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown {
        position: static;
    }

    .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
        left: 20px;
        right: 20px;
        top: 60px;
        width: calc(100% - 40px);
    }
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .count-indicator {
    position: relative;
    padding: 0;
    text-align: center;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .count-indicator i {
    font-size: 1.25rem;
    margin-right: 0;
    vertical-align: middle;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .count-indicator .count {
    position: absolute;
    left: 35%;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: #fc381d;
    top: -2px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .count-indicator:after {
    display: none;
}

.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right .nav-item .nav-link {
    color: #000000;
}

@media (min-width: 992px) {
    .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
        margin-left: auto;
    }

    .rtl .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
        margin-left: 0;
        margin-right: auto;
    }
}

.navbar.navbar-primary .navbar-menu-wrapper {
    background: #ec37fc;
}

.navbar.navbar-primary:not(.navbar-light) .navbar-menu-wrapper .navbar-toggler {
    color: #ffffff;
}

.navbar.navbar-primary:not(.navbar-light) .navbar-menu-wrapper .nav-item .nav-link {
    color: #ffffff;
}

.navbar.navbar-light .navbar-menu-wrapper {
    background: #ffffff;
    border-left: 1px solid #e4e6f6;
}

.navbar.navbar-light .navbar-menu-wrapper .navbar-toggler {
    color: #001737;
}

.navbar.navbar-light .navbar-menu-wrapper .nav-item .nav-link {
    color: #001737;
}

.navbar.navbar-light .navbar-menu-wrapper .nav-item.nav-search .input-group {
    background: rgba(0, 23, 55, 0.24);
}

.navbar.navbar-dark .navbar-menu-wrapper {
    border-left-color: #3743a4;
}

.navbar.navbar-danger .navbar-menu-wrapper .nav-item .nav-link.count-indicator .count {
    background: #ec37fc;
}

.navbar.navbar-secondary .navbar-menu-wrapper {
    background: #e4e6f6;
}

.navbar.navbar-secondary:not(.navbar-light) .navbar-menu-wrapper .navbar-toggler {
    color: #ffffff;
}

.navbar.navbar-secondary:not(.navbar-light) .navbar-menu-wrapper .nav-item .nav-link {
    color: #ffffff;
}

.navbar.navbar-light .navbar-menu-wrapper {
    background: #ffffff;
    border-left: 1px solid #e4e6f6;
}

.navbar.navbar-light .navbar-menu-wrapper .navbar-toggler {
    color: #001737;
}

.navbar.navbar-light .navbar-menu-wrapper .nav-item .nav-link {
    color: #001737;
}

.navbar.navbar-light .navbar-menu-wrapper .nav-item.nav-search .input-group {
    background: rgba(0, 23, 55, 0.24);
}

.navbar.navbar-dark .navbar-menu-wrapper {
    border-left-color: #3743a4;
}

.navbar.navbar-danger .navbar-menu-wrapper .nav-item .nav-link.count-indicator .count {
    background: #ec37fc;
}

.navbar.navbar-success .navbar-menu-wrapper {
    background: #51c81c;
}

.navbar.navbar-success:not(.navbar-light) .navbar-menu-wrapper .navbar-toggler {
    color: #ffffff;
}

.navbar.navbar-success:not(.navbar-light) .navbar-menu-wrapper .nav-item .nav-link {
    color: #ffffff;
}

.navbar.navbar-light .navbar-menu-wrapper {
    background: #ffffff;
    border-left: 1px solid #e4e6f6;
}

.navbar.navbar-light .navbar-menu-wrapper .navbar-toggler {
    color: #001737;
}

.navbar.navbar-light .navbar-menu-wrapper .nav-item .nav-link {
    color: #001737;
}

.navbar.navbar-light .navbar-menu-wrapper .nav-item.nav-search .input-group {
    background: rgba(0, 23, 55, 0.24);
}

.navbar.navbar-dark .navbar-menu-wrapper {
    border-left-color: #3743a4;
}

.navbar.navbar-danger .navbar-menu-wrapper .nav-item .nav-link.count-indicator .count {
    background: #ec37fc;
}

.navbar.navbar-info .navbar-menu-wrapper {
    background: #3794fc;
}

.navbar.navbar-info:not(.navbar-light) .navbar-menu-wrapper .navbar-toggler {
    color: #ffffff;
}

.navbar.navbar-info:not(.navbar-light) .navbar-menu-wrapper .nav-item .nav-link {
    color: #ffffff;
}

.navbar.navbar-light .navbar-menu-wrapper {
    background: #ffffff;
    border-left: 1px solid #e4e6f6;
}

.navbar.navbar-light .navbar-menu-wrapper .navbar-toggler {
    color: #001737;
}

.navbar.navbar-light .navbar-menu-wrapper .nav-item .nav-link {
    color: #001737;
}

.navbar.navbar-light .navbar-menu-wrapper .nav-item.nav-search .input-group {
    background: rgba(0, 23, 55, 0.24);
}

.navbar.navbar-dark .navbar-menu-wrapper {
    border-left-color: #3743a4;
}

.navbar.navbar-danger .navbar-menu-wrapper .nav-item .nav-link.count-indicator .count {
    background: #ec37fc;
}

.navbar.navbar-warning .navbar-menu-wrapper {
    background: #fcb41d;
}

.navbar.navbar-warning:not(.navbar-light) .navbar-menu-wrapper .navbar-toggler {
    color: #ffffff;
}

.navbar.navbar-warning:not(.navbar-light) .navbar-menu-wrapper .nav-item .nav-link {
    color: #ffffff;
}

.navbar.navbar-light .navbar-menu-wrapper {
    background: #ffffff;
    border-left: 1px solid #e4e6f6;
}

.navbar.navbar-light .navbar-menu-wrapper .navbar-toggler {
    color: #001737;
}

.navbar.navbar-light .navbar-menu-wrapper .nav-item .nav-link {
    color: #001737;
}

.navbar.navbar-light .navbar-menu-wrapper .nav-item.nav-search .input-group {
    background: rgba(0, 23, 55, 0.24);
}

.navbar.navbar-dark .navbar-menu-wrapper {
    border-left-color: #3743a4;
}

.navbar.navbar-danger .navbar-menu-wrapper .nav-item .nav-link.count-indicator .count {
    background: #ec37fc;
}

.navbar.navbar-danger .navbar-menu-wrapper {
    background: #fc381d;
}

.navbar.navbar-danger:not(.navbar-light) .navbar-menu-wrapper .navbar-toggler {
    color: #ffffff;
}

.navbar.navbar-danger:not(.navbar-light) .navbar-menu-wrapper .nav-item .nav-link {
    color: #ffffff;
}

.navbar.navbar-light .navbar-menu-wrapper {
    background: #ffffff;
    border-left: 1px solid #e4e6f6;
}

.navbar.navbar-light .navbar-menu-wrapper .navbar-toggler {
    color: #001737;
}

.navbar.navbar-light .navbar-menu-wrapper .nav-item .nav-link {
    color: #001737;
}

.navbar.navbar-light .navbar-menu-wrapper .nav-item.nav-search .input-group {
    background: rgba(0, 23, 55, 0.24);
}

.navbar.navbar-dark .navbar-menu-wrapper {
    border-left-color: #3743a4;
}

.navbar.navbar-danger .navbar-menu-wrapper .nav-item .nav-link.count-indicator .count {
    background: #ec37fc;
}

.navbar.navbar-light .navbar-menu-wrapper {
    background: #dee5ef;
}

.navbar.navbar-light:not(.navbar-light) .navbar-menu-wrapper .navbar-toggler {
    color: #ffffff;
}

.navbar.navbar-light:not(.navbar-light) .navbar-menu-wrapper .nav-item .nav-link {
    color: #ffffff;
}

.navbar.navbar-light .navbar-menu-wrapper {
    background: #ffffff;
    border-left: 1px solid #e4e6f6;
}

.navbar.navbar-light .navbar-menu-wrapper .navbar-toggler {
    color: #001737;
}

.navbar.navbar-light .navbar-menu-wrapper .nav-item .nav-link {
    color: #001737;
}

.navbar.navbar-light .navbar-menu-wrapper .nav-item.nav-search .input-group {
    background: rgba(0, 23, 55, 0.24);
}

.navbar.navbar-dark .navbar-menu-wrapper {
    border-left-color: #3743a4;
}

.navbar.navbar-danger .navbar-menu-wrapper .nav-item .nav-link.count-indicator .count {
    background: #ec37fc;
}

.navbar.navbar-dark .navbar-menu-wrapper {
    background: #001737;
}

.navbar.navbar-dark:not(.navbar-light) .navbar-menu-wrapper .navbar-toggler {
    color: #ffffff;
}

.navbar.navbar-dark:not(.navbar-light) .navbar-menu-wrapper .nav-item .nav-link {
    color: #ffffff;
}

.navbar.navbar-light .navbar-menu-wrapper {
    background: #ffffff;
    border-left: 1px solid #e4e6f6;
}

.navbar.navbar-light .navbar-menu-wrapper .navbar-toggler {
    color: #001737;
}

.navbar.navbar-light .navbar-menu-wrapper .nav-item .nav-link {
    color: #001737;
}

.navbar.navbar-light .navbar-menu-wrapper .nav-item.nav-search .input-group {
    background: rgba(0, 23, 55, 0.24);
}

.navbar.navbar-dark .navbar-menu-wrapper {
    border-left-color: #3743a4;
}

.navbar.navbar-danger .navbar-menu-wrapper .nav-item .nav-link.count-indicator .count {
    background: #ec37fc;
}

@media (max-width: 991px) {
    .navbar {
        flex-direction: row;
    }

    .navbar .navbar-brand-wrapper {
        width: 55px;
    }

    .navbar .navbar-brand-wrapper .navbar-brand.brand-logo {
        display: none;
    }

    .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
        display: inline-block;
    }

    .navbar-collapse {
        display: flex;
        margin-top: 0.5rem;
    }
}

@media (max-width: 480px) {
    .navbar .navbar-brand-wrapper {
        width: 55px;
    }

    .navbar .navbar-brand-wrapper .brand-logo-mini {
        padding-top: 0px;
    }
}

/* Layouts */
@media (min-width: 992px) {
    .sidebar-icon-only .navbar .navbar-brand-wrapper {
        width: 70px;
    }

    .sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo {
        display: none;
    }

    .sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo-mini {
        display: inline-block;
    }

    .sidebar-icon-only .navbar .navbar-menu-wrapper {
        width: calc(100% - 70px);
    }

    .sidebar-icon-only .sidebar {
        width: 70px;
    }

    .sidebar-icon-only .sidebar .nav {
        overflow: visible;
    }

    .sidebar-icon-only .sidebar .nav .nav-item {
        position: relative;
        padding: 0 0;
    }

    .sidebar-icon-only .sidebar .nav .nav-item .nav-link {
        display: block;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        text-align: center;
        position: static;
        margin: 0;
    }

    .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title,
    .sidebar-icon-only .sidebar .nav .nav-item .nav-link .badge,
    .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-sub-title {
        display: none;
    }

    .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
        border-radius: 0 5px 5px 0px;
    }

    .rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
        border-radius: 5px 0 0 5px;
    }

    .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-icon {
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 0;
    }

    .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-arrow {
        display: none;
    }

    .sidebar-icon-only .sidebar .nav .nav-item .nav-link[aria-expanded] .menu-title {
        border-bottom: 1px solid rgba(255, 255, 255, 0.36);
        border-radius: 0 5px 0 0px;
    }

    .rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link[aria-expanded] .menu-title {
        border-radius: 5px 0 0 0;
    }

    .sidebar-icon-only .sidebar .nav .nav-item .collapse {
        display: none;
    }

    .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        background: #b10dbf;
        padding: 0 1.4rem;
        left: 70px;
        position: absolute;
        text-align: left;
        top: 0;
        bottom: 0;
        width: 190px;
        z-index: 1;
        line-height: 1.8;
        box-shadow: 4px 0px 7px 0px rgba(182, 185, 189, 0.25);
    }

    .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
        left: auto;
        right: 70px;
        text-align: left;
        box-shadow: -4px 0px 7px 0px rgba(182, 185, 189, 0.25);
    }

    .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title:after {
        display: none;
    }

    .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link:hover .menu-title {
        background: #b10dbf;
    }

    .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
    .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
        display: block;
        padding: 0 0;
        background: #ec37fc;
        border-radius: 0 0 5px 0;
        position: absolute;
        left: 70px;
        width: 190px;
        box-shadow: 4px 4px 7px 0px rgba(182, 185, 189, 0.25);
    }

    .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
    .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
        left: auto;
        right: 70px;
        border-radius: 0 0 0 5px;
        box-shadow: -4px 4px 7px 0px rgba(182, 185, 189, 0.25);
    }

    .sidebar-icon-only .sidebar .nav.sub-menu {
        padding: 0 0;
        background: transparent;
        margin: 0;
    }

    .sidebar-icon-only .sidebar .nav.sub-menu .nav-item {
        border-top: 1px solid rgba(255, 255, 255, 0.36);
    }

    .sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
        text-align: left;
        padding-left: 20px;
        background: transparent;
    }

    .sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link::before {
        display: none;
    }

    .sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link.active {
        color: #001737;
    }

    .sidebar-icon-only .sidebar .nav.sub-menu .nav-item:before {
        display: none;
    }

    .sidebar-icon-only .sidebar .nav.sub-menu .nav-item:first-child {
        border-top: 1px solid transparent;
    }

    .sidebar-icon-only .sidebar .user-profile {
        margin: 2rem 0 0;
    }

    .sidebar-icon-only .sidebar .user-profile .user-image img {
        width: 36px;
        height: 36px;
    }

    .sidebar-icon-only .sidebar .user-profile .user-name {
        display: none;
    }

    .sidebar-icon-only .sidebar .user-profile .user-designation {
        display: none;
    }

    .sidebar-icon-only .main-panel {
        width: calc(100% - 70px);
        margin-left: 70px;
    }
}

/*# sourceMappingURL=maps/style.css.map */
