/* You can add global styles to this file, and also import other style files */
@import url('~@fortawesome/fontawesome-free/css/all.min.css');
@import url('~@ng-select/ng-select/themes/default.theme.css');
@import url('~css-star-rating/css/star-rating.min.css');
@import '@core/scss/libs/variables';
@import '@core/scss/avatar.scss';
@import 'assets/scss/shared_colors';
@import 'assets/scss/typography';
@import 'node_modules/@ctrl/ngx-emoji-mart/picker.css';
@import '../node_modules/angular-calendar/css/angular-calendar.css';
@import 'primeng/resources/themes/bootstrap4-light-purple/theme.css';
@import 'primeng/resources/primeng.css';

@import '../node_modules/@swimlane/ngx-datatable/themes/material.scss';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';

.mat-typography,
.mat-typography h4 {
    font-family: Poppins !important;
}

.p-component {
    font-family: Poppins !important;
}

.p-breadcrumb {
    font-family: Poppins !important;
    margin: 0px !important;
    font-size: 1rem !important;
    background: none !important;

    ul,
    ol,
    dl {
        font-size: 1rem !important;
        margin: 0px !important;
    }
}

.comments-rating .rating .star-container .star {
    height: 21px !important;
    width: 16px !important;
}

ng-select.ng-select-disabled {
    .ng-arrow-wrapper {
        display: none;
    }
}

.ng-select-container {
    border: 1px solid #bdbdc7;
}

.text-bold {
    font-weight: 900 !important;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
.completed,
.accepted,
.approved {
    background-color: rgba(33, 149, 55, 0.1) !important;
    color: rgba(33, 149, 55, 1) !important;
}

.pending,
.rejected,
.Pending {
    background-color: rgba(191, 23, 29, 0.1) !important;
    color: rgba(191, 23, 29, 1) !important;
}

.declined,
.canceled {
    background-color: rgba(114, 13, 117, 0.1) !important;
    color: rgba(191, 23, 29, 1) !important;
}

.pendingg {
    color: rgba(191, 23, 29, 1) !important;
}

.on-leave {
    background-color: rgba(0, 174, 255, 0.171) !important;
    color: rgb(0, 174, 255) !important;
}

.in-progress,
.request.pending {
    background-color: rgba(31, 31, 146, 0.1) !important;
    color: #1f1f92 !important;
}

.failed.confirmation {
    background-color: #e5e4e9 !important;
    color: rgba(94, 88, 115, 1) !important;
}

.reschedule {
    background-color: rgba(178, 232, 16, 0.1) !important;
    color: #1f9270 !important;
}

.unconfirmed {
    background-color: rgba(191, 23, 29, 0.1) !important;
    color: rgba(191, 23, 29, 1) !important;
}

.termination.requested {
    font-size: 12px;
    background-color: rgba(31, 31, 146, 0.1) !important;
    color: #1f1f92 !important;
}

.promotion.requested {
    font-size: 12px;
    background-color: rgba(31, 31, 146, 0.1) !important;
    color: #1f1f92 !important;
}

.confirmed,
.Acknowledged {
    font-size: 12px;
    background-color: rgba(33, 149, 55, 0.1) !important;
    color: rgba(33, 149, 55, 1) !important;
}

.app-icon {
    height: 20px !important;
    width: 20px !important;
    cursor: pointer !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.app-count-avatar span.count {
    color: #d62196;
    background: #ffff;
    border: 2px solid rgba(214, 33, 150, 0.5);
    top: 0;
    //   position: absolute;
    border-radius: 50%;
    font-size: 10px;
    height: 27px;
    width: 27px;
    //   margin-left: 4px;
    line-height: 22px;
    letter-spacing: 0.5px;
}

.cal-month-view .height {
    min-height: 10px !important;
    color: rgba(94, 88, 115, 1) !important;
}

.cal-month-view .cal-day-selected {
    background-color: #f7d3ea !important;
}

.cal-header {
    background-color: #e5e4e9 !important;
    color: rgba(94, 88, 115, 1) !important;
}

.cal-badge {
    margin-left: 20px;
    top: -5px;
    position: relative;
    display: inline-block;
}

.cal-badge::after {
    content: '';
    display: block;
    position: absolute;
    height: 7px;
    margin-left: -10px;
    margin-top: -10px;
    width: 7px;
    border-radius: 50%;
    background-color: #d62196;
}

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
    color: inherit !important;
}

.cal-month-view .cal-day-cell.cal-today {
    background-color: #e5e4e9 !important;
}

.cal-month-view .cal-day-cell.cal-day-selected.cal-today {
    background-color: #f7d3ea !important;
}

.cal-cell-top {
    min-height: 50px !important;
}

/* position */
.toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-left {
    top: 12px;
    left: 12px;
}

.toast-top-right {
    top: 12px;
    right: 12px;
}

.toast-bottom-right {
    right: 12px;
    bottom: 12px;
}

.toast-bottom-left {
    bottom: 12px;
    left: 12px;
}

/* toast styles */
.toast-title {
    font-weight: bold;
}

.toast-message {
    word-wrap: break-word;
}

.toast-message a,
.toast-message label {
    color: #ffffff;
}

.toast-message a:hover {
    color: #cccccc;
    text-decoration: none;
}

.toast-close-button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0 1px 0 #ffffff;
    /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
}

.toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;
}

.toast-container * {
    box-sizing: border-box;
}

.toast-container .ngx-toastr {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 300px;
    border-radius: 3px 3px 3px 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    box-shadow: 0 0 12px #999999;
    color: #ffffff;
}

.toast-container .ngx-toastr:hover {
    box-shadow: 0 0 12px #000000;
    opacity: 1;
    cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+');
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+');
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==');
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==');
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}

.ngx-toastr {
    background-color: #030303;
    pointer-events: auto;
}

.toast-success {
    background-color: #51a351;
}

.toast-error {
    background-color: #bd362f;
}

.toast-info {
    background-color: #2f96b4;
}

.toast-warning {
    background-color: #f89406;
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000000;
    opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
    .toast-container .ngx-toastr.div {
        padding: 8px 8px 8px 50px;
        width: 11em;
    }

    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}

@media all and (min-width: 241px) and (max-width: 480px) {
    .toast-container .ngx-toastr.div {
        padding: 8px 8px 8px 50px;
        width: 18em;
    }

    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}

@media all and (min-width: 481px) and (max-width: 768px) {
    .toast-container .ngx-toastr.div {
        padding: 15px 15px 15px 50px;
        width: 25em;
    }
}

$brand-pry-color: #440f7c;
$brand-default-color: #d62196;
$chat-light: #ede7f2;
$chat-light-color: #5e5873;

@font-face {
    font-family: 'Poppins-black';
    src:
        url('../src/assets/font/Poppins-Black.ttf') format('woff2'),
        url('../src/assets/font/Poppins-Black.ttf') format('woff');
}

@font-face {
    font-family: 'Poppins-bold';
    src:
        url('../src/assets/font/Poppins-Bold.ttf') format('woff2'),
        url('../src/assets/font/Poppins-Bold.ttf') format('woff');
}

@font-face {
    font-family: 'Poppins-light';
    src:
        url('../src/assets/font/Poppins-Light.ttf') format('woff2'),
        url('../src/assets/font/Poppins-Light.ttf') format('woff');
}

@font-face {
    font-family: 'Poppins-extralight';
    src:
        url('../src/assets/font/Poppins-ExtraLight.ttf') format('woff2'),
        url('../src/assets/font/Poppins-ExtraLight.ttf') format('woff');
}

@font-face {
    font-family: 'Poppins-thin';
    src:
        url('../src/assets/font/Poppins-Thin.ttf') format('woff2'),
        url('../src/assets/font/Poppins-Thin.ttf') format('woff');
}

@font-face {
    font-family: 'Poppins-medium';
    src:
        url('../src/assets/font/Poppins-Medium.ttf') format('woff2'),
        url('../src/assets/font/Poppins-Medium.ttf') format('woff');
}

@font-face {
    font-family: 'Poppins-regular';
    src:
        url('../src/assets/font/Poppins-Regular.ttf') format('woff2'),
        url('../src/assets/font/Poppins-Regular.ttf') format('woff');
}

a {
    text-decoration: none !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #5e5873;
}

.text-mute {
    color: #bdbdc7 !important;
}

.text-link {
    color: #444b8c;
}

.pointer {
    cursor: pointer;
}

.btn {
    border-radius: 5px;
    font-size: 12px !important;
    line-height: 18px !important;
    display: inline-block !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 6px 10px !important;
    gap: 10px !important;
    height: fit-content !important;

    &.btn-pry {
        background: $brand-pry-color;
        color: white;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
    }

    &.btn-link {
        background: #f7d3ea;
        color: #d62196;
    }

    &.btn-secondary-outline {
        border: 1px solid #dee2e6;
        border-radius: 5px;
    }
}

.fs-16 {
    font-style: normal;
    font-weight: 503;
    font-size: 1.1rem;
    line-height: 24px;
}

.fs-13 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
}

.fs-12 {
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 19px;
}

.fs-10 {
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 19px;
}

.card-statistics {
    .card-header {
        padding-left: 2.4rem;
    }

    .statistics-body {
        padding: 2rem 2.4rem 2.8rem !important;

        .avatar .avatar-content {
            width: 48px;
            height: 48px;

            .avatar-icon {
                width: 24px;
                height: 24px;
            }
        }
    }
}

// Button Styling
.btn {
    border-radius: 5px;
    box-shadow: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    border-color: transparent;
    text-decoration: none !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 6px 10px !important;
    gap: 10px !important;

    &.btn-default {
        background-color: $brand-default-color;
        color: $white;
    }

    &.btn-primary {
        background-color: $brand-pry-color;
        color: $white;
        border-color: $brand-pry-color;
    }

    &.btn-chat-light {
        background-color: $chat-light;
        color: $chat-light-color;
    }

    &.btn-link {
        background-color: #f7d3ea;
        color: $brand-default-color;
    }

    &.btn-secondary {
        background-color: #82868b;
        color: $white;
    }

    &.btn-disabled {
        background: #dacfe5;
        color: $brand-pry-color;
    }

    &.btn-default-outline {
        border-color: $brand-default-color;
        background-color: transparent;
        color: $brand-default-color;
    }

    &.btn-primary-outline {
        border: 1px solid $brand-pry-color !important;
        background-color: transparent;
        color: $brand-pry-color;
    }

    &.btn-secondary-outline {
        border: 1px solid #82868b !important;
        background-color: transparent;
        color: #82868b;
    }

    &.btn-secondary-outline:hover {
        background-color: #82868b;
        color: white;
    }

    &.btn-primary:hover {
        text-decoration: none;
        color: $brand-pry-color;
        background: transparent;
        border: 1px solid $brand-pry-color;
    }

    &.btn-default:hover {
        text-decoration: none;
        color: $brand-default-color;
        background: transparent;
        border: 1px solid $brand-default-color;
    }

    &.btn-link:hover {
        text-decoration: none;
        color: $brand-default-color;
        background: transparent;
        border: 1px solid #f7d3ea;
    }
}

.btn-slim {
    padding: 10px 15px !important;
    line-height: 1 !important;
}

.btn-slimmer {
    padding: 6px 10px !important;
    line-height: 1 !important;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn-group {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}

.btn-sm,
.btn-group-sm > .btn {
    padding: 0.486rem 1rem;
    font-size: 0.9rem;
    line-height: 1;
    border-radius: 0.358rem;
}

.btn-outline-primary.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23440F7C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.btn-outline-primary:hover:not(.disabled):not(:disabled) {
    background-color: rgba(68, 15, 124, 0.04);
    color: $brand-pry-color;
}

.show > .btn-outline-primary.dropdown-toggle {
    background-color: rgba(68, 15, 124, 0.04);
    color: $brand-pry-color;
}

.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.bg-light-primary {
    color: $brand-pry-color !important;
}

.bg-light-default {
    color: $brand-default-color !important;
}

.bg-light-danger {
    color: #ea5455 !important;
}

.bg-light-info {
    color: #00cfe8 !important;
}

.bg-light-success {
    color: #28c76f !important;
}

.bg-default {
    background-color: $brand-default-color !important;
}

// Button Ends
.font-10 {
    font-size: 10px !important;
}

.font-11 {
    font-size: 11px !important;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.nav-active {
    background: $brand-pry-color;
    color: $white;
}

// Card Styling

.card {
    border: none;
    margin-bottom: 2rem;
    box-shadow: 0 4px 5px 0 rgba(34, 41, 47, 0.1) !important;
    transition:
        all 0.3s ease-in-out,
        background 0s,
        color 0s,
        border-color 0s;
}

.card {
    position: static !important;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: $white;
    background-clip: border-box;
    // border: 1px solid rgba(34, 41, 47, 0.125) !important;
    border-radius: 0.428rem;
}

.card .card-header {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: none;
    padding: 0.5rem;
    padding-left: 1.5rem;
    background-color: transparent;
}

.card-header:first-child {
    border-radius: calc(0.428rem - 1px) calc(0.428rem - 1px) 0 0;
}

.card .card-title {
    font-weight: 500;
    font-size: 1.285rem;
    //   margin-bottom: 1.53rem;
}

.dropdown-toggle::after {
    border: none !important;
    content: ' ';
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    color: #6e6b7b;
    width: 14px;
    height: 11px;
    position: relative;
    top: 1px;
    right: 0px;
    left: 0.714rem;
    padding: 0;
    margin: 0;
    vertical-align: 0;
}

.media {
    display: flex;
    align-items: flex-start;
}

// MyGrids

@media (min-width: 668px) {
    .col-md-1-1 {
        flex: 0 0 10%;
        max-width: 10%;
    }

    .col-md-1-2 {
        flex: 0 0 12%;
        max-width: 12%;
    }

    .col-md-2-5 {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .col-md-3-3 {
        flex: 0 0 31%;
        max-width: 31%;
    }
}

// MyGrids Ends

html,
body {
    height: 100%;
    margin: 0;
}

.pi {
    font-family: 'primeicons' !important;
}

body {
    margin: 0;
    font-family: Poppins !important;
    font-size: 1rem !important;
}

.mat-icon-button {
    background: transparent !important;
}

.title {
    font-weight: 500;
}

.truncate {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ellipsis {
    word-wrap: break-word;
    word-break: break-all;
    overflow-wrap: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.primary-btn {
    background: #440f7c;
    border-radius: 5px;
    color: #ffffff;
    padding: 9px 24px;
    font-weight: 600;
    border: 1px solid #440f7c;
}

.primary-btn:hover {
    text-decoration: none;
    color: #440f7c;
    background: transparent;
    border: 1px solid #440f7c;
}

.primary-btn-2 {
    background: #f7d3ea;
    border-radius: 5px;
    color: #d62196;
    padding: 9px 24px;
    font-weight: 600;
    border: 1px solid #f7d3ea;
}

.primary-btn-2:hover {
    background: #d62196;
    text-decoration: none;
    color: #f7d3ea;
    border: 1px solid #f7d3ea;
}

.text-grey {
    color: #bdbdc7 !important;
}

.nav-active {
    background: #440f7c;
    color: #fff;
}

//modal css
.employee-search-modal {
    .modal-content {
        border: none !important;
        background-color: transparent !important;
    }
}

.accordion-wrapper {
    .accordion > .card:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .accordion > .card:not(:last-of-type) {
        border-bottom: 1px solid #ccc;
    }

    .card-header {
        background: none;
        padding: 0px !important;
    }

    .custom-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .card-body {
        padding: 0.5rem;
    }
}

.plan-employees-container {
    .card {
        border: 2px solid $secondary-solid;
        border-radius: 10px;
    }
}

.provider-logo {
    max-height: 50px;
}

// Reusable stlying
.card-body-slim {
    padding: 1.25rem !important;
}

.card-profile-size-1 {
    height: 250px;
    overflow: auto;
}

.card-profile-size-2 {
    height: 300px;
    overflow: auto;
}

.open-badge {
    padding: 7px 18px;
    background: rgba(33, 149, 55, 0.1);
    border-radius: 20px;
    color: #219537;
}

.filled-badge {
    padding: 7px 18px;
    background: rgba(191, 23, 29, 0.1);
    border-radius: 20px;
    color: #bf171d;
}

.ongoing-badge {
    padding: 7px 18px;
    background: rgba(31, 31, 146, 0.1);
    border-radius: 20px;
    color: #1f1f92;
}

// Auto Complete

.ng-autocomplete {
    width: 50% !important;

    #suggestions {
        z-index: 1000 !important;
    }
}

.autocomplete-container .input-container input,
.ng-select-container .ng-value-container .ng-input > input {
    border: none !important;
}

.autocomplete-container,
.form-style input,
.form-style select,
.form-style textarea {
    border: 1px solid #bdbdc7 !important;
    box-shadow: none !important;
    border-radius: 8px !important;
}

.form-style input,
.form-style select {
    height: 46px !important;
}

.btn-pink-light {
    background-color: #f7d3ea !important;
    color: #d62196 !important;
}

.btn-primary-dark {
    background-color: #440f7c !important;
    color: white !important;
}

.btn-pink {
    background: #d62196 !important;
    color: white !important;
}

.app-ng-autocomplete .autocomplete-container {
    position: relative !important;
    //   overflow: auto !important;
    height: auto !important;
    padding: 0.175rem 0.015rem !important;
}

.autocomplete-container {
    position: relative !important;
    //   overflow: auto !important;
    height: auto !important;
}

.app-ng-autocomplete {
    width: 100% !important;
}

.form-style select {
    padding: 0.3rem !important;
}

// .ng-autocomplete {
//   ::ng-deep {
//     i.close {
//       //   @include auto-override-icon('Font Awesome 5 Free', "\f005");
//     }

//     i.delete {
//       //   @include auto-override-icon('Font Awesome 5 Free', "\f007");
//     }
//   }
// }

// Filter Box

.search-box {
    padding: 5px;
    border: 1px solid #dadada;
    line-height: 1.5;
    color: white;
    border: 0;
    outline: 1px solid #e4e6f6;
    border-radius: 2px;
}

img.search-icon {
    height: 18px;
}

input.searchInput,
::placeholder {
    border: transparent;
    margin-left: 3px;
}

@media (min-width: 668px) {
    .search-box {
        width: 35%;
    }
}

@media (max-width: 667px) {
    .search-box {
        width: auto;
    }
}

.table-box {
    table {
        width: 100%;
        background: #ffffff;
    }

    td,
    th {
        width: auto;
        font-size: 14px;
    }

    tr,
    tr.mat-mdc-row {
        border: 1px solid #dee2e6;
        height: 40px;
    }

    th {
        border: 1.5px solid #dee2e6;
        padding-left: 15px !important;
    }

    td.mat-mdc-cell {
        border: 1px solid #dee2e6;
        // padding-left: 12px !important;
    }
}

.filter-box {
    .filter-item {
        width: 100%;
        margin-right: 0.8rem !important;
    }

    .filter-item-count {
        width: 40%;
    }

    .filter-item-recent {
        width: 55%;
    }

    .filter-item-type {
        width: 80%;
    }

    input,
    select.form-control {
        padding: 0.5rem 0.8rem;
        border: 0;
        outline: 1px solid #e4e6f6;
        color: #666666;
        border-radius: 6px;
    }

    .filter-search {
        width: 250px;
        position: relative;

        img {
            position: absolute;
            margin: 8px;
        }

        input {
            width: 100%;
        }

        input[type='text']::placeholder {
            border: transparent;
            padding-left: 16px;
        }
    }
}

.jobs {
    .mat-ink-bar {
        background-color: #d62196 !important;
    }

    .mat-tab-label.mat-tab-label-active {
        min-width: 25px !important;
        padding: 5px;
        background-color: transparent;
        color: #d62196;
    }
}

// ================================ LMS STYLES START ================================

.lms {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span,
    button,
    input,
    body {
        font-family: 'Poppins';
        font-style: normal;
        color: #5e5873;

        .pi {
            font-family: 'primeicons';
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
        appearance: textfield;
    }

    input[type='checkbox'] {
        color: $brand-pry-color;
        accent-color: $brand-pry-color;
        width: auto;
    }

    .mat-mdc-checkbox-checked.mat-accent .mat-mdc-checkbox-background {
        background-color: $brand-pry-color;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgb(204, 204, 204);
        border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgb(188, 188, 188);
    }

    .app-employee-select-option {
        display: flex;
        align-items: center;
        gap: 16px;

        .app-employee-select-info {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        .employee-info {
            display: flex;
            flex-direction: column;
            gap: 2px;
        }

        .name {
            font-weight: 500;
            line-height: 1;
        }

        .email {
            font-size: smaller;
            font-weight: 400;
            line-height: 1;
        }
    }

    p {
        margin: 0;
    }

    .page-header-card {
        background-color: white;
        border-radius: 8px;
        padding: 24px 16px;

        box-shadow: 0 4px 10px 0 rgba(34, 41, 47, 0.1);
        margin: 10px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 32px;

        .page-heading {
            margin: 0;
        }

        .delete-btn {
            background-color: #dc3545;
            color: white;
            padding: 8px 16px;
            padding-bottom: 14px;
        }
    }

    p.error-text {
        color: red;
        margin-top: 16px;
        font-style: italic;
    }

    .page-heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #5e5873;
    }

    .lms-charts-row {
        margin-bottom: 1rem;
    }

    .chart-card {
        height: 100%;
        margin-bottom: 50px;
    }

    .chart-card.learning-card {
        height: 100%;
    }

    .lms-dashboard .app-card {
        margin-bottom: 32px;

        ::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: rgb(204, 204, 204);
            border-radius: 5px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: rgb(188, 188, 188);
        }
    }

    .lms-dashboard {
        .page-heading {
            margin: 16px 0;
        }

        .filters-card {
            .filter-top-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .filters-selector {
                display: flex;
                align-items: center;
                gap: 24px;

                .ng-select-container {
                    min-width: 180px;
                    height: 40px;
                }

                .form-select {
                    width: 100%;
                }

                .btn-filter {
                    width: 100%;
                }
            }
        }
    }

    .course-select {
        .ng-value {
            display: none;
        }

        .ng-select-container .ng-value-container {
            .ng-placeholder {
                top: 8px;
            }
        }

        .ng-has-value .ng-placeholder {
            display: block;
        }

        &.ng-select-opened {
            .ng-has-value .ng-placeholder {
                display: none;
            }
        }
    }

    .toggle-radio {
        .toggle-btn {
            background-color: #f5f5f5;
            color: #5e5873;
            padding: 8px 24px !important;
            transition-duration: 300ms;

            .fas {
                font-size: 12px;
                margin-right: 4px;
            }

            &.active {
                background-color: #dddddd;
                transition-duration: 300ms;
            }

            &:first-child {
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
            }

            &:last-child {
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }
    }

    .form-group {
        label {
            display: flex;
            align-items: center;
            gap: 4px;
        }
    }

    i.label-help-icon {
        font-size: 8px;
        background-color: #f5f5f5;
        padding: 4px;
        cursor: pointer;
        height: 18px;
        width: 18px;
        text-align: center;
        border-radius: 50%;
    }

    .avg-chart-wrapper {
        .chart-container {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
        }

        .avg-score-text {
            font-weight: 700;
            font-size: 30px;
            line-height: 40px;
            color: #5e5873;

            position: absolute;
            top: auto;
            bottom: auto;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-align: center;
        }

        canvas {
            height: 300px !important;
            width: 300px !important;
        }
    }

    .app-card {
        background: #ffffff;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        padding: 20px;

        .dashboard-card-heading {
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #5e5873;

            &.filter-heading {
                font-size: 14px;
            }
        }
    }

    .top-stats-wrapper {
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #5e5873;

        .d-stat {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 5px 10px;

            .d-stat-value {
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
            }

            .d-stat-title {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
            }
        }
    }

    .curve-chart-wrapper {
        height: 100%;

        .chart-container {
            height: 100%;
        }
    }

    .chart-header-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 1px 0px 13px;

        .chart-heading {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #5e5873;
        }

        .chart-filters {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 16px;

            select {
                padding: 6px 8px 6px 14px;
                border: 1px solid #5e5873;
                border-radius: 5px;
            }
        }
    }

    .save-note-popup::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -50px;
        width: 0;
        height: 0;
        border-top: solid 50px rgb(214, 33, 150);
        border-left: solid 50px transparent;
        border-right: solid 50px transparent;
    }

    .course-list {
        .page-heading-with-btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 24px 0px;

            .upload-btn {
                padding: 8px 40px;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #ffffff;
                border-radius: 5px;
                border: none;
                background: #440f7c;
            }
        }

        .filter-strip {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            .form-select .ng-select-container,
            .form-control {
                height: 42px;
            }

            .filter-select,
            .form-select {
                padding: 8px 0;

                .ng-select-container {
                    min-width: 120px;
                }
            }

            .form-select {
                width: 150px;
            }

            .search-query-wrapper {
                width: 100%;
                position: relative;

                .form-control {
                    padding-left: 30px;

                    &::placeholder {
                        color: #999999;
                        opacity: 1;
                        /* Firefox */
                    }
                }

                .fa-search {
                    position: absolute;
                    left: 12px;
                    top: 14px;
                    color: #999;
                    opacity: 0.5;
                }
            }

            .filter-input {
                padding: 8px 10px;
                width: 100%;
            }

            .buttons-div {
                display: flex;
                gap: 10px;

                .filter-btn {
                    padding: 7px 20px;
                    background: #dacfe5;
                    border-radius: 2px;
                    font-weight: 500;
                    font-size: 12px;
                    color: #440f7c;
                    border: none;
                    display: flex;
                    align-items: center;
                }

                .clear-btn {
                    background-color: #d62196;
                    color: white;
                    border-radius: 2px;
                    padding: 0 10px;
                    border: none;
                    width: 32px;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .courses-wrapper {
            margin-top: 32px;

            .mat-icon {
                font-size: 18px;
                height: 18px;
                width: 18px;
            }

            .course-card {
                background-color: white;
                margin-bottom: 20px;
                border-radius: 6px;
                padding-bottom: 10px;
                cursor: pointer;
                overflow: hidden;
                height: 300px;
                box-shadow: 0 0 10px #dddddd;

                position: relative;

                .upload-status {
                    position: absolute;
                    top: 10px;
                    right: 0;
                    padding: 4px 8px;
                    background-color: #51a351;
                    color: white;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    font-size: 12px;
                    box-shadow: 0 0 5px #51a3515f;

                    .fas {
                        margin-right: 5px;
                    }
                }

                .expiry-date {
                    height: 0;
                    visibility: hidden;
                }

                .course-skills {
                    padding: 0 8px;
                    margin-top: 8px;
                    visibility: hidden;
                    height: 0;

                    .skills-wrapper {
                        display: flex;
                        gap: 8px;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        max-height: 140px;
                        overflow-x: auto;
                    }

                    .skills-heading {
                        font-weight: bold;
                        font-size: 14px;
                        margin-bottom: 8px;
                    }

                    .single-skill {
                        padding: 4px 8px;
                        background-color: #f5f5f5;
                        border-radius: 4px;
                        font-size: 11px;
                    }
                }

                .courses-count,
                .category,
                .job-role {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    font-size: 12px;
                }

                .rating.medium .star {
                    width: 12px;
                    height: 12px;
                }

                .rating.color-ok .star-container .star svg.star-empty {
                    fill: #5e5873 !important;
                }

                &.all {
                    &:hover {
                        img.course-image {
                            height: 0;
                            visibility: hidden;
                            transition-duration: 300ms;

                            display: flex;
                        }

                        .course-details {
                            margin-top: 10px;
                        }

                        .course-skills {
                            visibility: visible;
                            height: fit-content;
                            max-height: 135px;
                            transition-duration: 300ms;
                            overflow: hidden;
                        }

                        .expiry-date {
                            visibility: visible;
                            height: fit-content;
                            transition-duration: 300ms;
                        }
                    }
                }

                &.report {
                    height: 365px;
                }

                &.expired,
                &.upcoming {
                    .course-details,
                    .course-image {
                        opacity: 0.7;
                    }
                }

                .expired-indicator,
                .upcoming-indicator {
                    width: 95%;
                    margin: 0 8px;
                    padding: 5px;
                    border-radius: 5px;
                    margin-top: 6px;
                    font-weight: 600;
                    justify-content: center;

                    display: flex;

                    position: absolute;
                    bottom: 8px;
                    left: 0;

                    p {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        i {
                            margin-top: -4px;
                            font-size: 16px;
                        }
                    }
                }

                .expired-indicator {
                    background-color: #cfd8dc;
                    color: #455a64;
                }

                .upcoming-indicator {
                    background-color: #440f7c30;

                    p {
                        color: $brand-pry-color;
                    }
                }
            }

            .course-card-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 12px;
            }

            .course-details {
                padding: 8px;
            }

            .reminder-icon-wrapper {
                position: absolute;
                top: 10px;
                right: 0;

                background-color: white;
                box-shadow: 0 0 10px #6b6b6b;
                padding: 5px 8px;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;

                max-width: 30px;
                transition-duration: 500ms;

                height: 30px;

                display: flex;
                align-items: center;

                .reminder-icon {
                    font-size: 14px;
                    color: $brand-default-color;
                }

                .reminder-text {
                    visibility: hidden;
                }

                &:hover {
                    max-width: 500px;
                    background-color: $brand-default-color;
                    z-index: 1;
                    box-shadow: 0 0 10px #f5f5f5;

                    gap: 5px;

                    .reminder-text {
                        visibility: visible;
                        color: white;
                    }

                    .reminder-icon {
                        color: white;
                    }

                    transition-duration: 500ms;
                }
            }

            p.course-title-price {
                color: #5e5873;
                font-weight: 700;
                line-height: 1.2;
                letter-spacing: -0.02rem;
                font-size: 1rem;

                display: flex;
                align-items: flex-start;
                gap: 10px;
                justify-content: space-between;
            }

            .course-title,
            .title {
                max-width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            img.course-image {
                width: 100%;
                height: 155px;
                transition-duration: 300ms;
            }

            button {
                font-weight: 400;
                font-size: 11px;
                line-height: 16px;
                padding: 5px;
                border-radius: 4px;
                border: none;
            }

            .course-buttons {
                display: flex;
                justify-content: space-between;
                padding: 8px;

                position: absolute;
                bottom: 8px;
                left: 0;
                width: 100%;
            }

            .recommend-btn {
                color: #ffffff;
                background: #d62196;
            }

            .publish-btn {
                background-color: #440f7c;
                color: white;
            }

            .unpublish-btn {
                background: #ffffff;
                border: 1px solid #440f7c;
                border-radius: 4px;
                font-weight: 400;
                font-size: 11px;
                line-height: 16px;
                color: #440f7c;
            }

            p.light-text {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #5e5873;
            }
        }

        .report-stats {
            padding: 8px;

            .employee-report-text {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
            }

            .stats-wrapper {
                padding-top: 8px;
                display: flex;
                justify-content: space-between;
                gap: 16px;
            }

            .stat {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 5px 14px;
                gap: 3px;
                border-radius: 5px;
                width: 100%;

                .stat-heading {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 24px;

                    i {
                        margin-right: 5px;
                    }
                }

                .stat-value {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;

                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                }
            }

            .in-progress {
                background-color: #dacfe5;

                p,
                span {
                    color: #440f7c;
                }
            }

            .completed.stat {
                background-color: #f7d3ea;

                p,
                span {
                    color: #d62196;
                }
            }
        }

        .ngx-pagination {
            text-align: center;

            li.current {
                background: unset;

                span {
                    color: #d62196;
                }
            }
        }
    }

    .path-list {
        .courses-wrapper {
            .path-card {
                transition-duration: 300ms;

                .path-courses {
                    visibility: hidden;
                    max-height: 0;
                    overflow: hidden;
                }

                .course-title-price {
                    .title {
                        max-width: 100%;
                    }
                }

                .course-details {
                    background-color: white;
                }

                &.all:hover {
                    transition-duration: 300ms;

                    .course-card-row {
                        visibility: hidden;
                        height: 0;
                        margin: 0;
                    }

                    .course-details {
                        transition-duration: 300ms;
                        background-color: $brand-pry-color;
                        margin: 0;
                        margin-top: 0;
                        padding: 10px 8px;

                        .course-title-price span {
                            color: white;
                        }
                    }

                    .path-courses {
                        transition-duration: 300ms;
                        visibility: visible;
                        padding: 8px;
                        margin-top: 10px;
                        max-height: unset;
                    }

                    .courses-heading {
                        font-size: 14px;
                        font-weight: bold;
                        margin-bottom: 8px;
                    }

                    .path-courses-wrapper {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        max-height: 150px;
                        overflow-y: auto;
                    }

                    .path-single-course {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 4px 12px;
                        background-color: #f5f5f5;
                        border-radius: 4px;

                        p {
                            font-size: 14px;
                        }

                        .path-course-price {
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .type-options-wrapper {
            display: flex;
            align-items: center;
            gap: 16px;
            justify-content: flex-end;
            margin-top: 8px;

            padding: 16px;
            background-color: white;
            border-radius: 6px;
            box-shadow: 0 0 10px #f5f5f5;

            .btn-group-toggle {
                padding: 4px;
                border: 1px solid #dddddd;
                border-radius: 30px;

                .toggle-btn:first-child {
                    border-top-left-radius: 30px;
                    border-bottom-left-radius: 30px;
                }

                .toggle-btn:last-child {
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                }
            }

            .type-text {
                font-weight: 500;
                font-size: 16px;
            }
        }
    }

    .recommendations-page {
        .app-card {
            margin-top: 30px;
        }

        .app-card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 32px;

            .header-right-div {
                display: flex;
                gap: 10px;
                align-items: center;

                .recommend-filter {
                    height: 32px;
                    width: 100%;
                    min-width: 150px;
                }

                .btn-primary {
                    width: 100%;
                    min-width: 150px;
                }
            }
        }

        button.recommend-btn {
            padding: 6px 10px;
            gap: 10px;
            background: #440f7c;
            font-weight: 600;
            font-size: 12px;
            text-align: center;
            letter-spacing: 0.366667px;
            color: #ffffff;
            border-radius: 5px;
        }

        .type-select-row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            padding: 8px 12px;
            margin-top: 32px;
            border: 1px solid #dee2e6;
            border-radius: 6px;
        }

        .report-table-component {
            mat-checkbox {
                margin-left: -5px;
            }
        }
    }

    .lms-table {
        .employee-info {
            display: flex;
            flex-direction: column;
            gap: 2px;

            .name {
                font-weight: 500;
                line-height: 1;
            }

            .email {
                font-size: smaller;
                font-weight: 400;
                line-height: 1;
            }
        }

        .datatable-footer {
            .page-count {
                visibility: hidden;
            }
        }

        .mdc-checkbox {
            padding: 0;

            .mdc-checkbox__background {
                top: unset;
                left: unset;
            }

            .mat-mdc-checkbox-touch-target {
                height: auto;
            }

            .mdc-checkbox__native-control {
                width: auto;
                height: auto;

                &:enabled:checked ~ .mdc-checkbox__background {
                    background-color: $brand-pry-color;
                    border-color: $brand-pry-color;

                    .mdc-checkbox__checkmark {
                        color: white;
                    }
                }
            }

            &:hover,
            &:not(:disabled):active {
                .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
                    border-color: $brand-pry-color;
                    background-color: $brand-pry-color;
                }
            }
        }
    }

    .form-control {
        padding: 0.7rem;
    }

    .thumbnail-upload {
        .input-file-label {
            width: fit-content;
            padding: 10px 30px;
            background: #f5f5f5;
            border-radius: 10px;
            cursor: pointer;

            font-size: 12px;
        }
    }

    .form-select {
        &.is-invalid {
            .ng-select-container {
                border-color: #dc3545;
            }
        }

        &.ng-select-multiple {
            .ng-select-container {
                padding: 5px 10px;
            }
        }

        &.ng-select-single {
            .ng-select-container {
                padding: 20px 10px;
            }
        }

        .ng-select-container {
            border: 1px solid #e4e6f6;
            border-radius: 2px;
        }

        .app-select-option {
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }

    .section-heading {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #5e5873;
        margin-bottom: 16px;

        display: flex;
        gap: 8px;
        align-items: center;

        i {
            cursor: pointer;
            font-size: 16px;
            line-height: 27px;
            margin-top: -5px;
        }
    }

    .upload-input-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
            background: #e6e9ec;
            color: #5e5873;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            cursor: pointer;
            text-transform: uppercase;
            padding: 0.5rem;
            font-family: sans-serif;
            border-radius: 0.3rem;
            width: 200px;
            text-align: center;
            margin: 0;

            i {
                margin-right: 8px;
            }
        }

        .color-sec {
            color: #d62196;
            font-size: small;
            font-style: italic;
        }
    }

    .remove-course-icon {
        position: absolute;
        right: -10px;
        top: -10px;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        box-shadow: 0 0 10px #dddddd;

        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f5f5f5;
        cursor: pointer;

        i {
            font-size: 12px;
            color: #5e5873;
        }
    }

    .upload-course-v2 {
        .actions-section {
            background-color: white;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            box-shadow: 0 4px 10px 0 rgba(34, 41, 47, 0.1);
            margin: 10px;
            padding: 16px;

            button.move-to-next {
                background: #440f7c;
                border: none;
                border-radius: 8px;
                color: white;
                font-weight: bold;
                padding: 8px 40px;
            }
        }

        .mat-horizontal-content-container {
            padding: 0;
        }

        .mat-horizontal-stepper-header-container {
            background-color: white;
            border-radius: 8px;
            box-shadow: 0 4px 10px 0 rgba(34, 41, 47, 0.1);
            margin: 10px;

            .mat-step-icon-content {
                span {
                    color: white;
                }
            }
        }

        .mat-stepper-horizontal,
        .mat-stepper-vertical {
            background-color: unset;
        }

        .mat-step-header.cdk-keyboard-focused,
        .mat-step-header.cdk-program-focused,
        .mat-step-header:hover:not([aria-disabled]),
        .mat-step-header:hover[aria-disabled='false'] {
            background-color: unset;
        }

        .uc-basic-information {
            display: flex;
            flex-direction: column;
            gap: 24px;
            margin-top: 32px;

            .upload-section {
                background-color: white;
                border-radius: 8px;
                padding: 24px 16px;
                box-shadow: 0 4px 10px 0 rgba(34, 41, 47, 0.1);
                margin: 10px;

                &.pricing-section {
                    .equal-to-price {
                        color: grey;
                        font-size: 20px;
                        font-style: italic;
                        line-height: 30px;
                        margin-top: 5px;
                        opacity: 0.7;
                    }

                    label {
                        margin: 0;
                    }

                    .form-price-label {
                        align-items: center;
                        display: flex;
                        gap: 32px;
                    }

                    .form-control {
                        padding: 0.6rem;
                    }
                }

                &.prerequisite-section {
                    .course-select {
                        width: 50%;
                        margin-bottom: 40px;
                    }

                    .pre-req-courses-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 32px;

                        .pre-req-course {
                            background-color: white;
                            border: 1px solid #f5f5f6;
                            border-radius: 5px;
                            display: flex;
                            flex-basis: 30%;
                            flex-grow: 0;
                            flex-shrink: 0;
                            max-height: 80px;
                            position: relative;

                            .course-description {
                                padding: 16px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;

                                .course-name {
                                    font-weight: bold;
                                }
                            }

                            .course-image {
                                max-height: 80px;

                                border-top-left-radius: 5px;
                                border-bottom-left-radius: 5px;
                            }
                        }
                    }
                }

                &.skills-section {
                    .action-btn-wrapper {
                        align-items: center;
                        display: flex;
                    }

                    .skill-group-heading {
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 24px;
                        margin-top: 16px;
                    }

                    .skills-wrapper {
                        display: flex;
                        gap: 16px;
                        padding: 16px 0;

                        .single-skill {
                            background-color: #f5f5f5;
                            border-radius: 5px;
                            display: flex;
                            align-items: center;

                            .remove-skill {
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                width: 0;
                                padding: 0;

                                background-color: #ea5455;
                                transition-duration: 200ms;
                                cursor: pointer;
                                visibility: hidden;
                                border-top-right-radius: 5px;
                                border-bottom-right-radius: 5px;
                                height: 100%;
                                overflow: hidden;

                                i {
                                    font-size: small;
                                    color: white;
                                    cursor: pointer;
                                }
                            }

                            .skill-number,
                            .skill-title {
                                padding: 6px 12px;
                            }

                            .skill-title {
                                border-left: 2px solid #d0d0d0;
                            }

                            &:hover {
                                .remove-skill {
                                    display: flex;
                                    width: 32px;
                                    padding: 5px 8px;
                                    visibility: visible;
                                    background-color: #ea5455;
                                    transition-duration: 200ms;
                                    cursor: pointer;
                                    visibility: visible;
                                    height: 100%;
                                    color: white;
                                    border-top-right-radius: 5px;
                                    border-bottom-right-radius: 5px;
                                }
                            }
                        }
                    }

                    .add-skill-btn {
                        background-color: $brand-pry-color;
                        color: white;
                    }
                }
            }
        }

        .upload-group {
            label {
                align-items: center;
                display: flex;
                font-weight: bold;
                gap: 8px;

                .required {
                    color: #ea5455;
                }
            }
        }

        .uc-upload-sections {
            margin: 10px;
            margin-top: 32px;
            background-color: white;
            padding: 16px;
            border-radius: 8px;
            margin-bottom: 32px;
            box-shadow: 0 4px 10px 0 rgba(34, 41, 47, 0.1);

            .sections-wrapper {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .s-name-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 10px;

                    .section-name-input {
                        border: none;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: #5e5873;
                        padding-left: 0;
                    }

                    .remove-section-icon {
                        display: none;
                        transition-duration: 300ms;
                        cursor: pointer;
                    }

                    &:hover {
                        .remove-section-icon {
                            display: flex;
                            transition-duration: 300ms;
                        }
                    }
                }

                .single-section {
                    &:last-child {
                        margin-bottom: 24px;
                    }
                }
            }

            .upload-card-body {
                border: 1px solid #e4e6f6;
                border-radius: 2px;

                .accordion-toggle {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px 10px;

                    .open-section-heading {
                        display: none;
                    }

                    .close-section-heading {
                        display: flex;
                    }

                    &.collapsed {
                        .close-section-heading {
                            display: none;
                        }

                        .open-section-heading {
                            display: flex;
                        }
                    }
                }

                .upload-collapse-body {
                    padding: 16px 8px;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    .section-name-group {
                        display: flex;
                        align-items: center;
                        gap: 16px;
                        margin: 0;

                        label {
                            flex-basis: 30%;
                        }
                    }

                    .upload-file-div.upload-resources-div {
                        margin: 10px 0;

                        .resource-heading {
                            font-weight: 500;
                            font-size: small;
                            margin-bottom: 3px;
                            padding-left: 10px;
                        }

                        .resource-text {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: small;
                            padding-left: 10px;

                            span {
                                max-width: 95%;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }

                            i {
                                font-size: small;
                                color: grey;
                                cursor: pointer;
                            }
                        }
                    }

                    .chapter-heading-wrapper {
                        display: flex;
                        align-items: center;
                        gap: 16px;

                        .chapters-heading {
                            font-weight: bold;
                        }

                        .add-chapter-btn {
                            display: flex;
                            height: 32px;

                            .hovered-add-chapter {
                                max-width: 0;
                                transition-duration: 300ms;
                                display: flex;
                                visibility: hidden;
                                overflow: hidden;
                                gap: 8px;

                                .btn {
                                    max-height: 32px;
                                    background-color: #440f7c90;
                                    color: white;
                                    border-radius: 2px;
                                }
                            }

                            &:hover {
                                .add-chapter {
                                    width: 0;
                                    visibility: hidden;
                                }

                                .hovered-add-chapter {
                                    max-width: 700px;
                                    transition-duration: 300ms;
                                    visibility: visible;
                                }
                            }
                        }
                    }

                    .file-name {
                        color: grey;
                        font-style: italic;
                    }
                }
            }

            .chapters-wrapper {
                display: flex;
                flex-direction: column;
                gap: 8px;
                margin-top: 16px;

                .single-chapter {
                    border: 1px solid #e4e6f6;
                    border-radius: 5px;

                    .single-chapter-heading {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                    }

                    .accordion-toggle {
                        padding: 12px;
                    }

                    .chapter-remove-icon {
                        display: flex;
                        align-items: center;
                    }

                    .chapter-collapse {
                        padding: 8px;

                        .chapter-collapse-body {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;

                            .form-group {
                                margin: 0;
                                display: flex;
                                gap: 32px;

                                label {
                                    flex-basis: 30%;
                                    display: flex;
                                    align-items: center;
                                    gap: 8px;
                                }
                            }

                            input::placeholder {
                                color: #d6d6d6;
                            }
                        }
                    }

                    .input-group {
                        .form-select {
                            .ng-select-container {
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                                width: 200px;
                                height: 45px;
                                border-left: 0;
                            }
                        }
                    }
                }
            }

            .add-section-wrapper {
                .add-section-btn {
                    background-color: #440f7c;
                    color: white;
                    padding: 12px 24px;
                    font-weight: bold;
                }
            }

            .form-group.toggle-group {
                justify-content: space-between;

                .toggle-radio {
                    min-width: 280px;
                }
            }
        }

        span.required {
            color: #ea5455;
        }

        .uc-upload-scorm {
            background-color: white;
            border-radius: 8px;
            margin: 32px 0;

            .scorm-heading {
                padding: 16px;
            }

            .scorm-content-wrapper {
                padding: 16px;
                padding-top: 0;

                display: flex;
                flex-direction: column;
                gap: 16px;

                .upload-scorm-label {
                    font-size: xx-large;
                    margin-left: auto;
                    margin-right: auto;
                    padding: 64px;
                    border: 1px solid #dddddd;
                    border-radius: 8px;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 16px;

                    background: transparent;
                    color: #dddddd;

                    span {
                        font-size: 14px;
                        text-transform: none;
                        color: #9f9f9f;
                        display: inline-block;
                        width: 150px;
                    }
                }
            }

            .file-name {
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                padding: 8px 24px;
                margin-top: 16px;
                background: #f5f5f5;
                border-radius: 8px;

                span {
                    font-weight: bold;
                }
            }

            .form-group {
                display: flex;
                align-items: center;
                justify-content: space-between;

                label {
                    flex-basis: 30%;
                }

                .input-group {
                    flex-basis: 70%;
                }

                &.toggle-group {
                    justify-content: space-between;

                    .toggle-radio {
                        min-width: 280px;
                    }
                }
            }
        }

        .uc-review {
            margin-top: 32px;
            margin-bottom: 48px;

            .upload-card {
                background-color: white;
                padding: 24px 16px;
                padding-top: 0;
                border-radius: 8px;

                .card-heading {
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: bold;
                    padding-left: 8px;
                    border-left: 6px solid #440f7c;

                    margin-top: 30px;
                }

                .course-info-label {
                    font-size: 0.875rem;
                    line-height: 1.4rem;
                    vertical-align: top;
                    font-weight: bold;
                    margin-top: 1rem;
                }

                .sections-review-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    .review-section-heading {
                        border: 1px solid #e4e6f6;
                        border-radius: 4px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 8px;

                        .open-section-heading {
                            flex-basis: 30%;
                            flex-grow: 0;
                            flex-shrink: 0;
                        }

                        p {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                        }
                    }
                }

                .single-chapter-review {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 8px;

                    .single-chapter-heading {
                        flex-basis: 30%;
                        flex-grow: 0;
                        flex-shrink: 0;

                        display: flex;
                        align-items: center;

                        i {
                            margin-right: 5px;

                            padding: 5px;
                            height: 32px;
                            width: 32px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            background: #f5f5f5;
                        }
                    }

                    .file-name {
                        flex-basis: 40%;
                        flex-grow: 0;
                        flex-shrink: 0;

                        font-style: italic;
                    }

                    .time-transcript {
                        flex-basis: 30%;
                        flex-grow: 0;
                        flex-shrink: 0;

                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        align-items: flex-end;

                        .review-min-time,
                        .review-transcript {
                            width: 200px;
                            text-align: left;
                        }
                    }
                }

                .review-actions {
                    padding: 24px 8px;
                }
            }
        }

        .uc-final-assessment {
            margin: 10px 10px 32px;
            background-color: white;
            padding: 16px;
            border-radius: 8px;
            box-shadow: 0 4px 10px 0 rgba(34, 41, 47, 0.1);

            .assessment-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .add-assessment.btn {
                    background: #e6e9ec;
                    color: #5e5873;
                    cursor: pointer;
                    text-transform: uppercase;
                    padding: 0.5rem;
                    border-radius: 0.3rem;
                    width: 200px;
                    text-align: center;
                    margin: 0;

                    span {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                    }
                }
            }

            .section-heading {
                i {
                    font-size: 8px;
                    line-height: 8px;
                }
            }
        }
    }

    .add-learning-path {
        .app-card {
            margin-top: 20px;

            &.mode-card {
                .mode-selection {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .mode-description {
                    margin-top: 16px;

                    p,
                    span {
                        font-size: 10px;
                    }

                    span {
                        font-weight: 500;
                        margin-right: 4px;
                    }
                }

                .section-heading {
                    margin: 0;
                }
            }
        }

        .courses-list {
            width: 100%;
            border-radius: 4px;
            overflow: hidden;
            margin-top: 32px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 25px;
            padding: 10px;
            position: relative;

            .vertical-line {
                position: absolute;
                height: 100%;
                border: 2px dashed #dadada;
                left: 50px;
            }
        }

        span.required {
            color: #ea5455;
        }

        .btn-col {
            display: flex;
            align-items: center;
        }

        .app-btn {
            background-color: $primary;
            border: 0;
            color: white;
            padding: 8px 24px !important;
        }

        .thumbnail-column {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .file-name {
                font-size: small;
                font-style: italic;
            }
        }
    }

    .attempts-type-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 12px;
        border-radius: 8px;
        border: 1px solid #dddddd;

        margin: 32px 0;
    }

    .course-attempts,
    .quiz-attempts {
        span.attempt-exhausted,
        .quiz-failed {
            padding: 5px 20px;
            display: inline-block;
            background: #ffd5d5;
            border-radius: 20px;
            color: #ff8282;
        }

        .quiz-passed {
            padding: 5px 20px;
            display: inline-block;
            background: #d5ffdd;
            border-radius: 20px;
            color: #3dd262;
        }

        .course-status-td {
            text-align: center;

            span {
                padding: 5px 20px;
                display: inline-block;
                border-radius: 20px;
                text-transform: capitalize;

                width: 155px;

                &.completed,
                &.passed {
                    background: #2195371a;
                    color: #219537;
                }

                &.inprogress {
                    background-color: #e3f2fd;
                    color: #1565c0;
                }

                &.new {
                    background-color: #eceff1;
                    color: #455a64;
                }
            }
        }

        table {
            .quiz-status-td,
            .attempts-td,
            .action-td {
                text-align: center;
            }

            td {
                border: 1px solid #e4e6f6;
            }
        }

        .ngx-pagination {
            text-align: center;
            margin-top: 30px;

            li.current {
                background: unset;

                span {
                    color: #d62196;
                }
            }
        }

        .attempts-filter {
            display: flex;
            align-items: center;
            gap: 32px;
            justify-content: space-between;

            margin-bottom: 32px;

            .page-heading {
                font-size: 18px;
                font-weight: 600;
            }

            .form-group {
                margin-bottom: 0;

                input {
                    padding: 0.5rem;
                }
            }

            .right-content {
                display: flex;
                align-items: center;
                gap: 32px;
            }
        }
    }
}

.lms-modal {
    &.recommend-modal {
        width: 40vw;

        .ng-select {
            input[type='checkbox'] {
                width: auto;
            }
        }
    }

    &.publish-modal {
        width: 50vw;

        .btn-group-toggle {
            width: 100%;
            margin: 24px 0;

            .active {
                box-shadow: 0 0 5px #a4a4a4;
            }
        }

        .btn-submit {
            text-transform: capitalize;
        }
    }

    .lms-modal-title {
        font-weight: 500;
        font-size: 16px;
        margin: 0;
    }

    &.report-modal {
        .modal-header {
            padding: 0;
            border: none;
        }

        mat-checkbox {
            margin-left: -5px;
        }
    }

    span.required {
        color: #ea5455;
    }

    &.upload-modal {
        width: 40%;

        .modal-body {
            padding-top: 24px;
            padding-bottom: 24px;
        }

        .uploading-modal-heading {
            font-weight: 600;
            text-align: center;
            margin-top: 32px;
            margin-bottom: 4px;
        }

        .uploading-body {
            text-align: center;
        }

        .upload-progress {
            font-weight: 600;
            margin-top: 32px;
            text-align: center;
            font-size: 18px;
        }
    }

    &.quiz-modal,
    &.report-modal {
        &.modal-dialog {
            max-width: 90vw;
        }
    }

    &.modal-dialog {
        max-width: unset;
    }
}

.single-course {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .course-index {
        position: absolute;
        left: 25px;
        top: auto;
        bottom: auto;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: white;
        border: 2px solid $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }

    .course-box {
        width: 85%;
        border-radius: 4px;
        box-shadow: 0 0 10px #dadada;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        cursor: move;
        background: white;
        height: 120px;

        .course-content {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        img {
            height: 120px;
            width: 160px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        .title {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 12px;
        }

        .course-info {
            display: flex;
            flex-direction: column;
            gap: 2px;
            width: 600px;
        }

        .course-skills,
        .category,
        .tutor {
            display: flex;
            align-items: center;
            gap: 8px;

            i {
                flex-basis: 16px;
            }
        }

        .course-price {
            padding: 0 20px;

            .price {
                font-weight: 700;
                line-height: 1.2;
                letter-spacing: -0.02rem;
                font-size: 1.3rem;
            }
        }
    }
}

.cdk-drag-preview {
    border-radius: 4px;
}

.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.courses-list.cdk-drop-list-dragging .single-course:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.app-progress-bar {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    .progress-bg {
        // width: 100%;
        background-color: #dadada;
        height: 10px;
        border-radius: 10px;

        flex-basis: 85%;
    }

    .progress-fill {
        background-color: #28c76f;
        height: 100%;
        border-radius: 10px;
    }

    .progress-text {
        flex-basis: 15%;
        font-size: 12px;
        font-weight: 500;
        color: #5e5873;
        text-align: right;
    }
}

.create-quiz {
    p,
    button,
    span,
    input,
    label {
        color: #5e5873;
    }

    .create-quiz-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .create-quiz-heading {
            margin: 0;
        }

        .quiz-help-wrapper {
            color: grey;
            cursor: pointer;

            i {
                margin-right: 5px;
            }
        }
    }

    .quiz-values-div {
        margin: 32px 0;
        padding: 16px 24px;

        border: 1px solid #dddddd;
        border-radius: 8px;
        box-shadow: 0 0 10px #dddddd;

        display: flex;
        flex-direction: column;
        gap: 8px;

        .passing-marks,
        .max-attempts {
            flex-basis: 50%;
        }

        .form-group {
            display: flex;
            align-items: center;
            justify-content: space-between;

            margin: 0;

            label {
                flex-basis: 40%;
                margin-bottom: 0;
            }
        }
    }

    .single-question {
        padding: 20px;
        border: 1px solid #e7e7e7;
        border-radius: 10px;
        margin-bottom: 20px;
        background: white;
        box-shadow: 0 0 10px #ebebeb;
        position: relative;

        .delete-question-wrapper {
            float: right;
            display: flex;
            align-items: center;
            cursor: pointer;

            i {
                margin-right: 5px;
            }
        }

        .question-statement {
            border: none;
            padding-left: 0;
            padding-bottom: 10px;
            border-bottom: 1px solid #d6d6d6;
            font-size: 16px;
            padding-top: 0;
        }

        .options-wrapper {
            margin-top: 20px;
        }

        .single-option:hover {
            .remove-icon {
                display: inline-block;
                transition-duration: 300ms;
            }
        }

        .single-option {
            padding: 10px 0px;

            i.remove-icon {
                transition-duration: 300ms;
                cursor: pointer;
                vertical-align: middle;
                font-size: small;
                color: grey;
                display: none;
                margin-left: 10px;
            }

            label {
                width: 90%;
            }

            .question-option-radio {
                height: 18px;
                width: 18px;
                margin-right: 10px;
                vertical-align: middle;
                accent-color: #5e5873;
            }

            .question-option-label {
                border: none;
                width: 100%;
                border-bottom: 2px solid #dadada;
            }
        }
    }

    button.add-question {
        width: 100%;
        text-align: center;
        background-color: white;
        color: #5e5873;
        border: #5e5873 1px dashed;
        padding: 10px;
        cursor: pointer;
    }

    .quiz-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        gap: 16px;

        button {
            border: none;
            padding: 8px 40px;
            border-radius: 5px;

            font-weight: 500;
            font-size: 16px;
            line-height: 24px;

            width: 100%;
        }

        .save-quiz-btn {
            background: #440f7c;
            color: white;
        }

        .cancel-quiz-btn {
            background: #e6e9ec;
            color: #5e5873;
        }
    }

    .upload-excel-div {
        margin: 32px 0;
        padding: 16px 24px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        border: 1px solid #dddddd;
        border-radius: 8px;
        box-shadow: 0 0 10px #dddddd;

        .upload-excel-buttons {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        .upload-file-label {
            background: #dddddd;
            margin: 0;
            padding: 8px 16px;
            border-radius: 8px;
            cursor: pointer;
        }

        .download-btn {
            padding: 8px 16px;
            padding: 8px 16px;
            background-color: #d62196;
            color: white;
            border: 0;
            border-radius: 6px;
            font-size: 14px;
        }

        p {
            margin: 0;
        }
    }
}

.completed-study-report {
    .lms-table {
        .datatable-footer {
            .page-count {
                visibility: hidden;
            }
        }
    }
}

.report-table-component {
    .lms-table {
        .datatable-body-row.active {
            input[type='checkbox'] {
                background: rgb(203, 203, 203);
            }
        }

        .datatable-header-cell {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;

            &:first-child {
                border-top-left-radius: 6px;
            }

            &:last-child {
                border-top-right-radius: 6px;
            }
        }

        .datatable-row-wrapper:last-child {
            .datatable-body-cell:first-child {
                border-bottom-left-radius: 6px;
            }

            .datatable-body-cell:last-child {
                border-bottom-right-radius: 6px;
            }
        }

        .datatable-body-cell {
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
        }

        .datatable-body-cell,
        .datatable-header-cell {
            padding: 10px;
            border: 1px solid #dee2e6;
        }

        .datatable-checkbox {
            margin: 0;

            input {
                width: 13px;
                height: 13px;
                outline: 2px solid #5e5873b2;
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                -o-appearance: none;
            }
        }

        .datatable-footer .datatable-pager .pager {
            display: flex;
            gap: 10px;

            .pages {
                a {
                    font-size: 14px;
                    line-height: 20px;
                    color: #5e5873;
                }
            }

            .pages.active {
                a {
                    color: #d62196;
                }
            }
        }

        .path-student-status {
            padding: 2px 8px;
            border-radius: 2px;
            font-size: 12px;
            font-weight: 500;

            &.new {
                background-color: #dddddd;
            }

            &.inprogress {
                background-color: #1f1f9240;
            }

            &.completed,
            &.passed {
                background-color: #21953740;
            }
        }
    }
}

.modal-dialog {
    // width: 70vw;
    // max-width: unset;

    .modal-header {
        position: relative;

        .publish-title {
            text-transform: capitalize;
            margin: 0;
        }

        .modal-close-icon {
            position: absolute;
            top: -30px;
            right: 0;
        }
    }

    .modal-footer {
        .btn-submit {
            padding: 8px 40px;
            background: #440f7c;
            border-radius: 5px;
            color: white;
        }
    }

    .modal-content {
        .study-report-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 32px;

            .table-filter {
                border: 1px solid #dee2e6;
                border-radius: 6px;
                padding: 8px 10px;
                margin-right: 10px;
            }

            .modal-right-side {
                flex-basis: 60%;

                display: flex;
                justify-content: flex-end;
            }

            .report-filter {
                width: 200px;
                padding: 7px;
            }

            .modal-table-filter {
                display: flex;
                gap: 10px;
                align-items: center;
            }

            .modal-action-btn {
                padding: 8px 12px;
                gap: 10px;
                background: #440f7c;
                font-weight: 600;
                font-size: 12px;
                text-align: center;
                letter-spacing: 0.366667px;
                color: #ffffff;
                border-radius: 5px;
                border: none;
            }

            .study-modal-buttons {
                display: flex;
                align-items: center;
                gap: 8px;
            }

            .modal-heading {
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                margin: 0;
            }
        }

        .modal-body {
            .publish-text {
                font-size: 14px;
                line-height: 27px;
                color: #5e5873;
            }
        }

        .modal-footer {
            .btn-submit {
                padding: 8px 40px;
                background: #440f7c;
                border-radius: 5px;
                color: white;
            }
        }

        .rec-error-text {
            color: #ea5455;
            font-style: italic;
        }

        .recommend-form {
            textarea.form-control {
                padding: 10px;
            }

            .recommend-btn-wrapper {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                margin-top: 32px;

                .recommend-btn {
                    padding: 6px 10px;
                    background: #440f7c;
                    border-radius: 5px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;
                    text-align: center;
                    letter-spacing: 0.366667px;
                    color: #ffffff;
                }
            }
        }
    }

    .app-employee-select-option {
        display: flex;
        align-items: center;
        gap: 16px;

        .app-employee-select-info {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        .employee-info {
            display: flex;
            flex-direction: column;
            gap: 2px;
        }

        .name {
            font-weight: 500;
            line-height: 1;
        }

        .email {
            font-size: smaller;
            font-weight: 400;
            line-height: 1;
        }
    }

    // ================================ LMS STYLES END ================================

    td,
    th {
        width: auto;
        font-size: 14px;
    }

    tr,
    tr.mat-row {
        border: 1px solid #dee2e6;
        height: 40px;
    }

    th {
        border: 1.5px solid #dee2e6;
        padding-left: 15px !important;
    }

    td.mat-cell {
        border: 1px solid #dee2e6;
        padding-left: 12px !important;
    }

    .emoji-mart-preview,
    .emoji-mart-bar,
    emoji-search {
        display: none !important;
    }

    @media (max-width: 667px) {
        .content-wrapper {
            padding: 0 !important;
        }

        .c-input {
            width: 70% !important;
        }

        .main-poll-container,
        .summary {
            width: 100% !important;
        }
    }
}

.delete-modal {
    &.modal-dialog {
        width: 40vw;
        max-width: unset;

        .modal-header {
            h1 {
                margin: 0;
            }
        }

        .modal-body {
            .delete-body {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

// ================================ LMS STYLES END ================================

td,
th {
    width: auto;
    font-size: 14px;
}

tr,
tr.mat-row {
    border: 1px solid #dee2e6;
    height: 40px;
}

th {
    border: 1.5px solid #dee2e6;
    padding-left: 15px !important;
}

td.mat-mdc-cell {
    border: 1px solid #dee2e6;
    padding-left: 12px !important;
}

.emoji-mart-preview,
.emoji-mart-bar {
    display: none !important;
}

@media (max-width: 667px) {
    .content-wrapper {
        padding: 0 !important;
    }

    .c-input {
        width: 70% !important;
    }

    .main-poll-container,
    .summary {
        width: 100% !important;
    }
}

.prosemirror-mention-node {
    color: blue !important;
    text-decoration: underline !important;
}

.prosemirror-tag-node {
    color: gray !important;
    font-style: italic;
}

.suggestion-item-list {
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.suggestion-item {
    color: black;
    padding: 8px 10px;
    text-decoration: none;
    display: block;
}

.suggestion-item:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}

.color-default {
    color: #d62196;
}

.emoji-container {
    display: block;
    position: relative;
}

.clickable {
    cursor: pointer !important;
}

.audit-modal {
    .modal-dialog {
        width: 30%;
        float: right;
    }

    section {
        .justify-content-between {
            padding: 0 !important;
        }

        hr {
            display: none !important;
        }
    }
}

.swal-modal {
    .modal-dialog {
        width: 40%;
    }
}

.dropdown-toggle::after {
    left: 0;
}

.title {
    font-weight: 500 !important;
}

.cdk-overlay-container {
    z-index: 9999 !important;
}

.mat-datepicker-content .mat-calendar {
    height: 100% !important;
}

.check {
    width: 400px;
    transform-origin: left top;
    left: -10px !important;
    position: relative;
}

:host ::ng-deep .p-knob-text {
    color: #5e5873 !important;
    font-weight: 900 !important;
}

.gap-10 {
    gap: 10px;
}

.gap-5 {
    gap: 5px;
}

.gap-20 {
    gap: 20px;
}

.bloc {
    width: 100%;
    flex-direction: column;
    height: 100%;
    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
}

.white {
    color: white !important;
    text-align: center;
}

.table-spinner-wrapper {
    width: 150px;
    //  position: fixed;
    //  top: 25%;
    //  left: 45%;
    margin: auto;

    & img {
        height: 55px;
        width: 80px;
        position: relative;
        top: 80px;
    }

    @media (max-width: 578px) {
        position: absolute;
        left: 35%;
        top: 50%;
        transform: translate(0, -50%);
    }

    @media (min-width: 578px) and (max-width: 768px) {
        position: absolute;
        left: 40%;
        top: 25%;
        transform: translate(0, -50%);
    }
}

::ng-deep .window-class .modal-dialog {
    max-width: 300px !important;
    width: 300px !important;
}

.mention-house {
    display: flex;
    align-items: flex-start;
    gap: 14px;

    .image {
        width: 43px;
        height: 43px;
        flex-shrink: 0;
        border-radius: 43px;
        background-color: lightgray;
        background-image: url('https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2');
        background-size: cover;
        background-repeat: no-repeat;
        //background: url('../../../../../assets/images/img1.jpg'), lightgray 50% / cover no-repeat, #C4C4C4;
    }

    .name-house {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .name {
        color: #5e5873;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .time {
        color: #5e5873;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.jobs .promoted-table .mat-tab-body-content {
    height: 100%;
    overflow: hidden !important;
}

@media print {
    body * {
        visibility: hidden;
    }

    .printable,
    .printable * {
        visibility: visible;
    }

    .printable {
        position: absolute;
        left: 0;
        top: 0;
    }
}

::ng-deep {
    .p-inputtext {
        padding: 0.875rem 1.375rem !important;
    }

    .p-inputnumber {
        width: 100% !important;
    }

    .p-organizationchart .p-organizationchart-node-content {
        border: 2px solid #440f7c !important;
        border-radius: 40px 40px !important;
    }

    .p-organizationchart .p-organizationchart-line-down {
        background: #440f7c !important;
    }
}

/* Add borders to mat-cell and mat-header-cell elements */
.mat-mdc-cell,
.mat-mdc-header-cell {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    /* Adjust the color and thickness as needed */
}

/* Remove the border from the last cell in each row */
.mat-mdc-row .mat-mdc-cell:last-child,
.mat-mdc-header-row .mat-mdc-header-cell:last-child {
    border-right: none;
}

.table-container {
    width: 100%;
    overflow-x: auto;
    /* Enables horizontal scrolling on smaller screens */
}

/* Add borders to mat-cell and mat-header-cell elements */
.mat-mdc-cell,
.mat-mdc-header-cell {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-mdc-row .mat-mdc-cell:last-child,
.mat-mdc-header-row .mat-mdc-header-cell:last-child {
    border-right: none;
}

/* Responsive styles */
@media (max-width: 600px) {
    .mat-header-cell,
    .mat-cell {
        padding: 8px 4px;
        /* Reduce padding for smaller screens */
    }

    /* Hide certain columns on small screens */
    .mat-column-weight,
    .mat-column-symbol {
        display: none;
    }
}

::ng-deep .mdc-checkbox .mdc-checkbox__background {
    top: 0px !important;
    left: 0px !important;
}

::ng-deep .mdc-checkbox {
    padding: 0px !important;
    margin: 0px !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox
    .mdc-checkbox__native-control[data-indeterminate='true']:enabled
    ~ .mdc-checkbox__background {
    background-color: $brand-pry-color !important;
    color: white;
    border-color: $brand-pry-color !important;
}

.mdc-checkbox
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark {
    color: white !important;
}

.mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: $brand-pry-color !important;
}

.mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    border-color: $brand-pry-color !important;
}

.mdc-form-field > label {
    font-family: Poppins !important;
    margin-bottom: 0px !important;
    display: block !important;
}

// TRACKER
.tracker {
    font-family: 'Poppins';
    margin-top: 16px;

    .chart-title {
        font-size: 16px;
        font-weight: 500;
    }

    .chart-error {
        .error-message {
            width: 100%;
        }
    }

    .avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

    .avatar-project {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 15px;
    }

    .circular-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 6px;
    }

    input[type='checkbox'] {
        accent-color: $brand-pry-color;
    }

    app-tracker-toggle {
        max-width: 100%;
    }

    p {
        margin: 0;
    }

    .btn-primary {
        background-color: #440f7c;
        color: white;
        border-color: $brand-pry-color;
    }

    .form-check-input:checked {
        background-color: #440f7c;
        border-color: #440f7c;
    }

    .widget-heading {
        display: flex;
        justify-content: space-between;
        border-radius: 5px;
        padding: 20px 0;
    }

    .form-group {
        label {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .widget {
        box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
        padding: 16px;
    }

    .canvas-container.font-loaded canvas {
        font-family: Poppins, Arial, sans-serif;
    }

    .project-chart {
        .chart-wrapper {
            max-width: 500px;
        }
    }

    .chart-row {
        margin-bottom: 30px;

        .chart-title widget-title {
            font-weight: 600;
            font-size: 16px;
        }

        .widget {
            height: 100%;
            background-color: #fff;
            border-radius: 5px;
            justify-content: space-between;
        }
    }

    .settings-widget {
        display: flex;
        align-items: center;
        padding: 1rem;
        border-radius: 6px;
        box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
        margin-bottom: 32px;

        .options-wrapper {
            display: flex;
            gap: 1rem;
        }

        .settings-option {
            padding: 6px 12px;
            border-radius: 5px;
            background-color: #f5f5f5;
            cursor: pointer;
            transition: background-color 0.3s;

            font-size: 14px;
            font-weight: 500;

            &:hover {
                background-color: #440f7c;
                color: #ffffff;
            }
        }

        .settings-option.active {
            background-color: #440f7c;
            color: #ffffff;
        }
    }

    .tracker-filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 6px;

        margin-bottom: 2rem;

        flex-wrap: wrap;
        gap: 12px;

        .group-filter-wrapper {
            display: flex;
            align-items: center;
            gap: 32px;

            .ng-select-container {
                min-width: 150px;
            }
        }

        .date-filter-wrapper {
            .tracker-date-filters {
                display: flex;
                align-items: center;
                gap: 16px;
                flex-wrap: wrap;

                .date-filter-option {
                    padding: 4px 10px;
                    background-color: #f5f5f5;
                    color: rgb(27, 27, 27);
                    font-size: 14px;
                    border-radius: 4px;
                    cursor: pointer;
                    transition-duration: 300ms;

                    &.active {
                        background-color: $brand-pry-color;
                        color: white;
                        transition-duration: 300ms;
                    }
                }
            }
        }

        .filter-custom-date {
            width: 250px;
            padding: 0;

            .mdc-text-field {
                background-color: white;
                border: 1px solid #ccc;
                border-radius: 6px;

                .mdc-line-ripple::before {
                    border-bottom-width: 0;
                }
            }

            .mat-mdc-form-field-bottom-align {
                display: none;
            }
        }

        .tracker-select-group {
            margin-bottom: 0;
        }
    }

    .ngx-datatable {
        .datatable-header {
            border-bottom-color: $brand-pry-color;

            .datatable-header-cell {
                background-color: $brand-pry-color;
                color: white;
            }

            .resize-handle {
                border-right-color: #653796;
            }
        }

        .datatable-body {
            .datatable-body-cell {
                &.expand-cell {
                    align-content: center;

                    .expand-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        width: 30px;
                        height: 30px;
                        border-radius: 4px;
                        background-color: #f5f5f5;
                        font-size: 20px;
                        flex-shrink: 0;
                        flex-grow: 0;

                        cursor: pointer;
                    }
                }
            }
        }
    }

    .inner-datatable {
        .datatable-header {
            border-bottom-color: white;

            .datatable-header-cell {
                background-color: white;
                color: #90a4ae;
            }

            .resize-handle {
                border-right-color: #ccc;
            }
        }

        .datatable-body {
            .datatable-body-row {
                &:hover {
                    background-color: #f1f1f1;
                }
            }

            .datatable-body-cell {
                &:hover {
                    background-color: #e0e0e0;
                }
            }
        }

        .datatable-footer {
            background-color: #e9e9e9;
        }
    }

    .help-icon.chart-help-icon {
        cursor: default;
        font-size: 14px;
        position: absolute;
        margin-left: 5px;
        margin-top: 2px;
    }

    .report-table {
        padding-bottom: 32px;
        text-align: center;
        overflow: visible;

        .report-config-row {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            margin-bottom: 16px;

            input {
                width: fit-content;
                padding: 10px 22px;
            }
        }

        .report-info {
            text-align: left;
            margin-bottom: 32px;
        }

        .report-title {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 16px;
        }

        .report-description {
            font-size: 12px;
            color: grey;
        }

        .circular-image {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 6px;
        }

        .report-column-config {
            min-width: 200px;
            margin-right: 32px;

            .ng-value-container {
                .ng-value {
                    display: none;
                }

                .ng-placeholder {
                    display: block;
                    font-size: 14px;
                    margin-top: 2px;
                }
            }

            .ng-option,
            .ng-optgroup {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 12px;

                &.ng-option-selected {
                    background-color: unset;
                }

                &:hover {
                    background-color: #440f7c20;
                }
            }

            .ng-optgroup {
                font-weight: 600;
            }
        }

        .datatable-footer {
            justify-content: center;

            .page-count {
                flex: unset;
            }

            .datatable-pager {
                font-size: 14px;

                li.active a {
                    background-color: $brand-pry-color;
                    color: white;
                }
            }
        }

        .datatable-body {
            .datatable-row-detail {
                background-color: white;
            }

            .datatable-body-row {
                .cell-percentage {
                    padding: 0;

                    .percentage-content {
                        padding: 0.9rem 1.2rem;
                        display: inline-block;
                        width: 100%;

                        text-align: center;
                    }
                }
            }
        }

        .employee-image {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 10px;
            flex-shrink: 0;
        }
    }

    .chart-filter {
        font-size: 14px;
        min-width: 140px;
    }

    .project-page {
        padding-bottom: 32px;

        .project-main-card {
            .top-widgets {
                border-top: 1px solid #eee;
                border-bottom: 1px solid #eee;
                margin: 32px 0;
                padding: 16px 0;

                .widget {
                    box-shadow: unset;
                }
            }

            .project-description-heading {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 16px;
            }
        }

        .project-status-group {
            .ng-select-container {
                display: none;
            }

            label {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 16px;
            }

            .ng-dropdown-panel {
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
                border-top-color: #ccc;
            }

            .ng-option {
                transition-duration: 300ms;

                &.ng-option-marked {
                    background-color: unset;

                    &:hover {
                        transition-duration: 300ms;
                        background-color: #f5faff;
                    }
                }
            }
        }

        .project-members {
            font-size: 14px;
            font-weight: 500;

            .members-heading {
                font-weight: 600;
                font-size: 14px;
            }
        }

        .task-list {
            .priority-dropdown,
            .status-dropdown {
                font-size: 10px;
                width: 100px;

                margin-right: 8px;

                .form-group {
                    margin-bottom: 0;
                }

                .ng-select-container {
                    border: unset;
                }

                .ng-arrow-wrapper {
                    display: none;
                }
            }
        }
    }

    .custom-timeline {
        .ngx-datatable.material {
            box-shadow: unset;

            .hours-row {
                display: flex;
                justify-content: space-between;
                margin-right: 5%;

                .hour-marker {
                    flex: 1;
                    text-align: left;
                    font-size: 10px;
                    padding-left: 2px;
                    box-sizing: border-box;
                }
            }

            .datatable-body .datatable-body-row .datatable-body-cell {
                border-left: 1px solid #eee;
                border-bottom: 1px solid #eee;
                align-content: center;

                font-size: 12px;

                &:last-child {
                    border-right: 1px solid #eee;
                }
            }

            .datatable-header {
                border-bottom-color: #eee;

                .datatable-header-cell {
                    background-color: white;
                    color: #90a4ae;

                    border-left: 1px solid #eee;
                    border-top: 1px solid #eee;

                    &:last-child {
                        border-right: 1px solid #eee;
                    }
                }
            }

            .datatable-footer {
                border-top: 0;
            }
        }
    }

    .wl-settings {
        .issue-select-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            app-tracker-select {
                width: 100%;
            }

            .form-group {
                display: flex;
                align-items: center;
                justify-content: space-between;

                width: 100%;

                label {
                    flex-basis: 60%;
                    flex-grow: 0;
                    flex-shrink: 0;
                }

                .tracker-select {
                    flex-basis: 40%;
                    flex-grow: 0;
                    flex-shrink: 0;

                    font-size: 14px;
                }
            }
        }

        label {
            font-weight: bold;
        }

        .issue-toggle-wrapper {
            .form-group {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .issue-label {
            font-size: 16px;
            font-weight: bold;
        }

        .issue-desc {
            font-size: 12px;
            color: grey;
        }

        .single-issue {
            margin-bottom: 32px;
        }

        .buttons-row {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            margin-top: 32px;
        }
    }

    .configs-page {
        padding-bottom: 32px;

        .ng-select-container {
            font-size: 12px;
        }

        .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
            font-size: 12px;
        }
    }

    .view-payrolls {
        .payroll-table {
            .datatable-body,
            .datatable-body-cell,
            .ngx-datatable {
                overflow-y: visible !important;
                overflow-x: visible !important;
            }

            .dropdown-item {
                cursor: pointer;
            }

            .dropdown-toggle {
                cursor: pointer;
                color: #000000;
            }

            .actions {
                text-align: right;
            }
        }
    }

    .captures-page {
        .single-capture {
            flex-basis: 24.4%;

            border-radius: 6px;
            box-shadow: 0 0 10px #eee;
            position: relative;

            .capture-img {
                width: 100%;
                max-width: 100%;
                border-radius: 6px;
            }

            .capture-details {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                position: absolute;
                bottom: 0;
                width: 100%;
                background: linear-gradient(to top, black, transparent);
                padding: 8px;
                height: 60px;
                border-radius: 6px;

                .capture-user {
                    display: flex;

                    img {
                        border-radius: 50%;
                        width: 24px;
                        height: 24px;
                    }
                }

                .capture-date {
                    font-size: 10px;
                    color: white;
                }
            }
        }

        .ngx-pagination {
            margin-top: 32px;
            text-align: center;

            li.current {
                background: unset;

                span {
                    color: #d62196;
                }
            }
        }
    }

    .mdc-switch:enabled .mdc-switch__track::after {
        background: #744aa8;
    }

    .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
        background: $brand-pry-color;
    }

    .mdc-switch.mdc-switch--selected:enabled:focus:not(:active) .mdc-switch__handle::after {
        background: $brand-pry-color;
    }

    .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after {
        background: #744aa8;
    }

    .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after {
        background: #744aa8;
    }

    .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active)
        .mdc-switch__handle::after {
        background: $brand-pry-color;
    }

    .mdc-switch:enabled:active .mdc-switch__track::after {
        background: #744aa8;
    }

    .mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__handle::after {
        background: $brand-pry-color;
    }
}

.tracker-select-group {
    margin-bottom: 12px;

    label {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 4px;
    }
}

.tracker-modal {
    &.tracker-modal.capture-modal {
        max-width: 70vw;

        .modal-capture-img {
            max-width: 100%;
            border-radius: 6px;
            width: 100%;
        }
    }

    &.add-rating-modal {
        .type-cell {
            .form-group {
                margin: 0;
            }
        }
    }

    &.download-modal {
        .modal-header {
            padding: 0;
            border: 0;
        }
    }
}

.tracker-toast {
    &.primary-toast {
        background-color: $brand-pry-color;
    }
}

.space {
    margin-right: 15px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-content {
    border: 0;
}

.modal-header .modal-title {
    margin: 0;
}

.modal-header .close {
    font-size: 1.5rem;
}

.modal-body {
    padding: 20px;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    font-weight: bold;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
}
